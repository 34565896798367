import { FC, ReactElement } from 'react';
import { styled, Box, IconButton, Link } from '@mui/material';

import TwitterIcon from 'assets/landing/twitter.svg';
import DiscordIcon from 'assets/landing/discord.svg';
import GithubIcon from 'assets/landing/github.svg';
import CoreCoinIcon from 'assets/landing/coreCoin.svg';

import Icon from './icon';

import {
  TEXT_M_MEDIUM,
  TEXT_SM_MEDIUM,
  TEXT_XS_SEMIBOLD,
} from 'styles/globalTypography';
import { ROW_ALIGN_CENTER__SPACE_B, ROW_CENTER } from 'styles/globalStyles';
import { mediaQueries } from 'styles/mediaQueries';

export const Footer: FC = (): ReactElement => {
  return (
    <FooterBox>
      <LeftContainer>
        <IconButton>
          <Icon src={TwitterIcon} />
        </IconButton>
        <IconButton>
          <Icon src={GithubIcon} />
        </IconButton>
        <IconButton>
          <Icon src={DiscordIcon} />
        </IconButton>
      </LeftContainer>
      <CenterContainer>
        <Title underline="none">Community</Title>
        <Title underline="none">Governance</Title>
        <Title underline="none">Developers</Title>
        <Title underline="none">Contact</Title>
      </CenterContainer>
      <RightContainer>
        <LicenseText underline="none">
          <span>CORE </span> License
        </LicenseText>
        <Divider>|</Divider>
        <CoreCoinContainer>
          <Icon src={CoreCoinIcon} />
          <CoreCoinID>153216</CoreCoinID>
        </CoreCoinContainer>
      </RightContainer>
    </FooterBox>
  );
};

const FooterBox = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  height: 84px;
  padding: 0 40px;
  background-color: var(--Black);
  border-top: 1px solid var(--Neutrals-N700);

  ${mediaQueries.lessThan('sm')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:48px;
    height: auto;
    padding: 48px 40px;
  `};
`;

const LeftContainer = styled('div')`
  ${ROW_CENTER}
  gap: 16px;
`;
const CenterContainer = styled('div')`
  ${ROW_CENTER}
  gap: 32px;
  ${mediaQueries.lessThan('sm')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:24px;
    width:100%;
  `};
`;

const RightContainer = styled('div')`
  ${ROW_CENTER}
  gap: 16px;
`;

const CoreCoinContainer = styled('div')`
  ${ROW_CENTER}
  gap: 8px;
`;

const Title = styled(Link)`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N500);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;

  ${mediaQueries.lessThan('sm')`
    font-size:14px;
    font-weight:500;
    letter-spacing: -0.01rem;
    text-transform: capitalize; 
    padding-bottom:24px;
    width:100%;
    border-bottom:1px solid var(--Neutrals-N700);
    &:last-of-type {
      border: none;
    }
    `};
  /* // ${TEXT_M_MEDIUM}  */
`;

const LicenseText = styled(Link)`
  ${TEXT_SM_MEDIUM}
  color: var(--Neutrals-N500);
  cursor: pointer;
  span {
    color: var(--Neutrals-N500);
  }
`;

const CoreCoinID = styled('p')`
  ${TEXT_XS_SEMIBOLD}
`;

const Divider = styled('div')`
  color: var(--Neutrals-N700);
`;

export default Footer;
