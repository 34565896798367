import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { GlobalDomains } from '../global/selectors';
import { GlobalState } from '../global/types';
import { CoreCoinData } from './initialTokens';
import { getAccountBalance, getTokenBalance, getTokenslist } from './providers';
import { TokensDomains } from './selectors';
import { tokensActions } from './slice';
import { Token, TokensState } from './types';
import { DAO, USDT_ADDRESS } from '@/services/constants';
import { getAssetPriceAPI } from '../swap/providers/apis';
import { numberOfDecimalsForUSDT } from './constants';
import { liquidityActions } from '../liquidity/slice';

function* getPrices() {
  const activeNetwork: GlobalState['activeNetwork'] = yield select(
    GlobalDomains.activeNetwork
  );
  yield put(tokensActions.setIsLoadingPrices(true));
  const tokens: TokensState['tokens'] = yield select(TokensDomains?.tokens);

  // FIXME: fix mock data
  const res: { tokenSymbol: string; value: number }[] = [];
  // FIXME: fix decimals and get the decimals from available tokens
  const xcbPrice: number = yield call(getAssetPriceAPI, {
    tokenIn: DAO,
    tokenOut: USDT_ADDRESS,
    providerAddress: activeNetwork.rpcUrl,
    tokenInDecimals: 18,
    tokenOutDecimals: numberOfDecimalsForUSDT,
  });

  Object.values(tokens).forEach((token) => {
    res.push({
      tokenSymbol: token.symbol,
      value: parseFloat(xcbPrice.toFixed(numberOfDecimalsForUSDT)),
    });
  });

  const toDispatch: {
    tokenSymbol: string;
    value: number;
  }[] = [];
  res.forEach((token) => {
    if (tokens[token.tokenSymbol]) {
      toDispatch.push(token);
    }
  });
  yield put(
    tokensActions.batchUpdateTokenPrice({
      tokens: toDispatch,
    })
  );
  yield put(tokensActions.setIsLoadingPrices(false));
}

function* getBalanceForToken(
  action: ReturnType<typeof tokensActions.getBalanceForToken>
) {
  const { tokenSymbol } = action.payload;
  const tokens: TokensState['tokens'] = yield select(TokensDomains.tokens);
  const token = tokens[tokenSymbol];
  yield put(
    tokensActions.setIsGettingTokenBalance({
      isGettingBalance: true,
      tokenSymbol: token.symbol,
    })
  );
  try {
    let balance: Token['balance'] = 0;
    if (tokenSymbol === CoreCoinData.symbol) {
      const b: bigint = yield call(getAccountBalance);
      balance = Number(b);
      yield put(
        tokensActions.setTokenBalance({
          balance,
          tokenSymbol,
          decimals: token.decimals,
        })
      );
    } else {
      const b: bigint = yield call(getTokenBalance, {
        symbol: tokenSymbol,
      });
      balance = Number(b);
      yield put(
        tokensActions.setTokenBalance({
          balance,
          tokenSymbol,
          decimals: token.decimals,
        })
      );
    }
  } catch (error) {
    console.error(`error getting ${tokenSymbol} balance`);
  } finally {
    yield put(
      tokensActions.setIsGettingTokenBalance({
        isGettingBalance: false,
        tokenSymbol: action.payload.tokenSymbol,
      })
    );
  }
}

function* getAllBalances(
  action: ReturnType<typeof tokensActions.getAllBalances>
) {
  if (!action.payload.silent) {
    yield put(tokensActions.setIsGettingAllBalances(true));
  }
  const allTokens: TokensState['tokens'] = yield select(TokensDomains.tokens);
  const tokenKeys = Object.keys(allTokens);
  // create empty array to fill with call-able g-functions to use in yield all(<array>)
  const arrayToCall: any[] = [];
  try {
    for (let i = 0; i < tokenKeys.length; i++) {
      const token = allTokens[tokenKeys[i]];
      if (token.symbol === CoreCoinData.symbol) {
        arrayToCall.push(call(getAccountBalance));
      } else {
        arrayToCall.push(
          call(getTokenBalance, {
            symbol: token.symbol,
          })
        );
      }
    }
    const results: any[] = yield all(arrayToCall);
    const toDispatch: {
      tokenSymbol: string;
      value: number;
      decimals: number;
    }[] = [];
    // since length for results and tokenKeys are the same, we can use the same index for both
    for (let i = 0; i < tokenKeys.length; i++) {
      const token = allTokens[tokenKeys[i]];
      const value = results[i];
      const numbered = Number(value);
      const symbol = token.symbol;
      toDispatch.push({
        tokenSymbol: symbol,
        value: numbered,
        decimals: token.decimals,
      });
    }
    // dispatch all balances for this network id
    yield put(
      tokensActions.batchUpdateTokenBalance({
        tokens: toDispatch,
      })
    );
  } catch (error) {
    console.error(`error getting balances for tokens`, error);
  } finally {
    if (!action.payload.silent) {
      yield put(tokensActions.setIsGettingAllBalances(false));
    }
  }
}

function* getAdditionalTokensFromBlockChain({}: ReturnType<
  typeof tokensActions.getAdditionalTokensFromBlockChain
>) {
  try {
    yield put(tokensActions.setIsLoadingTokens(true));
    const tokens: TokensState['tokens'] = yield call(getTokenslist);
    yield put(tokensActions.addTokens(tokens));

    yield all([
      put(tokensActions.getAllBalances({})),
      put(tokensActions.getPrices()),
    ]);
    yield put(liquidityActions.getPools());
  } catch (error) {
    console.log(error);
  } finally {
    yield put(tokensActions.setIsLoadingTokens(false));
  }
}
// tokens page saga
export function* tokensSaga() {
  yield takeLatest(tokensActions.getPrices.type, getPrices);
  yield takeLatest(tokensActions.getBalanceForToken.type, getBalanceForToken);
  yield takeLatest(tokensActions.getAllBalances.type, getAllBalances);
  yield takeLatest(
    tokensActions.getAdditionalTokensFromBlockChain.type,
    getAdditionalTokensFromBlockChain
  );
}
