export const EMPTY_ARRAY: any[] = [];

export enum RequestTypes {
  PUT = 'PUT',
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}
export enum ContentTypes {
  JSON = 'application/json',
  FORM_URLENCODED = 'application/x-www-form-urlencoded',
  MULTIPART_FORM_DATA = 'multipart/form-data',
}
export interface RequestParameters {
  requestType: RequestTypes;
  url: string;
  data?: any;
  isRawUrl?: boolean;
  contentType?: ContentTypes;
}
export enum LocalStorageKeys {}
export enum SessionStorageKeys {
  ACCESS_TOKEN = 'access_token',
  REDIRECT_ACTION = 'redirect_action',
}
export interface StandardResponse {
  message: string;
  data: any;
  status: boolean;
  is_success: boolean;
}

export const IS_PRODUCTION = !!import.meta.env.VITE_APP_IS_PRODUCTION;
export const IS_DEV = import.meta.env.NODE_ENV === 'development';

// Production/Staging Url
// export const BaseUrl = process.env.NODE_ENV === "production" ? process.env.VITE_APP_API_ENDPOINT : "/dev/"
export const BaseUrl = import.meta.env.VITE_APP_API_ENDPOINT;
export const USDT_ADDRESS = '0xab234acdf47ac7bacea3faf0805159461e2caf7ed708';
export const DAO = '0xab66575d9f17c8159ddfa0b9e87279b1bb6df709fce0';
export const ROUTER = '0xab168e4baf56afbc667c49873ae6ee498c87ecce1ea4';
export const WXCB = '0xab40e3ec2099134af38520b73d218260eafd4948f786';
export const FACTORY_ADDRESS = '0xab50509e98f891b452c965f453909c655465a9b7ae14';
export const ROUTER_CONTRACT_ADDRESS =
  '0xab168e4baf56afbc667c49873ae6ee498c87ecce1ea4';
export const UNISWAP_V2_CONTRACT_ADDRESS =
  '0xab50509e98f891b452c965f453909c655465a9b7ae14';
