// src/componetns/Footer.tsx

import React, { FC } from 'react';
import { styled } from '@mui/material';
import BaseButton, { BaseButtonProps } from './BaseButton';

interface GradientContentbutton extends BaseButtonProps {}

const GradientContentButton: FC<GradientContentbutton> = ({
  children,
  ...props
}) => {
  return <GCButton {...props}>{children}</GCButton>;
};

const GCButton = styled(BaseButton)`
  color: var(--Black);
  position: relative;
  z-index: 0;
  display: inline-block;
  background-color: var(--Brand-Neon-200);
  background: linear-gradient(
      to right,
      rgba(209, 254, 146, 1),
      rgba(9, 150, 105, 1)
    )
    0 0/0 0;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    border-radius: inherit;
    transition: 0.5s all;
    background-color: var(--Brand-Neon-200);
  }
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--Brand-Neon-200);
    background-image: inherit;
    border-radius: inherit;
    /* transition: 1s all; */
    -webkit-mask: linear-gradient(var(--White), var(--White)) top / 100% 0px,
      linear-gradient(var(--White), var(--White)) bottom/100% 0px;

    mask: linear-gradient(var(--White), var(--White)) top / 100% 0px,
      linear-gradient(var(--White), var(--White)) bottom/100% 0px;
  }

  &:hover::after {
    opacity: 0;
  }
  &:hover {
    background-color: var(--Brand-Neon-200);
    color: var(--Black);
  }
  &:disabled:after {
    opacity: 0;
  }
  &.Mui-disabled {
    background-color: var(--Black);
    color: var(--Neutrals-N400);
    border: 1px solid var(--Neutrals-N700);
  }
`;

export default GradientContentButton;
