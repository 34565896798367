import { FC, useState } from 'react';
import { styled, InputAdornment, TextField } from '@mui/material';

import SearchIcon from 'assets/liquidity/search.svg';

import { BaseInputProps } from './BaseInput';

import { H3_REGULAR } from '@/styles/globalTypography';
import Icon from '../icon';

export interface SearchInputProps extends BaseInputProps {
  search_focus_ref?: string;
  width?: string;
}

export const SearchInputEndIcon: FC<SearchInputProps> = ({
  placeholder,
  ...props
}) => {
  const [searchFocusRef, setSearchFocusRef] = useState('false');

  return (
    //@ts-ignore
    <Container
      search_focus_ref={searchFocusRef}
      variant="outlined"
      onFocus={() => {
        setSearchFocusRef('true');
      }}
      onBlur={() => {
        setSearchFocusRef('false');
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              backgroundColor: 'inherit',
            }}
          >
            <Icon src={SearchIcon} />
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};

const Container = styled(TextField)<SearchInputProps>`
  background-color: ${(props) =>
    props.search_focus_ref === 'true'
      ? 'var(--Neutrals-N900)'
      : ' var(--Black)'};

  position: relative;
  color: var(--White);

  border-bottom: 1px solid var(--Neutrals-N700);

  & .MuiInputBase-root {
    color: var(--White);
    padding: 0;
    width: ${({ width }) => width};
  }
  & ::placeholder {
    ${H3_REGULAR}
    text-align: left;
    color: var(--Neutrals-N100) !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  & .MuiInputBase-input {
    padding: 10px 6px;
  }
`;
