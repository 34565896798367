// src/componetns/Footer.tsx

import { FC } from 'react';
import { styled } from '@mui/material';
import BaseButton, { BaseButtonProps } from './BaseButton';

const WhiteButton: FC<BaseButtonProps> = ({ children, ...props }) => {
  return <WButton {...props}>{children}</WButton>;
};

const WButton = styled(BaseButton)<BaseButtonProps>`
  background-color: #fff;
  color: #000;

  &:hover {
    background-color: var(--White);
    color: var(--Neutrals-N100);
  }

  &.Mui-disabled {
    background-color: var(--Neutrals-N700);
    color: var(--Neutrals-N400);
  }
`;

export default WhiteButton;
