import { GovernanceState, ProposalDataType, Quorum } from './types';

export const OPEN_PROPOSALS_ROUTE = '/governance/open';
export const PAST_PROPOSALS_ROUTE = '/governance/past';

export const ROUTES_BASE_ON_TAB_VALUE = [
  OPEN_PROPOSALS_ROUTE,
  PAST_PROPOSALS_ROUTE,
];

export const proposalData: ProposalDataType[] = [
  {
    id: 1,
    date: '5d 16h 12m',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'open',
  },
  {
    id: 2,
    date: '5d 16h 12m',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'open',
  },
  {
    id: 3,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'Executed',
  },
  {
    id: 4,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'cancelled',
  },
  {
    id: 5,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'Executed',
  },
  {
    id: 6,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'Executed',
  },
  {
    id: 7,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'cancelled',
  },
  {
    id: 8,
    date: '',
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet...',
    forPrecentage: 70,
    againstPrecentage: 20,
    proposalStatus: 'cancelled',
  },
];

export const proposals: GovernanceState['proposals'] = {
  1: {
    id: 1,
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum 1',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    details:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    startDate: new Date(),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 7
    ),
    quorum: Quorum.normal,
    votes: { agree: 23, disagree: 2 },
    proposalStatus: 'open',
  },

  2: {
    id: 2,
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum 2',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    details:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ),
    endDate: new Date(),
    quorum: Quorum.strict,
    votes: { agree: 45, disagree: 1234 },
    proposalStatus: 'cancelled',
  },

  3: {
    id: 3,
    title: 'Fix the Cross Chain Messaging Bridge on Arbitrum 3',
    description:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    details:
      'On Ethereum, Uniswap Labs governance consists of a suite of smart contracts. However, in addition to its original deployment on Ethereum L1 mainnet, Uniswap V3 has also been deployed on Arbitrum L2. This deployment is not governed by the same contracts as the Ethereum L1 deployment, and is instead governed by a multisig. This multisig has been used to execute a number of proposals, including the recent proposal to increase the Arbitrum L2 fee tier to 0.05%.',
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 16
    ),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 2
    ),
    quorum: Quorum.strict,
    votes: { agree: 2323, disagree: 5 },
    proposalStatus: 'Executed',
  },
};

export const MIN_XCB_FOR_VOTING = 1000;
