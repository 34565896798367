import { tokensActions } from '@/containers/tokens/slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useInitializeApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tokensActions.getAdditionalTokensFromBlockChain());
  }, []);
};
