import { Box, styled } from '@mui/material';
import { WalletMenuContent } from '../shared/WalletMenuContent';
import { COLUMN_CENTER } from '@/styles/globalStyles';

export const WalletMenuModalContent = () => {
  return (
    <Container>
      <Divider />
      <WalletMenuContent />
    </Container>
  );
};

const Container = styled(Box)`
  ${COLUMN_CENTER}
  gap: 16px;
`;

const Divider = styled(Box)`
  width: 100%;
  border-top: 1px solid var(--Neutrals-N700);
  margin-bottom: 8px;
`;
