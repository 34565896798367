import { FiatCurrences } from './types';

export const fiatCurrencyNames = ['USD', 'EUR', 'GBP', 'YEN'] as const;

export const FIAT_CURRENCES: FiatCurrences = {
  USD: {
    symbol: '$',
    label: 'USD',
  },
  EUR: {
    symbol: '€',
    label: 'EUR',
  },
  GBP: {
    symbol: '£',
    label: 'GBP',
  },
  YEN: {
    symbol: '¥',
    label: 'YEN',
  },
};

export const storagedLanguage = localStorage.getItem('i18nextLng');

export const storagedFiatCurrency = localStorage.getItem('fiat-currency');
