import { FC } from 'react';
import { styled } from '@mui/material';

import BaseInput, { BaseInputProps } from './BaseInput';
import { formatCurrencyWithMaxFraction } from '@/utils/formatters';

//@ts-ignore
export interface NumberInputProps extends BaseInputProps {
  value: string;
  maxFraction?: number;
  onChange: (newValue: string) => void;
}

const NumberInput: FC<NumberInputProps> = ({
  onChange,
  value,
  maxFraction = 8,
  ...props
}) => {
  const formatted = formatCurrencyWithMaxFraction(value, maxFraction);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    let v = e.target.value.replace(/ /g, '');
    if (v.includes('..')) {
      return;
    }
    if (v.length > 0) {
      const count = (v.match(/\./g) || []).length;
      if (count > 1) {
        return;
      }
    }
    if (v === '.') {
      v = '0.';
    }
    if (isNaN(Number(v.replace(/,/g, '')))) {
      return;
    }
    if (maxFraction && v.includes('.')) {
      const splitted = v.split('.');
      if (splitted[1] && splitted[1].length > maxFraction) {
        //prevent input if fraction length is much
        return;
      }
    }
    const noComma = v.replace(/,/g, '');
    if (Number(noComma) > 10000000000) {
      return;
    }
    onChange(v.replace(/,/g, ''));
  };

  return (
    <NInput value={formatted} onChange={handleInputChange} {...props}></NInput>
  );
};

const NInput = styled(BaseInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default NumberInput;
