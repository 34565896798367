import { CoreNetwork } from '@/hooks/network';
import { useConnection } from '@/hooks/useConnection';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tokensActions } from '../tokens/slice';
import { NETWORK } from './initialNetwork';
import { globalActions } from './slice';

export const Blockchain = () => {
  const dispatch = useDispatch();
  const {
    account,
    connect,
    connected,
    disconnect,
    errors,
    network,
    sendTransaction,
  } = useConnection({
    network: NETWORK,
    autoConnect: true,
  });

  useEffect(() => {
    dispatch(
      globalActions.setConnectionData({
        connect,
        connected,
        disconnect,
        errors,
        network,
        sendTransaction,
      })
    );
  }, [connect, connected, disconnect, errors, network, sendTransaction]);

  useEffect(() => {
    dispatch(globalActions.setAccount(account));
  }, [account]);

  useEffect(() => {
    dispatch(tokensActions.getPrices());
  }, []);

  return <></>;
};
