import { ChangeEvent, FC } from 'react';
import { styled, Box } from '@mui/material';

import Icon from '@/components/icon';

import RowViewIcon from 'assets/liquidity/rowViewDisactive.svg';
import PopularIcon from 'assets/swapping/popularArrow.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import { TEXT_XS_SEMIBOLD } from '@/styles/globalTypography';
import { AvailableTokenItemBox } from './AvailableTokenItemBox';
import { SearchInputEndIcon } from '@/components/input/SearchInputEndIcon';
import { NotAvailableToken } from './NotAvailableToken';
import { Token } from '@/containers/tokens/types';

interface Props {
  handleSelectItem: (symbol: string) => void;
  handleChangeSearchValue: (event: ChangeEvent<HTMLInputElement>) => void;
  tokens: Token[];
}

export const SelectTokenModalContent: FC<Props> = ({
  handleSelectItem,
  handleChangeSearchValue,
  tokens,
}) => {
  return (
    <Wrapper>
      <SearchInputEndIcon
        placeholder="Search or select token ..."
        fullWidth
        onChange={handleChangeSearchValue}
      />
      {tokens.length > 0 ? (
        <>
          <TokensContainer>
            <ContainerHeader>
              <Icon src={PopularIcon} />
              <Title>Popular tokens</Title>
            </ContainerHeader>
            <TokenItems>
              {Object.values(tokens).map((token, index) => {
                return (
                  <AvailableTokenItemBox
                    key={index}
                    symbol={token.symbol}
                    handleSelectItem={() => handleSelectItem(token.symbol)}
                  />
                );
              })}
            </TokenItems>
          </TokensContainer>
          <TokensContainer>
            <ContainerHeader>
              <Icon src={RowViewIcon} />
              <Title>Your tokens</Title>
            </ContainerHeader>
            <TokenItems>
              {Object.values(tokens).map((token, index) => {
                return (
                  <AvailableTokenItemBox
                    key={index}
                    symbol={token.symbol}
                    handleSelectItem={() => handleSelectItem(token.symbol)}
                  />
                );
              })}
            </TokenItems>
          </TokensContainer>
        </>
      ) : (
        <NotAvailableToken />
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
  padding: 8px;
`;

const TokensContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  width: 100%;
  z-index: 4;
`;

const ContainerHeader = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 8px;
`;

const Title = styled('p')`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const TokenItems = styled(Box)`
  ${COLUMN_CENTER}
  gap: 24px;
  width: 100%;
`;
