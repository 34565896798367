import { GlobalSelectors } from '@/containers/global/selectors';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useAccount = () => {
  const { account: activeAccount, connect } = useSelector(
    GlobalSelectors.coreportConnection
  );
  const [account, setAccount] = useState(activeAccount);
  useEffect(() => {
    if (activeAccount !== account) {
      setAccount(activeAccount);
    }
  }, [activeAccount]);

  return { account, connect };
};
