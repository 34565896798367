import { styled, Box } from '@mui/material';

import { TransactionItem } from './TransactionItem';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from '@/styles/globalStyles';
import { TEXT_XS_SEMIBOLD } from '@/styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';

export interface TransactionType {
  status: 'success' | 'failed' | 'pending';
  title: string;
  detail: string;
  date: Date;
  id: string | number;
}

export const TRANSACTIONS: TransactionType[] = [
  {
    status: 'success',
    title: 'Swap',
    detail: 'Swap 100 XCB to 2051.3561462314 CTN',
    date: new Date(),
    id: 1,
  },
  {
    status: 'pending',
    title: 'Transaction pending',
    detail: 'Swap 100 XCB to 2051.3561462314 CTN',
    date: new Date(),
    id: 2,
  },
  {
    status: 'failed',
    title: 'Transaction failed',
    detail: 'Swap 100 XCB to 2051.3561462314 CTN',
    date: new Date(),
    id: 3,
  },
];

export const TransactionModalContent = () => {
  return (
    <Wrapper>
      <Header>
        <LeftSideContainer>
          <Text>Transaction type</Text>
          <Text>Details</Text>
        </LeftSideContainer>
        <Text>Date and time</Text>
      </Header>
      <ContentContainer>
        {TRANSACTIONS.map((transaction) => {
          return (
            <TransactionItem key={transaction.id} transaction={transaction} />
          );
        })}
      </ContentContainer>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
`;

const Header = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width:100%;

  ${mediaQueries.lessThan('sm')`
    display: none ;
  `}
`;

const LeftSideContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 56px;
`;

const Text = styled('p')`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: left;
`;

const ContentContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 8px;
  width: 100%;
`;
