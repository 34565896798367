import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import ArrowIcon from 'assets/tokens/greenArrow.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  ROW_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_LG_MEDIUM,
  TEXT_LG_REGULAR,
} from '@/styles/globalTypography';
import TokenChart from './TokenChart';
import SwappingBox from '@/containers/swap/components/SwappingBox';
import { EXTERNAL_LINKS } from '../../constants';
import { TokenStatBox } from './TokenStatBox';
import { mediaQueries } from '@/styles/mediaQueries';
import { useScreenSize } from '@/hooks/useScreenSize';

export interface TokenOverviewProps {}

const TokenOverview: FC<TokenOverviewProps> = ({}) => {
  const { id } = useParams();

  const { isMobile } = useScreenSize();

  const symbol = id ?? 'XCB';

  return (
    <Container>
      <ChartsContainer>
        <TokenChart symbol={symbol} />
        {isMobile ? null : <SwappingBox isMobileView="true" />}
      </ChartsContainer>
      <AboutContainer>
        <Title>About</Title>
        <Description>
          On Ethereum, Uniswap Labs governance consists of a suite of smart
          contracts. However, in addition to its original deployment on Ethereum
          L1 mainnet, Uniswap contracts are also deployed on four additional
          domains - Polygon, Optimism, Celo, and Arbitrum. Uniswap deployments
          on these domains do not have native access to the on-chain governance
          system, nor the UNI tokens which grant voting power in this system on
          Ethereum mainnet; in order for the existing Uniswap governance system
          to execute proposals governing these deployments, there must be some
          method of passing governance decisions from Ethereum mainnet to other
          domains. Presently, this method has been implemented differently for
          each of the four chains. The system governing Optimism and Polygon
          follow similar design principles, and should be functional for passing
          proposals.
        </Description>
      </AboutContainer>
      <LinksContainer>
        {EXTERNAL_LINKS.map((link) => {
          return (
            <LinkBox key={link}>
              <LinkTitle>{link}</LinkTitle>
              <Icon src={ArrowIcon} />
            </LinkBox>
          );
        })}
      </LinksContainer>
      <TokenStatBox symbol={symbol} />
    </Container>
  );
};

const Container = styled(Box)`
  padding: 64px 0;
  width: 80%;
  margin: auto;
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap: 40px;

  ${mediaQueries.lessThan('sm')`
  margin:0;
  padding: 24px;
  width: 100%
`}
`;

const ChartsContainer = styled(Box)`
  width: 100%;
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 96px;

  ${mediaQueries.lessThan('sm')`
  display:block;
  width: 100%
`}
`;

const AboutContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap:16px;
`;

const Title = styled('h3')`
  ${H3_REGULAR}
`;

const Description = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const LinksContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 24px;

  ${mediaQueries.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `}
`;

const LinkBox = styled(Box)`
  ${ROW_CENTER}
  gap: 4px;
  cursor: pointer;
`;

const LinkTitle = styled('p')`
  ${TEXT_LG_MEDIUM}
  color: var(--Brand-Neon-200);
`;

const Icon = styled('img')``;

export default TokenOverview;
