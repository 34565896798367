import { useDispatch, useSelector } from 'react-redux';

import { navbarSelectors } from '../../selectors';
import { navbarActions } from '../../slice';

import { ConnectToWalletContent } from './content';
import { Modal } from '@/components/modal';

export const ConnectToWalletModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(navbarSelectors.connectToWalletModal);
  const handleModalClose = () => {
    dispatch(
      navbarActions.connectToWalletModal({
        isOpen: false,
      })
    );
  };

  return (
    <Modal
      title={'Connect a wallet'}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <ConnectToWalletContent />
    </Modal>
  );
};
