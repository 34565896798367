import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, css, styled, IconButton } from '@mui/material';

import { swapActions } from '../slice';
import { swapSelectors } from '../selectors';

import Icon from '@/components/icon';

import SettingIcon from 'assets/swapping/setting.svg';

import { ROW_ALIGN_CENTER__SPACE_B, ROW_CENTER } from '@/styles/globalStyles';
import { H2_REGULAR, H3_REGULAR } from '@/styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';
import { StringBoolean } from '@/types';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { Spinner } from '@/components/loadingIndicator/Spinner';

export interface SwapHeaderProps {
  isMobileView?: StringBoolean;
}

export const SwapHeader: FC<SwapHeaderProps> = ({ isMobileView }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(swapSelectors.settingMenu);
  const isLoadingAssetsPrices = useSelector(
    TokensSelectors.isLoadingAssetsPrices
  );

  const handleOpenSettingMenu = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(
      swapActions.toggleSwapSettingMenu({
        isOpen: !isOpen,
        anchorEl: event.currentTarget,
      })
    );
  };

  return (
    <Header>
      <Title is_mobile_view={isMobileView}>Swap</Title>
      <IconContainer>
        {isLoadingAssetsPrices ? (
          <>
            <Spinner width={40} height={40} />
            <Divider />
          </>
        ) : null}

        <IconButton onClick={handleOpenSettingMenu}>
          <Icon src={SettingIcon} />
        </IconButton>
      </IconContainer>
    </Header>
  );
};

const Header = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  z-index: 3;
`;

const Divider = styled('div')`
  height: 32px;
  border-left: 1px solid var(--Neutrals-N700);
`;

const Title = styled('h1')<{ is_mobile_view?: StringBoolean }>`
  ${H2_REGULAR}
  text-align: left;
  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      ${H3_REGULAR}
    `}

  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.01em;
    font-style: normal;
    font-weight: 400;
  `} /* ${H3_REGULAR} */
`;

const IconContainer = styled(Box)`
  ${ROW_CENTER}
  gap:16px;
  z-index: 3;
`;
