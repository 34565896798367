import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { swapActions } from '../slice';
import { swapSelectors } from '../selectors';

import { SettingMenu } from '@/components/shared/SettingMenu';

export const SwapSettingMenu: FC = () => {
  const dispatch = useDispatch();
  const { isOpen: settingIsOpen } = useSelector(swapSelectors.settingMenu);

  const { anchorEl, transactionDuration, slippageTolerance } = useSelector(
    swapSelectors.settingMenu
  );

  const { autoRouterAPI, expertMode } = useSelector(swapSelectors.settingMenu);

  const handleClose = () => {
    dispatch(
      swapActions.toggleSwapSettingMenu({ isOpen: false, anchorEl: null })
    );
  };

  const handleChangeTransactionDuration = (value: string) => {
    dispatch(swapActions.setTransactionDuration(value));
  };

  const handleChaneSlippageTolerance = (value: string) => {
    dispatch(swapActions.setSwapSlippageTolerance(value));
  };

  const handleChangeAutoRouterAPI = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(swapActions.setSwapAutoRouterAPI(event.target.checked));
  };

  const handleChangeExpertMode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(swapActions.setSwapExpertMode(event.target.checked));
  };

  return (
    <SettingMenu
      open={settingIsOpen}
      anchorEl={anchorEl}
      slippageValue={slippageTolerance}
      handleChaneSlippageTolerance={handleChaneSlippageTolerance}
      transactionDurationValue={transactionDuration}
      handleChangeTransactionDuration={handleChangeTransactionDuration}
      autoRouterAPI={autoRouterAPI}
      handleChangeAutoRouterAPI={handleChangeAutoRouterAPI}
      expertMode={expertMode}
      handleChangeExpertMode={handleChangeExpertMode}
      handleClose={handleClose}
    />
  );
};
