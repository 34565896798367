import { numberOfDecimalsForUSDT } from '@/containers/tokens/constants';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { TEXT_XS_REGULAR } from '@/styles/globalTypography';
import { styled } from '@mui/material';
import { multiply } from 'precise-math';
import { useSelector } from 'react-redux';
import PriceFormat from '../formatting/PriceFormat';

interface Props {
  symbol: string;
  value: string;
}
export const EquivalentPrice = (props: Props) => {
  const { symbol, value } = props;
  const tokenData = useSelector(TokensSelectors.tokenInfoBySymbol(symbol));
  const valueToCalculate = isNaN(Number(value)) ? 0 : Number(value);
  const calculated = multiply(tokenData?.usdPrice || 0, valueToCalculate);
  return <Price maxFraction={numberOfDecimalsForUSDT}>{calculated}</Price>;
};
const Price = styled(PriceFormat)`
  ${TEXT_XS_REGULAR}
  color: var(--Neutrals-N100);
`;
