import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { governanceActions } from './slice';
import { GlobalDomains } from '../global/selectors';
import { GlobalState } from '../global/types';
import { proposals } from './constants';

function* getProposals() {
  yield put(governanceActions.setIsGettingProposals(true));
  // FIXME: remove delay
  yield delay(4000);

  console.log('fetchProposalssss');
  // FIXME: fix mock data
  yield put(governanceActions.setProposals(proposals));
  yield put(governanceActions.setIsGettingProposals(false));
}
export function* governanceSaga() {
  yield takeLatest(governanceActions.getProposals.type, getProposals);
}
