import { FC, useEffect } from 'react';
import { Box, styled, Card, CardActionArea } from '@mui/material';

import ProposalStatusBox from './ProposalStatusBox';

import BackIcon from 'assets/governance/arrowBack.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_ALIGN_START__JUSTIFY_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import {
  TEXT_XS_SEMIBOLD,
  TEXT_LG_REGULAR,
  TEXT_M_BOLD,
  TEXT_LG_MEDIUM,
} from '@/styles/globalTypography';
import CurrentResultBox from './CurrentResultBox';
import CastingVoteBox from './CastingVoteBox';
import { Proposal } from '../types';
import { mediaQueries } from '@/styles/mediaQueries';
import Icon from '@/components/icon';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { governanceSelectors } from '../selectors';
import { governanceActions } from '../slice';

export interface VoteProposalComponentProps {}

export const VoteProposalComponent: FC<VoteProposalComponentProps> = () => {
  const dispatch = useDispatch();
  const goBack = () => {
    history.back();
  };
  const { id: stringProposalId } = useParams<{ id?: string }>();
  const proposalId = stringProposalId ? parseInt(stringProposalId, 10) : 0;

  const proposalData = useSelector(
    governanceSelectors.proposalById(proposalId)
  );
  console.log('proposalData', proposalData);

  useEffect(() => {
    dispatch(governanceActions.getProposals());
  }, []);

  return (
    <Wrapper>
      <MainContainer>
        <Card>
          <BackToProposal>
            <Icon src={BackIcon} />
            <BackText onClick={goBack}>Back to proposals</BackText>
          </BackToProposal>
        </Card>
        <TitleContainer>
          <Title>{proposalData?.title}</Title>
          <Description>{proposalData?.description}</Description>
        </TitleContainer>
        <ProposalStatusBox />
        <BackgroundContainer>
          <BackgroundTitle>Background</BackgroundTitle>
          <BackgroundDescription>
            On Ethereum, Uniswap Labs governance consists of a suite of smart
            contracts. However, in addition to its original deployment on
            Ethereum L1 mainnet, Uniswap contracts are also deployed on four
            additional domains - Polygon, Optimism, Celo, and Arbitrum. Uniswap
            deployments on these domains do not have native access to the
            on-chain governance system, nor the UNI tokens which grant voting
            power in this system on Ethereum mainnet; in order for the existing
            Uniswap governance system to execute proposals governing these
            deployments, there must be some method of passing governance
            decisions from Ethereum mainnet to other domains. Presently, this
            method has been implemented differently for each of the four chains.
            The system governing Optimism and Polygon follow similar design
            principles, and should be functional for passing proposals.
          </BackgroundDescription>
          <BackgroundList>
            <BackGroundListItem>
              On Ethereum, Uniswap Labs governance consists of a suite of smart
              contracts. However, in addition to its original deployment on
              Ethereum L1 mainnet, Uniswap contracts are also deployed on four
              additional domains - Polygon, Optimism, Celo, and Arbitrum.
            </BackGroundListItem>
            <BackGroundListItem>
              Presently, this method has been implemented differently for each
              of the four chains. The system governing Optimism and Polygon
              follow similar design principles, and should be functional for
              passing proposals.
            </BackGroundListItem>
            <BackGroundListItem>
              Uniswap deployments on these domains do not have native access to
              the on-chain governance system.
            </BackGroundListItem>
          </BackgroundList>
        </BackgroundContainer>
        <ProblemContainer>
          <ProblemTitle>Problem</ProblemTitle>
          <ProblemDescription>
            Arbitrum was initially going to allow L1 addresses to pass calls
            directly to L2 with msg.sender preserved. After realizing security
            issues with this approach, Arbitrum team instead shifted their
            approach to use aliasing (this method transforms L1 addresses to be
            a different address when represented on an L2): Uniswap Labs was not
            made aware of this change in approach before deploying on Arbitrum.
            The Uniswap Factory was deployed with the owner set to the same,
            unaliased address of the L1 Uniswap Timelock contract.
          </ProblemDescription>
        </ProblemContainer>
      </MainContainer>
      <SideBar>
        <CurrentResultBox />
        <CastingVoteBox />
      </SideBar>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: 64px 0;
  ${ROW_ALIGN_START__JUSTIFY_CENTER}
  width: 80%;
  margin: auto;
  gap: 96px;

  ${mediaQueries.lessThan('sm')`
    padding: 24px;
    width: 100%;
    gap:40px;
    flex-direction: column;
    align-items: center;
  `}
`;

const MainContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const BackToProposal = styled(CardActionArea)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 8px;
  background-color: var(--Black);
`;

const BackText = styled('p')`
  ${TEXT_XS_SEMIBOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--Neutrals-N100);
`;

const TitleContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 24px;
`;

const Title = styled('h1')`
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
`;

const Description = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N50);
  text-align: left;
`;

const StatusBox = styled(Box)``;

const BackgroundContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
`;

const BackgroundTitle = styled('p')`
  ${TEXT_M_BOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

const BackgroundDescription = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const BackgroundList = styled('ul')`
  padding: 16px 0 0;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 24px;
`;

const BackGroundListItem = styled('li')`
  ${TEXT_LG_MEDIUM}
  color:var(--Neutrals-N100);
  text-align: left;
  padding-left: 40px;
  position: relative;
  &::before {
    content: '•';
    position: absolute;
    left: 10px;
    color: var(--Brand-Neon-200);
  }
`;

const ProblemContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
`;

const ProblemTitle = styled('p')`
  ${TEXT_M_BOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;

const ProblemDescription = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const SideBar = styled(Box)`
  ${COLUMN_CENTER}
  gap:32px;
`;
