import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';
import { add, divide, multiply } from 'precise-math';

export const GovenanceDomains = {
  root: (state: RootState) => state?.governance,
  isGettingProposal: (state: RootState) =>
    state?.governance?.isGettingProposals,
  error: (state: RootState) => state?.governance?.error,
  proposals: (state: RootState) => state?.governance?.proposals,
  createProposalModal: (state: RootState) =>
    state.governance.createProposalModal,
};

export const governanceSelectors = {
  root: createSelector(GovenanceDomains.root, (root) => root),
  error: createSelector(GovenanceDomains.error, (error) => error),
  proposals: createSelector(
    [GovenanceDomains.proposals],
    (proposals) => proposals
  ),
  proposalById: (id: number) =>
    createSelector([GovenanceDomains.proposals], (proposals) => {
      if (proposals) {
        return Object.values(proposals).find((proposal) => proposal.id === id);
      }
      return null;
    }),
  closeProposals: createSelector([GovenanceDomains.proposals], (proposals) => {
    // return proposals that with "cancelled" or "executed" proposalStatus property
    if (proposals) {
      // return Object.keys(proposals).map((proposalId) => proposals[proposalId]).filter((proposal) => proposal.proposalStatus === 'cancelled');
      return Object.values(proposals).filter(
        (proposal) =>
          proposal.proposalStatus === 'cancelled' ||
          proposal.proposalStatus === 'Executed'
      );
    }
    return [];
  }),
  openProposals: createSelector([GovenanceDomains.proposals], (proposals) => {
    // return proposals that with "open" proposalStatus property
    if (proposals) {
      // return Object.keys(proposals).map((proposalId) => proposals[proposalId]).filter((proposal) => proposal.proposalStatus === 'cancelled');
      return Object.values(proposals).filter(
        (proposal) => proposal.proposalStatus === 'open'
      );
    }
    return [];
  }),
  favoredVotesById: (id: number) =>
    createSelector([GovenanceDomains.proposals], (proposals) => {
      if (proposals) {
        const proposal = Object.values(proposals).find(
          (proposal) => proposal.id === id
        );
        const favoredVotesValue = proposal
          ? multiply(
              divide(
                proposal.votes.agree,
                add(proposal.votes.agree, proposal.votes.disagree)
              ),
              100
            )
          : 0;
        return Math.trunc(favoredVotesValue);
      }
      return 0;
    }),
  againstVotesById: (id: number) =>
    createSelector([GovenanceDomains.proposals], (proposals) => {
      if (proposals) {
        const proposal = Object.values(proposals).find(
          (proposal) => proposal.id === id
        );
        const againstVoteValues = proposal
          ? multiply(
              divide(
                proposal.votes.disagree,
                add(proposal.votes.agree, proposal.votes.disagree)
              ),
              100
            )
          : 0;
        return Math.trunc(againstVoteValues);
      }
      return 0;
    }),
  createProposalModal: createSelector(
    GovenanceDomains.createProposalModal,
    (createProposalModal) => createProposalModal
  ),
};
