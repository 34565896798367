// this function receives a number, and retures another number with desired decimal places and also removes zeros from end
// this is generated with copilot
export function decimalShortener({
  number,
  decimals,
}: {
  number: number;
  decimals: number;
}): number {
  const numberString = number.toString();
  const numberStringSplit = numberString.split('.');
  if (numberStringSplit.length === 1) {
    return number;
  }
  const decimalPart = numberStringSplit[1];
  const decimalPartLength = decimalPart.length;
  if (decimalPartLength <= decimals) {
    return number;
  }
  const decimalPartShortened = decimalPart.slice(0, decimals);
  const decimalPartShortenedNumber = Number(decimalPartShortened);
  const decimalPartShortenedNumberString =
    decimalPartShortenedNumber.toString();
  const decimalPartShortenedNumberStringLength =
    decimalPartShortenedNumberString.length;
  const decimalPartShortenedNumberStringLastChar =
    decimalPartShortenedNumberString[
      decimalPartShortenedNumberStringLength - 1
    ];
  if (decimalPartShortenedNumberStringLastChar === '0') {
    const decimalPartShortenedNumberStringWithoutZeros =
      decimalPartShortenedNumberString.replace(/0+$/, '');
    const decimalPartShortenedNumberStringWithoutZerosLength =
      decimalPartShortenedNumberStringWithoutZeros.length;
    if (decimalPartShortenedNumberStringWithoutZerosLength === 0) {
      return Number(numberStringSplit[0]);
    }
    return Number(
      `${numberStringSplit[0]}.${decimalPartShortenedNumberStringWithoutZeros}`
    );
  }
  return Number(`${numberStringSplit[0]}.${decimalPartShortenedNumberString}`);
}
// Compare this snippet from src\app\store\assets\selectors.ts:
