export const networkId = 1;

export const TIME_BASIS = {
  // '1M': '1MM',
  // '5M': '5M',
  // '15M': '15M',
  // '30M': '30M',
  '1H': '1H',
  '4H': '4H',
  '1D': '1D',
  '1W': '1W',
  '1Mn': '1Mn',
  '1Y': '1Y',
};

export const SORT_BY = {
  HOT: 'HOT',
  NEW: 'NEW',
};
