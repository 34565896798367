import { runSaga } from '@/store/configureStore';
import { globalSaga } from './global/saga';
import { governanceSaga } from './governance/saga';
import { liquiditySaga } from './liquidity/saga';
import { swapSaga } from './swap/saga';
import { tokensSaga } from './tokens/saga';
import { navbarSaga } from './navbar/saga';

export const runSagas = () => {
  runSaga(globalSaga);
  runSaga(liquiditySaga);
  runSaga(governanceSaga);
  runSaga(swapSaga);
  runSaga(tokensSaga);
  runSaga(navbarSaga);
};
