import { NETWORK } from '@/containers/global/initialNetwork';
import { GlobalDomains } from '@/containers/global/selectors';
import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { CoreCoinData } from '@/containers/tokens/initialTokens';
import { RouterContract__factory } from '@/contracts/router';
import { calculateDeadline } from '@/utils/deadline';
import { calRemainValueOfPercentage } from '@/utils/numbers';
import {
  AbstractProvider,
  AddressLike,
  Block,
  ContractTransaction,
  ethers,
  formatUnits,
  parseUnits,
} from 'm_ccbc';
import { all, call, select } from 'redux-saga/effects';
import { liquidityDomains, liquiditySelectors } from '../selectors';
import { LiquidityState, Pool } from '../types';
import { Token } from '@/containers/tokens/types';
import {
  ROUTER_CONTRACT_ADDRESS,
  UNISWAP_V2_CONTRACT_ADDRESS,
} from '@/services/constants';
import { UniswapV2Factory__factory } from '@/contracts/uniswapfactory';
import { TypedContractMethod } from '@/contracts/uniswapfactory/common';
import { UniswapV2Pair__factory } from '@/contracts/uniswapPair';
import { add, divide, multiply, subtract } from 'precise-math';
import { getTokenDetails } from '@/containers/tokens/providers/apis';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { timeIntervalOptions } from '../constants';
import { TimeBasisType } from '@/types';
interface Props {
  token1: Token;
  token2: Token;
  token1Amount: number;
  token2Amount: number;
}

export function* startAddLiquidityProcess({
  token1,
  token1Amount,
  token2,
  token2Amount,
}: Props) {
  const { address: token1Address, decimals: token1Decimals } = token1;
  const { address: token2Address, decimals: token2Decimals } = token2;

  const { slippageTolerance }: LiquidityState['liquiditySettingMenu'] =
    yield select(liquidityDomains.liquiditySettingMenu);

  const token1MinAmount = calRemainValueOfPercentage(
    token1Amount,
    slippageTolerance
  );
  const token2MinAmount = calRemainValueOfPercentage(
    token2Amount,
    slippageTolerance
  );
  console.log({ token1MinAmount, token2MinAmount });

  const token1MinAmountWithDecimals = parseUnits(
    token1MinAmount.toString(),
    token1Decimals
  );
  const token2MinAmountWithDecimals = parseUnits(
    token2MinAmount.toString(),
    token2Decimals
  );

  const token1AmountWithDecimals = parseUnits(
    token1Amount.toString(),
    token1Decimals
  );
  const token2AmountWithDecimals = parseUnits(
    token2Amount.toString(),
    token2Decimals
  );

  const rpcUrl = NETWORK.rpcUrl;
  const provider: AbstractProvider = yield call(getRpcProvider, rpcUrl);
  const contract = RouterContract__factory.connect(
    ROUTER_CONTRACT_ADDRESS,
    provider
  );

  const userAddress: string = yield select(GlobalDomains.activeWalletAddress);
  let unsignedTransaction: ContractTransaction;
  if (token2Address === CoreCoinData.address) {
    unsignedTransaction = yield contract.addLiquidityETH.populateTransaction(
      token1Address,
      token1AmountWithDecimals,
      token1MinAmountWithDecimals,
      token2MinAmountWithDecimals,
      userAddress,
      calculateDeadline(),
      {
        value: token2AmountWithDecimals,
      }
    );
  } else {
    unsignedTransaction = yield contract.addLiquidity.populateTransaction(
      token1Address,
      token2Address,
      token1AmountWithDecimals,
      token2AmountWithDecimals,
      token1MinAmountWithDecimals,
      token2MinAmountWithDecimals,
      userAddress,
      calculateDeadline()
    );
  }

  return unsignedTransaction;
}

export function* startGetListOfPools() {
  const rpcUrl = NETWORK.rpcUrl;
  const provider: AbstractProvider = yield call(getRpcProvider, rpcUrl);

  const contract = UniswapV2Factory__factory.connect(
    UNISWAP_V2_CONTRACT_ADDRESS,
    provider
  );
  // get how many pools are exist
  const length: bigint = yield contract.allPairsLength();
  const lengthOfPools = Number(length);

  const poolsAddress: string[] = [];
  const toCall = [];

  // a function to fetch all pools
  // FIXME
  // @ts-ignore
  function* toFetch(i: number): Pool {
    // fetched the pair address
    const poolAddress: string = yield contract.allPairs(i);
    poolsAddress.push(poolAddress);
    // fetched the pair contract
    const poolContract = UniswapV2Pair__factory.connect(poolAddress, provider);
    // fetched the addresses of pair tokens
    const [token0Address, token1Address] = yield all([
      poolContract.token0(),
      poolContract.token1(),
    ]);
    // get token details from token slice
    const token0Details: Token = yield select(
      TokensSelectors.tokenInfoByAddress(token0Address)
    );
    const token1Details: Token = yield select(
      TokensSelectors.tokenInfoByAddress(token1Address)
    );

    const userAddress: string = yield select(GlobalDomains.activeWalletAddress);
    // fetch the balance of the active wallet, total supply, and
    // the pair reserves amount of tokens
    const balanceOfCall: Promise<bigint> = poolContract.balanceOf(userAddress);
    const totalSupplyCall: Promise<bigint> = poolContract.totalSupply();
    const reservesCall = poolContract.getReserves();
    const [balanceOf, totalSupply, reserves]: [bigint, bigint, any] = yield all(
      [balanceOfCall, totalSupplyCall, reservesCall]
    );

    // get the selected interval in the pool page
    const { timeBasis: selectedInterval } = yield select(
      liquiditySelectors.poolPageData
    );

    // fetched pair details for selected interval
    const { totalVolume, fee, swapEventsNumber, firstMintBlockNumber } =
      yield getTotalVolumeLastMonth(
        poolAddress,
        provider,
        token0Details,
        token1Details,
        selectedInterval
      );

    // format reserves to number/usdt/with decimals
    const reserve0 = Number(reserves[0]);
    const reserve1 = Number(reserves[1]);

    const reserve0USD = multiply(reserve0, token0Details.usdPrice || 1);
    const reserve1USD = multiply(reserve1, token1Details.usdPrice || 1);

    const reserve0WithDecimals = divide(
      reserve0USD,
      Math.pow(10, token0Details.decimals)
    );
    const reserve1WithDecimals = divide(
      reserve1USD,
      Math.pow(10, token1Details.decimals)
    );

    // calc the total liquidity based on the reserves amounts
    const totalLiquidity = add(
      Number(reserve0WithDecimals),
      Number(reserve1WithDecimals)
    );
    // calc liquidity pool share of acc based in the balanceOf & totalSupply
    const lpShare = Number(balanceOf) / Number(totalSupply);
    // calc user liquidity based on the lpShare & totalLiquidity
    const userLiquidity = multiply(lpShare, totalLiquidity);
    // calc user liquidity based on the userLiquidity & totalLiquidity, & fee
    const userShareOfFee = (userLiquidity / totalLiquidity) * fee;

    // returned the pool pair detail
    const poolsNewProperty = {
      address: poolAddress,
      reserveToken0: reserve0WithDecimals,
      reserveToken1: reserve1WithDecimals,
      totalLiquidity: totalLiquidity,
      token0: token0Details,
      token1: token1Details,
      volume: totalVolume,
      fee: fee,
      userLiquidity: userLiquidity,
      userShareOfFee: userShareOfFee,
      lpShare: lpShare,
      swapEventsNumber: swapEventsNumber,
      firstMintBlockNumber: firstMintBlockNumber,
    };
    return poolsNewProperty;
  }
  // loop over pools addreses and add toFetch call func to toCall array
  for (let i = 0; i < lengthOfPools; i++) {
    toCall.push(toFetch(i));
  }
  // fetch pools
  const pools: Pool[] = yield all(toCall);
  return pools;
}

async function getTotalVolumeLastMonth(
  poolAddress: string,
  provider: AbstractProvider,
  token0Details: Token,
  token1Details: Token,
  selectedInterval: TimeBasisType
): Promise<{
  totalVolume: number;
  fee: number;
  swapEventsNumber: number;
  firstMintBlockNumber: number;
}> {
  const poolContract = UniswapV2Pair__factory.connect(poolAddress, provider);

  // filter events to get the mint events of the pool
  const filterMint = poolContract.filters.Mint();
  const mintEventsCall = poolContract.queryFilter(filterMint);

  // get the current block number
  const blockNumberCall = provider.getBlockNumber();

  // call and get mint events and the current block number
  const [mintEvents, blockNumber] = await Promise.all([
    mintEventsCall,
    blockNumberCall,
  ]);

  // calc the first mint block number
  // this number is used to sort the pair based on creation time
  const firstMintBlockNumber = mintEvents[0].blockNumber || 0;

  // calc the selected interval time
  const selectedIntervalValue = timeIntervalOptions[selectedInterval];

  // calc the block number of one month ago
  // assuming 7 seconds as average block time
  const oneMonthAgoBlockNumber = Math.round(
    blockNumber - selectedIntervalValue / 7
  );

  // filter events to get the mint events of the pool
  // in the selected interval
  const filter = poolContract.filters.Swap();
  const swapEvents = await poolContract.queryFilter(
    filter,
    oneMonthAgoBlockNumber > 0 ? oneMonthAgoBlockNumber : 1,
    blockNumber
  );

  // calc the swap events number to sort the pairs based on recent activities
  const swapEventsNumber = swapEvents.length || 0;

  let totalVolume = 0;
  let fee = 0;

  // set the feePercentage to 0.003
  // FIXME fetch the feePercentage from the contract
  const feePercentage = 0.003;

  for (const event of swapEvents) {
    const amount0In = Number(event.args.amount0In);
    const amount0Out = Number(event.args.amount0Out);
    const amount1In = Number(event.args.amount1In);
    const amount1Out = Number(event.args.amount1Out);
    // TODO use m_cbcc bignumber
    const amount0InUSDT = divide(
      multiply(amount0In, token0Details.usdPrice || 1),
      Math.pow(10, token0Details.decimals)
    );
    const amount0OutUSDT = divide(
      multiply(amount0Out, token0Details.usdPrice || 1),
      Math.pow(10, token0Details.decimals)
    );
    formatUnits;
    const amount1InUSDT = divide(
      multiply(amount1In, token1Details.usdPrice || 1),
      Math.pow(10, token1Details.decimals)
    );
    const amount1OutUSDT = divide(
      multiply(amount1Out, token1Details.usdPrice || 1),
      Math.pow(10, token1Details.decimals)
    );

    // calc totalVolume from as addition of all amounts in & out
    let totalAmountIn = add(amount0InUSDT, amount1InUSDT);
    totalVolume = add(
      totalVolume,
      amount0InUSDT,
      amount0OutUSDT,
      amount1InUSDT,
      amount1OutUSDT
    );
    // calc fee as feePercentage of totalAmountIn
    fee = add(fee, multiply(totalAmountIn, feePercentage));
  }

  return { totalVolume, fee, swapEventsNumber, firstMintBlockNumber };
}
