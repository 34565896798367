import { getItemFromLocalStorage } from '@/utils/localStorage';

import CTNIcon from 'assets/swapping/ctnIcon.svg';
import XCBIcon from 'assets/swapping/xcbIcon.svg';
import { LiquidityState, Pool } from './types';
import { LiquidityListType } from './components/liquidity/LiquidityBox';
import { CoreCoinData } from '../tokens/initialTokens';

export const LIQUIDITY_ROUTE = '/pools/liquidity';
export const POOLS_ROUTE = '/pools/pools';

export const ROUTES_BASE_ON_TAB_VALUE = [LIQUIDITY_ROUTE, POOLS_ROUTE];

export const availableLiquidity: LiquidityListType[] = [
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XCB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XAB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XDB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XPB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XGB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
  {
    tkn1: 'XCB',
    tkn2: 'CTN',
    pairCoin: 'XTB / CTN',
    fromIcon: XCBIcon,
    toIcon: CTNIcon,
    liquidityValue: '128694',
    LP: '0.0431 LP',
    fromPool: '0.050156546846 XCB',
    toPool: '0.995489 CTN',
    share: '<0.1%',
  },
];

export const LOCALSTORAGE_KEYS = {
  LIQUIDITY_SETTING: 'liquidity-setting',
  POOL_SETTING: 'pool-setting',
  POOL_PAGE_DATA: 'pool-page-data',
};

export const storagedLiquiditySetting: LiquidityState['liquiditySettingMenu'] =
  getItemFromLocalStorage(LOCALSTORAGE_KEYS.LIQUIDITY_SETTING);

export const storagedPoolSetting: LiquidityState['poolSettingMenu'] =
  getItemFromLocalStorage(LOCALSTORAGE_KEYS.POOL_SETTING);

export const storagedPoolPageData: LiquidityState['poolPageData'] =
  getItemFromLocalStorage(LOCALSTORAGE_KEYS.POOL_PAGE_DATA);

// FIXME assign type
export const timeIntervalOptions: any = {
  '1H': 60 * 60,
  '4H': 4 * 60 * 60,
  '1D': 24 * 60 * 60,
  '1W': 7 * 24 * 60 * 60,
  '1Mn': 30 * 24 * 60 * 60,
  '1Y': 365 * 30 * 24 * 60 * 60,
};

export const poolInitialData: Pool = {
  address: '',
  reserveToken0: 0,
  reserveToken1: 0,
  totalLiquidity: 0,
  volume: 0,
  fee: 0,
  token0: CoreCoinData,
  token1: CoreCoinData,
  userLiquidity: 0,
  userShareOfFee: 0,
  lpShare: 0,
  swapEventsNumber: 0,
  firstMintBlockNumber: 0,
};
