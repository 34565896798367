import { useEffect, useRef, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { CoreNetwork } from './network';
import { TransactionLike } from './transaction';
import { customToast } from '@/components/toast/CustomToast';
import { Connect } from 'vite';
import { ConnectionFailedToast } from '@/containers/navbar/components/ConnectionFailedToast';
export const useConnection = ({
  network,
  autoConnect,
}: {
  network: CoreNetwork;
  autoConnect: boolean;
}) => {
  const [accountDetails, setAccountDetails] = useState({
    account: '',
    connected: false,
  });
  const [errors, setErrors] = useState({});

  const isCoreportInitializedRef = useRef(false);

  const connect = () => {
    // @ts-ignore
    window.coreport
      ? // @ts-ignore
        window.coreport.connect(network)
      : customToast.error(<ConnectionFailedToast />);
  };
  const disconnect = () => {
    // @ts-ignore
    window.coreport?.disconnect();
  };
  const sendTransaction = (tx: TransactionLike) => {
    // @ts-ignore
    return window.coreport?.sendTransaction(tx);
  };
  const sign = (message: string) => {
    // @ts-ignore
    return window.coreport?.sign(message);
  };
  useEffect(() => {
    let subscription: any;
    let errorSubscription: any;
    window.addEventListener(
      'coreport#initialized',
      () => {
        if (subscription) {
          subscription.unsubscribe();
        }
        if (errorSubscription) {
          errorSubscription.unsubscribe();
        }
        if (!isCoreportInitializedRef.current) {
          const messageService: Subject<any> =
            // @ts-ignore
            window.coreport.MessageService.Subscriber;
          subscription = messageService.subscribe((message: any) => {
            if (message.type === 'connectionRequestAcceptOrReject') {
              // @ts-ignore
              const connectedAccount = window.coreport.connectedAccount;
              setAccountDetails({
                account: connectedAccount,
                connected: message.accepted,
              });
            } else if (message.type === 'disconnect') {
              setAccountDetails({
                account: '',
                connected: false,
              });
            } else if (message.type === 'accountChanged') {
              disconnect();
            }
          });
          const errorsMessageService: BehaviorSubject<{
            [key: string]: string;
          }> =
            //@ts-ignore
            window.coreport.ErrorMessagesService.Subscriber;
          errorSubscription = errorsMessageService.subscribe((errors: any) => {
            setErrors(() => ({ ...errors }));
          });

          isCoreportInitializedRef.current = true;
          if (autoConnect) {
            // @ts-ignore
            window.coreport?.checkConnection(network);
          }
        }
      },
      false
    );
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (errorSubscription) {
        errorSubscription.unsubscribe();
      }
    };
  }, []);
  const hasError = Object.keys(errors).length > 0;

  return {
    connected: accountDetails.connected,
    account: accountDetails.account,
    network,
    connect,
    disconnect,
    errors,
    sendTransaction,
    sign,
    hasError,
  };
};
