import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { navbarSelectors } from '../../selectors';
import { navbarActions } from '../../slice';

import { Modal } from '@/components/modal';
import { NotificationModalContent } from './content';

export const NotificationModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(navbarSelectors.notificationModal);
  const handleModalClose = () => {
    dispatch(navbarActions.notificationModal({ isOpen: false }));
  };

  return (
    <CModal title={'Notification'} isOpen={isOpen} onClose={handleModalClose}>
      <NotificationModalContent />
    </CModal>
  );
};

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 928px;
    max-height: 864px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 928px;
    max-height: 864px;
  }
`;
