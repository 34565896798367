import { FC } from 'react';
import { Button, ButtonProps, css, styled } from '@mui/material';
import { mediaQueries } from '@/styles/mediaQueries';
import { BORDER_BLACK_WHITE_8, ROW_CENTER } from '@/styles/globalStyles';
import { Spinner } from '../loadingIndicator/Spinner';

export interface BaseButtonProps extends ButtonProps {
  gradient_border?: 'none' | 'white' | 'green';
  size?: 'small' | 'medium' | 'large';
  isLoading?: boolean;
  loadingText?: string;
}

const BaseButton: FC<BaseButtonProps> = ({
  children,
  size = 'medium',
  gradient_border: gradientBorder = 'white',
  isLoading = false,
  loadingText,
  endIcon,
  startIcon,
  ...props
}) => {
  return (
    <BButton
      variant="contained"
      size={size}
      gradient_border={gradientBorder}
      endIcon={!isLoading ? endIcon : null}
      startIcon={!isLoading ? startIcon : null}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <LoadingContainer>
          {loadingText}
          <Spinner />
        </LoadingContainer>
      ) : (
        children
      )}
    </BButton>
  );
};

const BButton = styled(Button)<BaseButtonProps>`
  border-radius: 8px;
  background-color: var(--Neutrals-N700);
  color: var(--White);
  position: relative;
  text-transform: none;

  &:hover {
    background-color: var(--Neutrals-N600);
    color: var(--White);
  }

  &:hover::before {
    opacity: 0;
  }

  &.Mui-disabled {
    background-color: var(--Neutrals-N800);
    color: var(--Neutrals-N400);
    &::before {
      display: none;
    }
  }

  &.MuiButton-root {
    min-width: auto;
  }

  ${({ gradient_border: gradientBorder }) =>
    (gradientBorder === 'none' &&
      css`
        border: none;
      `) ||
    (gradientBorder === 'white' &&
      css`
        ${BORDER_BLACK_WHITE_8}
      `) ||
    (gradientBorder === 'green' &&
      css`
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          border-radius: 8px;
          padding: 1px;
          background: linear-gradient(
            135deg,
            rgba(209, 254, 146, 1),
            rgba(9, 150, 105, 1)
          );
          -webkit-mask: linear-gradient(var(--White) 0 0) content-box,
            linear-gradient(var(--White) 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      `)}

  ${({ size }) =>
    (size === 'small' &&
      css`
        //some css for shared small button
      `) ||
    (size === 'medium' &&
      css`
        padding: 12px 24px;
        font-style: normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        ${mediaQueries.lessThan('sm')`
          padding: 12px 16px;
        `}
      `) ||
    (size === 'large' &&
      css`
        //some css for shared large button
      `)}
`;

const LoadingContainer = styled('div')`
  ${ROW_CENTER}
  gap: 16px;
  opacity: 0.5;
`;
export default BaseButton;
