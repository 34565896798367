import { Box, Button, css, IconButton, styled } from '@mui/material';
import Icon from '../icon';
import NumberInput from 'components/input/numberInput';

import ChevronDown from 'assets/swapping/chevronDown.svg';

import { mediaQueries } from '@/styles/mediaQueries';
import {
  COLUMN_ALIGN_END__JUSTIFY_START,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from 'styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_SM_REGULAR,
  TEXT_XL_MEDIUM,
  TEXT_XL_REGULAR,
  TEXT_XS_SEMIBOLD,
} from 'styles/globalTypography';
import { useState } from 'react';
import { Token } from '@/containers/tokens/types';
import { useSelector } from 'react-redux';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { divide } from 'precise-math';
import { EquivalentPrice } from '../info/equivalentPrice';
import { StringBoolean } from '@/types';
import { TokenIcon } from '../icon/TokenIcon';

interface Props {
  headerText: string;
  symbol: string;
  isMobileView: StringBoolean;
  onChange: (value: string) => void;
  value: string;
  onDropdownClick: () => void;
  hasActions?: boolean;
}

export const TokenValueItem = (props: Props) => {
  const {
    headerText,
    symbol,
    isMobileView = 'false',
    onChange,
    value,
    onDropdownClick,
    hasActions = true,
  } = props;
  const [isInputFocus, setIsInputFocus] = useState<'false' | 'true'>('false');
  const tokenData = useSelector(
    TokensSelectors.tokenInfoBySymbol(symbol)
  ) as Token;
  const balance = tokenData?.balance || 0;
  const isMaxActive =
    balance !== 0 &&
    !isNaN(Number(value)) &&
    Number(balance) === Number(value || 0);
  const isHalfActive =
    balance !== 0 &&
    !isNaN(Number(value)) &&
    Number(value) === Number(divide(balance, 2));

  const handleFocusInput = () => {
    setIsInputFocus(isInputFocus === 'true' ? 'false' : 'true');
  };

  const handleMaxbuttonClick = () => {
    onChange(balance.toString());
  };
  const handleHalfbuttonClick = () => {
    onChange(divide(balance, 2).toString());
  };

  return (
    <ItemBox is_focus={isInputFocus}>
      <ItemHeader>
        <HeaderText> {headerText}</HeaderText>
        <HeaderText> Balance: {balance}</HeaderText>
      </ItemHeader>
      <ItemContent>
        <CoinSection>
          <TokenIcon address={tokenData?.address} />
          <CoinName>{tokenData?.symbol}</CoinName>
          <IconButton onClick={onDropdownClick}>
            <Icon src={ChevronDown} />
          </IconButton>
        </CoinSection>
        <Divider is_mobile_view={isMobileView} />
        {hasActions && (
          <ButtonsSection>
            <ValueButton
              is_mobile_view={isMobileView}
              onClick={handleMaxbuttonClick}
              is_active={isMaxActive ? 'true' : 'false'}
            >
              Max
            </ValueButton>
            <ValueButton
              is_mobile_view={isMobileView}
              onClick={handleHalfbuttonClick}
              is_active={isHalfActive ? 'true' : 'false'}
            >
              Half
            </ValueButton>
          </ButtonsSection>
        )}
        <Input
          placeholder="0"
          is_mobile_view={isMobileView}
          value={value}
          maxFraction={tokenData.decimals}
          onChange={onChange}
          onFocus={handleFocusInput}
          onBlur={handleFocusInput}
        />
      </ItemContent>
      <EquivalentPrice symbol={tokenData.symbol} value={value} />
    </ItemBox>
  );
};

const Input = styled(NumberInput)<{ is_mobile_view?: StringBoolean }>`
  ${TEXT_XL_REGULAR}

  .MuiInputBase-input {
    &::placeholder {
      opacity: 1;
    }
  }
  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      ${H3_REGULAR}
    `}
  @media screen and (max-width: 414px) {
    ${H3_REGULAR}
  }
`;

const ItemBox = styled(Box)<{ is_focus: 'false' | 'true' }>`
  ${COLUMN_ALIGN_END__JUSTIFY_START}
  gap: 8px;
  border: 1px var(--Neutrals-N700) solid;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  background-color: var(--Neutrals-N900);
  z-index: 3;

  ${({ is_focus }) =>
    is_focus === 'true' &&
    css`
      background-color: var(--Neutrals-N800);
    `}
`;
const ItemHeader = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;

const HeaderText = styled('p')`
  ${TEXT_SM_REGULAR}
  color: var(  --Neutrals-N100);
`;

const ItemContent = styled(Box)`
  ${ROW_CENTER}
  gap: 16px;
  width: 100%;
`;

const CoinSection = styled('div')`
  ${ROW_CENTER}
  gap: 8px;
`;

const CoinName = styled('p')`
  ${TEXT_XL_MEDIUM}
`;

const Divider = styled('div')<{ is_mobile_view?: StringBoolean }>`
  height: 40px;
  border-left: 1px solid var(--Neutrals-N800);

  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      display: none;
    `}
  ${mediaQueries.lessThan('sm')`
        display:none;
      `}
`;

const ButtonsSection = styled('div')`
  ${ROW_CENTER}
  gap:8px;
`;

const ValueButton = styled(Button)<{
  is_mobile_view?: StringBoolean;
  is_active: StringBoolean;
}>`
  padding: 4px 8px;
  border: 1px solid var(--Neutrals-N700);
  color: var(--Neutrals-N500);
  min-width: auto;
  ${TEXT_XS_SEMIBOLD}
  text-transform: uppercase;
  letter-spacing: 0.1em;

  ${({ is_active }) =>
    is_active === 'true' &&
    css`
      background-color: var(--Neutrals-N700);
      border-color: var(--Neutrals-N500);
      color: var(--Neutrals-N100);
    `}

  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      display: none;
    `}
      ${mediaQueries.lessThan('sm')`
        display:none;
      `}
`;
