import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { divide } from 'precise-math';
import { initialTokens } from './initialTokens';
import { TokensState } from './types';
import { decimalShortener } from './utils/decimalShortener';
import { storagedTokensData } from './constants';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '@/utils/localStorage';
import { LOCALSTORAGE_KEYS } from './constants';

const initialState: TokensState = {
  isGettingTokens: false,
  tokens: initialTokens,
  isGettingAllBalances: false,
  isLoadingInitialPrices: false,
  error: '',
  searchValue: '',
  tokenSetting: {
    chartTimeFrame: storagedTokensData?.chartTimeFrame
      ? (storagedTokensData.chartTimeFrame as TokensState['tokenSetting']['chartTimeFrame'])
      : '1W',

    timeBasis: storagedTokensData?.timeBasis
      ? (storagedTokensData.timeBasis as TokensState['tokenSetting']['timeBasis'])
      : '1W',
    sortBy: storagedTokensData?.sortBy
      ? (storagedTokensData.sortBy as TokensState['tokenSetting']['sortBy'])
      : 'HOT',
  },
};
// tokens page slice
export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    getAdditionalTokensFromBlockChain: () => {},
    addTokens: (state, action: PayloadAction<TokensState['tokens']>) => {
      state.tokens = { ...initialTokens, ...action.payload };
    },
    setIsLoadingTokens: (state, action: PayloadAction<boolean>) => {
      state.isGettingTokens = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    // new
    updateTokenPrice(
      state,
      action: PayloadAction<{
        tokenSymbol: string;
        value: number;
      }>
    ) {
      const { tokenSymbol, value } = action.payload;
      state.tokens[tokenSymbol].usdPrice = value;
    },
    getPrices() {},
    getAllBalances(state, action: PayloadAction<{ silent?: boolean }>) {},
    setIsGettingAllBalances(state, action: PayloadAction<boolean>) {
      state.isGettingAllBalances = action.payload;
    },
    setIsLoadingPrices(state, action: PayloadAction<boolean>) {
      state.isLoadingInitialPrices = action.payload;
    },
    batchUpdateTokenBalance(
      state,
      action: PayloadAction<{
        tokens: {
          tokenSymbol: string;
          value: number;
          decimals: number;
        }[];
      }>
    ) {
      const { tokens } = action.payload;
      tokens.forEach((token) => {
        const num = divide(token.value, 10 ** token.decimals);
        const actualValue = decimalShortener({
          number: num,
          decimals: token.decimals,
        });
        state.tokens[token.tokenSymbol].balance = actualValue;
      });
    },
    batchUpdateTokenPrice(
      state,
      action: PayloadAction<{
        tokens: { tokenSymbol: string; value: number }[];
      }>
    ) {
      const { tokens } = action.payload;
      tokens.forEach((token) => {
        state.tokens[token.tokenSymbol].usdPrice = token.value;
      });
    },
    getBalanceForToken(
      state,
      action: PayloadAction<{ tokenSymbol: string }>
    ) {},
    setIsGettingTokenBalance(
      state,
      action: PayloadAction<{
        tokenSymbol: string;
        isGettingBalance: boolean;
      }>
    ) {
      const { isGettingBalance, tokenSymbol } = action.payload;
      state.tokens[tokenSymbol].isGettingBalance = isGettingBalance;
    },
    setTokenBalance(
      state,
      action: PayloadAction<{
        tokenSymbol: string;
        balance: number;
        decimals: number;
      }>
    ) {
      const { balance, tokenSymbol, decimals } = action.payload;
      const num = divide(balance, 10 ** decimals);
      const actualValue = decimalShortener({
        number: num,
        decimals: decimals,
      });
      state.tokens[tokenSymbol].balance = actualValue;
    },
    setChartTimeFrame(
      state,
      action: PayloadAction<TokensState['tokenSetting']['chartTimeFrame']>
    ) {
      state.tokenSetting.chartTimeFrame = action.payload;
      setItemToLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING, {
        ...getItemFromLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING),
        chartTimeFrame: action.payload,
      });
    },
    setTimeBasis(
      state,
      action: PayloadAction<TokensState['tokenSetting']['timeBasis']>
    ) {
      state.tokenSetting.timeBasis = action.payload;
      setItemToLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING, {
        ...getItemFromLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING),
        timeBasis: action.payload,
      });
    },
    setSortBy(
      state,
      action: PayloadAction<TokensState['tokenSetting']['sortBy']>
    ) {
      state.tokenSetting.sortBy = action.payload;
      setItemToLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING, {
        ...getItemFromLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING),
        sortBy: action.payload,
      });
    },
  },
});

export const { actions: tokensActions, reducer: tokensReducer } = tokensSlice;
