import { FC } from 'react';
import { Box, styled } from '@mui/material';

import BaseButton from '@/components/button/BaseButton';

import ClockIcon from 'assets/governance/clock.svg';
import WarningIcon from 'assets/governance/warn.svg';
import CheckedIcon from 'assets/governance/checked.svg';

import {
  BORDER_BLACK_WHITE_16,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from 'styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_LG_SEMIBOLD,
  TEXT_M_REGULAR,
  TEXT_SM_BOLD,
  TEXT_XS_SEMIBOLD,
} from '@/styles/globalTypography';
import { Proposal } from '../types';
import { enUSDateFormat } from '@/utils/date';
import { mediaQueries } from '@/styles/mediaQueries';
import { MIN_XCB_FOR_VOTING } from '../constants';
import { addComma } from '@/utils/formatters';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { governanceSelectors } from '../selectors';

export interface CastingVoteBoxProps {}

const CastingVoteBox: FC<CastingVoteBoxProps> = () => {
  // const stratDate = new Date();
  // const endDate = new Date(
  //   new Date(
  //     new Date().getFullYear(),
  //     new Date().getMonth(),
  //     new Date().getDate() + 7
  //   )
  // );

  // const formatedStartDate = enUSDateFormat(stratDate);
  // const formatedEndDate = enUSDateFormat(endDate);

  const { id: stringProposalId } = useParams<{ id?: string }>();
  const proposalId = stringProposalId ? parseInt(stringProposalId, 10) : 0;

  const proposalData = useSelector(
    governanceSelectors.proposalById(proposalId)
  );

  const initialProposalData = {
    startDate: new Date(),
    endDate: new Date(),
  };

  const { startDate, endDate } = proposalData || initialProposalData;

  const formatedStartDate = enUSDateFormat(startDate);
  const formatedEndDate = enUSDateFormat(endDate);

  return (
    <Container>
      <Title>Cast your vote</Title>
      <Description>
        Various proposals were initiated, discussed and iterated to clarify the
        structure and guidelines for an appropriate operational framework.
      </Description>
      <DateContainer>
        <DateRow>
          <DateTitle>Start date</DateTitle>
          <DateValueContainer>
            <Icon src={ClockIcon} />
            <DateValue>{formatedStartDate}</DateValue>
          </DateValueContainer>
        </DateRow>
        <DateRow>
          <DateTitle>End date</DateTitle>
          <DateValueContainer>
            <Icon src={ClockIcon} />
            <DateValue>{formatedEndDate}</DateValue>
          </DateValueContainer>
        </DateRow>
      </DateContainer>
      <Button fullWidth>Yes, I support the proposal</Button>
      <Button fullWidth>No, I dont’t support</Button>
      <EndDescriptionContainer>
        <Icon src={WarningIcon} />
        <EndDescription>
          {addComma(MIN_XCB_FOR_VOTING)} XCB needed for voting
        </EndDescription>
      </EndDescriptionContainer>

      <JoinedBadge>
        <Icon src={CheckedIcon} />
        <JoinedBadgetext> You have joined governance</JoinedBadgetext>
      </JoinedBadge>
    </Container>
  );
};

const Container = styled(Box)`
  width: 352px;
  padding: 32px;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  ${BORDER_BLACK_WHITE_16}

  ${mediaQueries.lessThan('sm')`
    max-width: 352px;
  `}
`;

const Title = styled('h1')`
  ${H3_REGULAR}
  text-align: left;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const DateContainer = styled(Box)`
  width: 100%;
  padding: 16px 0;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
`;

const DateRow = styled(Box)`
  width: 100%;
  ${ROW_ALIGN_CENTER__SPACE_B}
`;

const DateTitle = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
`;

const DateValueContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 8px;
`;

const Icon = styled('img')``;

const DateValue = styled('p')`
  ${TEXT_XS_SEMIBOLD}
`;

const Button = styled(BaseButton)``;

const EndDescriptionContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 8px;
`;

const EndDescription = styled('p')`
  ${TEXT_SM_BOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--Neutrals-N100);
  padding: 16px 0;
`;

const JoinedBadge = styled(Box)`
  width: 100%;
  padding: 16px 0;
  border: 1px solid var(--Neutrals-N700);
  border-radius: 8px;
  ${ROW_CENTER}
  gap: 16px;
`;

const JoinedBadgetext = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  color: var(--Neutrals-N400);
`;
export default CastingVoteBox;
