import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';

export const GlobalDomains = {
  root: (state: RootState) => state,
  coreportConnection: (state: RootState) => state?.global.coreportConnection,
  activeNetwork: (state: RootState) =>
    state?.global.coreportConnection?.network,
  activeWalletAddress: (state: RootState) => state?.global.activeWalletAddress,
};

export const GlobalSelectors = {
  coreportConnection: createSelector(
    GlobalDomains.coreportConnection,
    (coreportConnection) => coreportConnection
  ),
  activeNetwork: createSelector(
    GlobalDomains.activeNetwork,
    (activeNetwork) => activeNetwork
  ),
};
