import { getItemFromLocalStorage } from '@/utils/localStorage';
import { TokensState } from './types';

export const numberOfDecimalsForUSDT = 2;

export const EXTERNAL_LINKS = [
  'Corescan',
  'More analytics',
  'Website',
  'Twitter',
];

export const TIME_FRAME = {
  '1H': '1H',
  '1D': '1D',
  '1W': '1W',
  '1M': '1M',
  '1Y': '1Y',
};

export const LOCALSTORAGE_KEYS = {
  TOKEN_SETTING: 'token-setting',
};

export const storagedTokensData: TokensState['tokenSetting'] =
  getItemFromLocalStorage(LOCALSTORAGE_KEYS.TOKEN_SETTING);
