import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { navbarSelectors } from '../../selectors';
import { navbarActions } from '../../slice';

import { Modal } from '@/components/modal';
import { TransactionModalContent } from './content';

export const TransactionModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(navbarSelectors.transactionModal);
  const handleModalClose = () => {
    dispatch(navbarActions.transactionModal({ isOpen: false }));
  };

  return (
    <CModal
      title={'Recent transaction'}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <TransactionModalContent />
    </CModal>
  );
};

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 928px;
  }

  .MuiDialog-paper {
    max-width: 928px;
  }
`;
