import { FC } from 'react';
import { styled, css, TypographyProps } from '@mui/material';
import AscendingIcon from 'assets/swapping/ascending.svg';
import DescendingIcon from 'assets/swapping/descending.svg';
import { ROW_CENTER } from '@/styles/globalStyles';
import { TEXT_LG_MEDIUM } from '@/styles/globalTypography';

export interface TrendStatusProps extends TypographyProps {
  trend: 'ascending' | 'descending' | 'neutral';
  hasIcon?: boolean;
}

const TrendStatus: FC<TrendStatusProps> = ({
  children,
  trend = 'neutral',
  hasIcon = false,
}) => {
  return (
    <Container>
      {hasIcon && trend === 'ascending' && <Icon src={AscendingIcon} />}
      {hasIcon && trend === 'descending' && <Icon src={DescendingIcon} />}
      <Text trend={trend}>{children}%</Text>
    </Container>
  );
};

const Container = styled('div')`
  ${ROW_CENTER}
  gap: 4px;
`;
const Icon = styled('img')``;
const Text = styled('p')<TrendStatusProps>`
  ${TEXT_LG_MEDIUM}
  ${({ trend }) =>
    (trend === 'ascending' &&
      css`
        color: var(--Brand-Neon-200);
      `) ||
    (trend === 'descending' &&
      css`
        color: var(--Base-Red);
      `) ||
    (trend === 'neutral' &&
      css`
        //some color for this option
      `)}
`;

export default TrendStatus;
