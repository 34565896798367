import { useDispatch, useSelector } from 'react-redux';

import { swapSelectors } from 'containers/swap/selectors';

import { ConfirmSwapModalContent } from './content';
import { Modal } from '@/components/modal';
import { swapActions } from '@/containers/swap/slice';
import { styled } from '@mui/material';
import { Spinner } from '@/components/loadingIndicator/Spinner';

export const ConfirmSwapModal = () => {
  const dispatch = useDispatch();

  const { isOpen } = useSelector(swapSelectors.confirmSwapModal);

  const isLoading = false;

  const handleClose = () => {
    dispatch(swapActions.setIsConfirmModalOpen(false));
  };

  const actionOfModal = (
    <>
      {isLoading ? (
        <>
          <Spinner width={40} height={40} />
          <Divider />
        </>
      ) : null}
    </>
  );

  return (
    <CModal
      isOpen={isOpen}
      onClose={handleClose}
      actions={actionOfModal}
      title="Confirm Swap"
    >
      <ConfirmSwapModalContent />
    </CModal>
  );
};

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 658px;
    max-height: 856px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 658px;
    max-height: 856px;
    padding: 72px;
  }
`;

const Divider = styled('div')`
  height: 32px;
  border-left: 1px solid var(--Neutrals-N700);
`;
