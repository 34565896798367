import { useDispatch, useSelector } from 'react-redux';

import { navbarSelectors } from '../../../selectors';
import { navbarActions } from '../../../slice';

import BrandIcon from 'assets/landing/Brand.svg';

import { Modal } from '@/components/modal';
import { WalletMenuModalContent } from './content';
import Icon from '@/components/icon';

export const WalletMenuModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(navbarSelectors.walletMenuModal);
  const handleModalClose = () => {
    dispatch(navbarActions.toggleWalletMenuModal(false));
  };

  const titleOfModal = <Icon src={BrandIcon} />;

  return (
    <Modal
      title={titleOfModal}
      isOpen={isOpen}
      onClose={handleModalClose}
      anchor="top"
    >
      <WalletMenuModalContent />
    </Modal>
  );
};
