import { SelectTokenModal } from '@/components/shared/selectTokenModal';
import { useDispatch, useSelector } from 'react-redux';
import { swapSelectors } from '../../selectors';
import { swapActions } from '../../slice';

export const SwapSelectTokenModal = () => {
  const dispatch = useDispatch();
  const { isOpen, type } = useSelector(swapSelectors.selectTokenToSwapModal);

  const exceptedToken = useSelector(
    swapSelectors.swapTokensSymbolInfo(type === 'From' ? 'To' : 'From')
  );

  const handleModalClose = () => {
    dispatch(
      swapActions.selectTokenToSwapModal({
        isOpen: false,
        type: type,
      })
    );
  };

  const handleSelectItem = (symbol: string) => {
    dispatch(swapActions.setSwapTokensSymbol({ type, symbol }));
    handleModalClose();
  };

  return (
    <SelectTokenModal
      isOpen={isOpen}
      exceptedToken={exceptedToken}
      handleModalClose={handleModalClose}
      handleSelectItem={handleSelectItem}
    />
  );
};
