import { Box, IconButton, styled } from '@mui/material';

import Icon from '@/components/icon';
import MobileSearchInput from '@/components/input/MobileSearchInput';

import TwitterIcon from 'assets/landing/twitter.svg';
import DiscordIcon from 'assets/landing/discord.svg';
import GithubIcon from 'assets/landing/github.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import { CustomLink } from '@/components/link';
import { useDispatch } from 'react-redux';
import { navbarActions } from '@/containers/navbar/slice';
import { TEXT_M_MEDIUM, TEXT_XL_MEDIUM } from '@/styles/globalTypography';

const PAGES = [
  { title: 'Swap', linkTo: '/swap' },
  { title: 'Tokens', linkTo: '/tokens' },
  { title: 'Pools', linkTo: 'pools' },
];

const LINKS = [
  {
    title: 'Vote in governance',
    linkTo: '/governance',
    hasIcon: true,
  },
  {
    title: 'Legal & Privacy ',
    linkTo: '',
    hasIcon: true,
  },
  {
    title: 'Contact',
    linkTo: '',
    hasIcon: true,
  },
  {
    title: 'Developers',
    linkTo: '',
    hasIcon: false,
  },
  {
    title: 'Documentation',
    linkTo: '',
    hasIcon: false,
  },
  {
    title: 'Community',
    linkTo: '',
    hasIcon: false,
  },
];

const ICONS = [
  {
    src: TwitterIcon,
    linkTo: '',
  },
  {
    src: GithubIcon,
    linkTo: '',
  },
  {
    src: DiscordIcon,
    linkTo: '',
  },
];
export const HamberMenuModalContent = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(navbarActions.toggleHamberMenuModal(false));
  };
  return (
    <Container>
      <StyledSearchInput placeholder="Search tokens, pools ..." />
      <SectionContainer onClick={handleClose}>
        {PAGES.map((item, index) => {
          const isLastItem = index === PAGES.length - 1;
          return (
            <LinkContainer key={item.title}>
              <PageTitle to={item.linkTo}>{item.title}</PageTitle>
              {!isLastItem && <Divider />}
            </LinkContainer>
          );
        })}
      </SectionContainer>
      <SectionContainer>
        {LINKS.map((item, index) => {
          const isLastItem = index === LINKS.length - 1;
          return (
            <LinkContainer key={item.title}>
              <LinkTitle
                color="gray"
                to={item.linkTo}
                iconHeight="16px"
                iconWidth="16px"
              >
                {item.title}
              </LinkTitle>
              {!isLastItem && <Divider />}
            </LinkContainer>
          );
        })}
      </SectionContainer>
      <IconContainer>
        {ICONS.map((item) => {
          return (
            <IconButton key={item.src}>
              <Icon src={item.src} />
            </IconButton>
          );
        })}
      </IconContainer>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const StyledSearchInput = styled(MobileSearchInput)`
  margin-top: 8px;
`;

const SectionContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  width: 100%;
`;

const IconContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  width: 100%;
`;

const Divider = styled(Box)`
  width: 100%;
  border-top: 1px solid var(--Neutrals-N700);
`;

const PageTitle = styled(CustomLink)`
  ${TEXT_XL_MEDIUM}
  p {
    color: red !important;
  }
`;

const LinkTitle = styled(CustomLink)`
  ${TEXT_M_MEDIUM}
  p {
    color: red !important;
  }
`;

const LinkContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  width: 100%;
`;
