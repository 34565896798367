import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GovernanceState, Proposal } from './types';

const initialState: GovernanceState = {
  isGettingProposals: false,
  proposals: {},
  error: '',
  createProposalModal: {
    isOpen: false,
  },
};

// governance slice
export const governanceSlice = createSlice({
  name: 'governance',
  initialState,
  reducers: {
    // governance reducers
    getProposals: (state) => {
      console.log('fetchProposals');
    },
    getProposalById: (state) => {
      console.log('fetchProposalById');
    },
    setIsGettingProposals(state, action: PayloadAction<boolean>) {
      state.isGettingProposals = action.payload;
    },
    setProposals(state, action: PayloadAction<GovernanceState['proposals']>) {
      state.proposals = action.payload;
    },
    toggleCreateProposalModal(
      state,
      action: PayloadAction<GovernanceState['createProposalModal']['isOpen']>
    ) {
      state.createProposalModal.isOpen = action.payload;
    },
  },
});

export const { actions: governanceActions, reducer: governanceReducer } =
  governanceSlice;
