import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';
import { TokenItemTypes } from '@/types';
import { TokensDomains } from '../tokens/selectors';
import { divide, multiply } from 'precise-math';
import { parseUnits } from 'm_ccbc';
import { CoreCoinData } from '../tokens/initialTokens';
import { WXCB } from '@/services/constants';
import { GlobalDomains } from '../global/selectors';
import { calculateDeadline } from '@/utils/deadline';
import { isNonZeroValue } from '@/utils/string';
import { calRemainValueOfPercentage } from '@/utils/numbers';
import { DEFAULT_SLIPPAGE_PERCENTAGE } from '@/config';

export const swapDomains = {
  root: (state: RootState) => state.swap,
  confirmSwapModal: (state: RootState) => state.swap.confirmSwapModal,
  selectTokenToSwapModal: (state: RootState) =>
    state.swap.selectTokenToSwapModal,
  settingMenu: (state: RootState) => state.swap.swapSettingMenu,
  selectedFromSymbolToken: (state: RootState) =>
    state.swap.selectedFromSymbolToken,
  selectedToSymbolToken: (state: RootState) => state.swap.selectedToSymbolToken,
  fromValue: (state: RootState) => state.swap.fromValue,
  toValue: (state: RootState) => state.swap.toValue,
  ratioOfThePriceOfTheTokensFromtoTo: (state: RootState) =>
    state.swap.ratioOfThePriceOfTheTokensFromtoTo,
  lastEntry: (state: RootState) => state.swap.lastEntry || 'none',
  isSwapping: (state: RootState) => state.swap.isSwapping,
};

export const swapSelectors = {
  root: createSelector(swapDomains.root, (root) => root),
  confirmSwapModal: createSelector(
    swapDomains.confirmSwapModal,
    (confirmSwapModal) => confirmSwapModal
  ),
  selectTokenToSwapModal: createSelector(
    swapDomains.selectTokenToSwapModal,
    (selectTokenToSwapModal) => selectTokenToSwapModal
  ),
  settingMenu: createSelector(
    swapDomains.settingMenu,
    (settingMenu) => settingMenu
  ),

  swapTokensSymbolInfo: (type: TokenItemTypes) =>
    createSelector(
      [swapDomains.selectedFromSymbolToken, swapDomains.selectedToSymbolToken],
      (selectedFromSymbolToken, selectedToSymbolToken) => {
        if (type === 'From') {
          return selectedFromSymbolToken;
        }
        return selectedToSymbolToken;
      }
    ),
  swapTokensInfo: (type: TokenItemTypes) =>
    createSelector(
      [
        swapDomains.selectedFromSymbolToken,
        swapDomains.selectedToSymbolToken,
        TokensDomains.tokens,
      ],
      (selectedFromSymbolToken, selectedToSymbolToken, tokens) => {
        if (type === 'From') {
          return tokens[selectedFromSymbolToken];
        }
        return tokens[selectedToSymbolToken];
      }
    ),
  swapTokensValue: (type: TokenItemTypes) =>
    createSelector(
      [swapDomains.fromValue, swapDomains.toValue],
      (fromValue, toValue) => {
        if (type === 'From') {
          return fromValue;
        }
        return toValue;
      }
    ),
  ratioOfThePriceOfTheTokensFromtoTo: (fromSymbol: string, toSymbol: string) =>
    createSelector(
      [swapDomains.ratioOfThePriceOfTheTokensFromtoTo, TokensDomains.tokens],
      (ratioOfThePriceOfTheTokensFromtoTo, tokens) => {
        const fromTknPrice = tokens[fromSymbol].usdPrice;
        const toTknPrice = tokens[toSymbol].usdPrice;

        return fromTknPrice && toTknPrice
          ? divide(fromTknPrice, toTknPrice)
          : ratioOfThePriceOfTheTokensFromtoTo;
      }
    ),
  swapMethod: createSelector(
    [
      TokensDomains.tokens,
      swapDomains.fromValue,
      swapDomains.toValue,
      swapDomains.selectedFromSymbolToken,
      swapDomains.selectedToSymbolToken,
      swapDomains.settingMenu,
      swapDomains.lastEntry,
      GlobalDomains.activeWalletAddress,
    ],
    (
      tokens,
      fromValue,
      toValue,
      selectedFromSymbolToken,
      selectedToSymbolToken,
      settingMenu,
      lastEntry,
      userWalletAddress
    ) => {
      const slippage = Number(
        settingMenu.slippageTolerance || DEFAULT_SLIPPAGE_PERCENTAGE
      );
      if (
        !fromValue ||
        !toValue ||
        !isNonZeroValue(fromValue) ||
        !isNonZeroValue(toValue) ||
        !selectedFromSymbolToken ||
        !selectedToSymbolToken ||
        !(slippage > 0) ||
        !userWalletAddress ||
        lastEntry === 'none'
      ) {
        return;
      }
      const fromToken = tokens[selectedFromSymbolToken];
      const toToken = tokens[selectedToSymbolToken];
      const deadline = calculateDeadline();

      const minimumToken2Value = calRemainValueOfPercentage(
        Number(toValue),
        slippage
      );
      const token1Amount = parseUnits(fromValue, fromToken.decimals);
      const minToken2Amount = parseUnits(
        minimumToken2Value.toString(),
        toToken.decimals
      );
      const res = () => {
        if (lastEntry === 'token1') {
          if (fromToken.address === CoreCoinData.address) {
            const functionName = 'swapExactETHForTokens';
            const params = [
              minToken2Amount,
              [WXCB, toToken.address],
              userWalletAddress,
              deadline,
              {
                value: 1,
              },
            ];
            return {
              functionName,
              params,
            };
          } else if (toToken.address === CoreCoinData.address) {
            const functionName = 'swapExactTokensForETH';
            const params: any = [
              token1Amount,
              minToken2Amount,
              [fromToken.address, WXCB],
              userWalletAddress,
              deadline,
            ];
            return {
              functionName,
              params,
            };
          } else {
            const functionName = 'swapExactTokensForTokens';
            const params = [
              token1Amount,
              minToken2Amount,
              [fromToken.address, toToken.address],
              userWalletAddress,
              deadline,
            ];
            return {
              functionName,
              params,
            };
          }
        } else if (lastEntry === 'token2') {
          if (fromToken.address === CoreCoinData.address) {
            const functionName = 'swapETHForExactTokens';
            const params = [
              minToken2Amount,
              [WXCB, toToken.address],
              userWalletAddress,
              deadline,
              {
                value: token1Amount,
              },
            ];
            return {
              functionName,
              params,
            };
          } else if (toToken.address === CoreCoinData.address) {
            const functionName = 'swapTokensForExactETH';
            const params = [
              token1Amount,
              minToken2Amount,
              [fromToken.address, WXCB],
              userWalletAddress,
              deadline,
            ];
            return {
              functionName,
              params,
            };
          } else {
            const functionName = 'swapTokensForExactTokens';
            const params = [
              token1Amount,
              minToken2Amount,
              [fromToken.address, toToken.address],
              userWalletAddress,
              deadline,
            ];
            return {
              functionName,
              params,
            };
          }
        }
      };
      return res();
    }
  ),
  isSwapping: createSelector(
    swapDomains.isSwapping,
    (isSwapping) => isSwapping
  ),
};
