import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { RouterContract__factory } from '@/contracts/router';
import { UniswapV2Pair__factory } from '@/contracts/uniswapPair';
import { UniswapV2Factory__factory } from '@/contracts/uniswapfactory';
import { FACTORY_ADDRESS } from '@/services/constants';
import { AbstractProvider, parseUnits } from 'm_ccbc';
import { divide } from 'precise-math';

export const swap = (props: {
  providerAddress: string;
  userAddress: string;
  routerAddress: string;
}) => {
  const { providerAddress, userAddress, routerAddress } = props;
  const provider: AbstractProvider = getRpcProvider(providerAddress);
  const contract = RouterContract__factory.connect(routerAddress, provider);
  const result = contract.swapTokensForExactTokens;
};
export const getAssetPriceAPI = async (props: {
  providerAddress: string;
  tokenIn: string;
  tokenInDecimals: number;
  tokenOutDecimals: number;
  tokenOut: string;
}) => {
  const {
    tokenIn,
    tokenOut,
    providerAddress,
    tokenInDecimals,
    tokenOutDecimals,
  } = props;
  try {
    const provider: AbstractProvider = getRpcProvider(providerAddress);
    const factory = UniswapV2Factory__factory.connect(
      FACTORY_ADDRESS,
      provider
    );
    const pairAddress = await factory.getPair(tokenIn, tokenOut);
    const pair = UniswapV2Pair__factory.connect(pairAddress, provider);
    const reserves = await pair.getReserves();
    const reserve0 = Number(reserves[0]);
    const reserve1 = Number(reserves[1]);
    const r0Decimals = tokenInDecimals;
    const r1Decimals = tokenOutDecimals;
    const r0withDecimals = Number(parseUnits('1', r0Decimals));
    const r1withDecimals = Number(parseUnits('1', r1Decimals));
    const price = divide(reserve0, reserve1);

    return price;
  } catch (error) {
    console.error({ error });
  }
};
