import xcbIcon from 'assets/tokens/xcb_icon.svg';
import ctnIcon from 'assets/tokens/ctn_icon.svg';
import { Token, TokensState } from './types';

export const CoreCoinData: Token = {
  name: 'Core Coin',
  symbol: 'XCB',
  decimals: 18,
  address: '0x0',
  logoURI: xcbIcon,
  balance: 0,
  lastPrices: [],
  notRemoveable: true,
  isNative: true,
};

export const initialTokens: TokensState['tokens'] = {
  XCB: CoreCoinData,
  CTN: {
    name: 'Core Token',
    symbol: 'CTN',
    decimals: 18,
    address: '0xab788e6c1e3bb2174aa05358a903dd93b2f3d361e2b6',
    logoURI: ctnIcon,
    balance: 0,
    lastPrices: [],
    notRemoveable: true,
    isNative: false,
  },
};
