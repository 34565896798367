import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { Token__factory } from '@/contracts/token';
import { AbstractProvider } from 'm_ccbc';

interface Props {
  spenderAddress: string;
  tokenAddress: string;
  userAccountAddress: string;
  rpcUrl: string;
  amount: string;
}
export const requestApproveToSpendToken = async ({
  amount,
  rpcUrl,
  userAccountAddress,
  spenderAddress,
  tokenAddress,
}: Props) => {
  console.log({
    amount,
    rpcUrl,
    userAccountAddress,
    spenderAddress,
    tokenAddress,
  });
  const provider: AbstractProvider = getRpcProvider(rpcUrl);
  const tokenContract = Token__factory.connect(tokenAddress, provider);
  const populated = await tokenContract.approve.populateTransaction(
    spenderAddress,
    amount
  );
  return { ...populated, from: userAccountAddress };
};
