import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { Token__factory } from '@/contracts/token';
import { AbstractProvider } from 'm_ccbc';

export const getAllowedAmountOfTokenToSpend = async ({
  accountAddress,
  rpcAddress,
  spenderAddress,
  tokenAddress,
}: {
  tokenAddress: string;
  rpcAddress: string;
  spenderAddress: string;
  accountAddress: string;
}) => {
  const provider: AbstractProvider = getRpcProvider(rpcAddress);
  const tokenContract = Token__factory.connect(tokenAddress, provider);
  const result = await tokenContract.allowance(accountAddress, spenderAddress);
  return result;
};
