import { FC, useState } from 'react';
import { styled, Divider, InputAdornment, TextField } from '@mui/material';

import SearchIcon from 'assets/liquidity/search.svg';

import { BaseInputProps } from './BaseInput';

import { TEXT_SM_REGULAR } from '@/styles/globalTypography';
import Icon from '../icon';
import { BORDER_BLACK_WHITE_8 } from '@/styles/globalStyles';
import { useScreenSize } from '@/hooks/useScreenSize';

export interface SearchInputProps extends BaseInputProps {
  search_focus_ref?: string;
  width?: string;
}

const MobileSearchInput: FC<SearchInputProps> = ({ placeholder, ...props }) => {
  const [searchFocusRef, setSearchFocusRef] = useState('false');

  return (
    //@ts-ignore
    <Container
      search_focus_ref={searchFocusRef}
      variant="outlined"
      onFocus={() => {
        setSearchFocusRef('true');
      }}
      onBlur={() => {
        setSearchFocusRef('false');
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              backgroundColor: 'inherit',
            }}
          >
            <Icon src={SearchIcon} />
            {<CDivider orientation="vertical" />}
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};

const Container = styled(TextField)<SearchInputProps>`
  width: 100%;
  background-color: ${(props) =>
    props.search_focus_ref === 'true'
      ? ' var(--Black)'
      : ' var(--Neutrals-N900)'};

  color: var(--White);
  ${BORDER_BLACK_WHITE_8}

  & .MuiInputBase-root {
    color: var(--White);
    padding: 0;
    width: ${({ width }) => width};
  }
  & ::placeholder {
    ${TEXT_SM_REGULAR}
    text-align: left;
    color: var(--Neutrals-N100) !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  & .MuiInputBase-input {
    padding: 10px 6px;
  }
`;

const CDivider = styled(Divider)`
  border-color: var(--Neutrals-N700);
  margin: 0.5px;
  height: 20px;
`;

export default MobileSearchInput;
