import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box, styled } from '@mui/material';

import { navbarActions } from '../../slice';

import Icon from '@/components/icon';
import { NewsType } from './content';

import CelebrateIcon from 'assets/landing/🎉.svg';

import {
  BORDER_BLACK_WHITE_8,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from 'styles/globalStyles';
import { TEXT_M_REGULAR } from 'styles/globalTypography';
import { DateFormaterUTC } from '@/utils/date';

interface NewsItemProps {
  news: NewsType;
}

export const NewsItem: FC<NewsItemProps> = ({ news }) => {
  const dispatch = useDispatch();

  const handleShowNewsDetails = () => {
    dispatch(navbarActions.newsModal({ isOpen: true, news: news }));
  };
  return (
    <ItemBox onClick={handleShowNewsDetails}>
      <LeftSideContainer>
        <Icon src={CelebrateIcon} />
        <Description>{news.title}</Description>
      </LeftSideContainer>
      <DateContainer>{DateFormaterUTC(news.date)}</DateContainer>
    </ItemBox>
  );
};

const ItemBox = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  background-color: var(--Neutrals-N900);
  padding: 16px;
  ${BORDER_BLACK_WHITE_8}
  cursor: pointer;
`;

const LeftSideContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  text-align: left;
`;

const DateContainer = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;
