// src/componetns/Footer.tsx

import { FC, useEffect } from 'react';
import { styled } from '@mui/material';

import BaseButton, { BaseButtonProps } from 'components/button/BaseButton';
import GradientContentButton from '@/components/button/GradientContentButton';
import Icon from '@/components/icon';
import { WalletConnectionRequierdButton } from '@/components/button/WalletConnectionRequierdButton';

import SwapIcon from 'assets/swapping/swap.svg';

import { TEXT_LG_SEMIBOLD } from '@/styles/globalTypography';
import { ROW_CENTER } from '@/styles/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { swapSelectors } from '../selectors';
import { swapActions } from '../slice';
import { toast } from 'react-toastify';

const SwapButton: FC<BaseButtonProps> = () => {
  const dispatch = useDispatch();
  const selectedFromToken = useSelector(swapSelectors.swapTokensInfo('From'));
  const selectedFromValue = useSelector(swapSelectors.swapTokensValue('From'));
  const isSwapping = useSelector(swapSelectors.isSwapping);
  const swapMethod = useSelector(swapSelectors.swapMethod);
  const isInsufficientValue =
    selectedFromToken.balance < parseInt(selectedFromValue) ||
    selectedFromToken.balance === 0;

  const handleSwapClick = () => {
    dispatch(swapActions.setIsConfirmModalOpen(true));
  };

  return (
    <WalletConnectionRequierdButton
      disconnectedChild={
        <NeutralsButton gradient_border="none" fullWidth>
          Connect wallet
        </NeutralsButton>
      }
    >
      {!isInsufficientValue ? (
        <ValidSwapButton
          onClick={handleSwapClick}
          fullWidth
          disabled={!swapMethod}
          isLoading={isSwapping}
          loadingText="Swapping..."
          endIcon={<Icon src={SwapIcon} />}
        >
          Swap
        </ValidSwapButton>
      ) : (
        <NeutralsButton gradient_border="none" fullWidth disabled>
          Insufficient {selectedFromToken.symbol} Funds
        </NeutralsButton>
      )}
    </WalletConnectionRequierdButton>
  );
};

const NeutralsButton = styled(BaseButton)`
  ${TEXT_LG_SEMIBOLD}
  height: 56px;
  border: 1px solid var(--Neutrals-N700);
  background-color: var(--Black);
  color: var(--Neutrals-N400);
  z-index: 3;

  &:disabled {
    border: 1px solid var(--Neutrals-N700);
    background-color: var(--Black);
    color: var(--Neutrals-N400);
  }
`;

const ValidSwapButton = styled(GradientContentButton)`
  ${TEXT_LG_SEMIBOLD}
  ${ROW_CENTER}
  height: 56px;
  z-index: 3;
`;

export default SwapButton;
