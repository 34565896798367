import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';

import { navbarActions } from '../../slice';

import Icon from '@/components/icon';

import CorePortIcon from 'assets/landing/coreport.svg';

import { useAccount } from '@/hooks/useAccount';
import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import { H3_REGULAR, TEXT_SM_REGULAR } from '@/styles/globalTypography';
import { customToast } from '@/components/toast/CustomToast';
import { ConnectionFailedToast } from '../ConnectionFailedToast';

export const ConnectToWalletContent = () => {
  const { connect } = useAccount();
  const dispatch = useDispatch();

  const handleConnectToWallet = () => {
    try {
      //@ts-ignore
      connect();
    } catch (error: any) {
      customToast.error(<ConnectionFailedToast />);
      throw new Error(error);
    } finally {
      dispatch(navbarActions.connectToWalletModal({ isOpen: false }));
    }
  };

  return (
    <Wrapper>
      <WalletContainer onClick={handleConnectToWallet}>
        <Icon src={CorePortIcon} />
        <WalletName>CorePort</WalletName>
      </WalletContainer>
      <DiscriptionContainer>
        <Discription>
          By connecting a wallet, you agree to CueSwap&apos;s
          <span> Terms of Use</span> and
          <span> Privacy Policy</span> and acknowledge that you have read them.
        </Discription>
        <Discription>
          Wallets are provided by third parties. By connecting your wallet, you
          agree to their terms and conditions. Wallet access may depend on these
          third parties being operational.
        </Discription>
      </DiscriptionContainer>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const WalletContainer = styled('div')`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
  width: 100%;
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  z-index: 3;
  &:hover {
    opacity: 0.8;
  }
`;

const WalletName = styled('h3')`
  ${H3_REGULAR}
`;

const DiscriptionContainer = styled('div')`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:16px;
`;

const Discription = styled('p')`
  ${TEXT_SM_REGULAR}
  text-align: left;
  color: var(--Neutrals-N100);

  span {
    color: var(--Neutrals-N50);
  }
`;
