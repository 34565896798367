import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectChangeEvent } from '@mui/material';
import BaseSelect from '@/components/select/BaseSelect';
import { navbarSelectors } from '../../../selectors';
import { navbarActions } from '../../../slice';
import { NavbarState } from '../../../types';
import { FIAT_CURRENCES } from '../../../constants';

interface Props {}

export const FiatCurrenctSelectButton = (props: Props) => {
  const dispatch = useDispatch();

  const [fiatCurrences] = useState(FIAT_CURRENCES);

  const selectedFiatCurrency = useSelector(
    navbarSelectors.selectedFiatCurrency
  );

  const handleChange = (event: SelectChangeEvent<any>) => {
    const valueSelected = event.target.value;

    const fiatSelected: NavbarState['selectedFiatCurrency'] =
      Object.values(FIAT_CURRENCES).find(
        (fiat) => fiat.label === valueSelected
      ) ?? FIAT_CURRENCES.USD;

    dispatch(navbarActions.setSelectedFiatCurrency(fiatSelected));
  };

  return (
    <BaseSelect
      onChange={handleChange}
      defaultValue={selectedFiatCurrency.label}
      items={Object.values(fiatCurrences).map((fiatCurrency) => {
        return {
          label: fiatCurrency.symbol + fiatCurrency.label,
          value: fiatCurrency.label,
        };
      })}
    />
  );
};
