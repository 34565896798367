import { useSelector } from 'react-redux';
import { styled, Box } from '@mui/material';

import { navbarSelectors } from '../../selectors';

import Icon from '@/components/icon';
import BaseButton from '@/components/button/BaseButton';

import WhiteDiognalArrowIcon from 'assets/landing/whiteDiognalArrow.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING,
  ROW_CENTER,
} from '@/styles/globalStyles';
import {
  H2_REGULAR,
  TEXT_LG_REGULAR,
  TEXT_LG_SEMIBOLD,
  TEXT_M_BOLD,
} from '@/styles/globalTypography';

export const NewsModalContent = () => {
  const { news } = useSelector(navbarSelectors.newsModal);
  return (
    <Container>
      <Wrapper>
        <Title>{news?.title}</Title>
        <DescriptionContainer>
          <SubTitle>{news?.subTitle}</SubTitle>
          <Description>{news?.description}</Description>
        </DescriptionContainer>
      </Wrapper>
      <ButtonContainer>
        <Button
          fullWidth
          gradient_border="white"
          endIcon={<Icon src={WhiteDiognalArrowIcon} />}
        >
          Mark as Read
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled(Box)`
  position: relative;
`;

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
  overflow: auto;
  max-height: 640px;
  height: auto;
  padding: 0px 0 80px 0;
  ${HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING}
`;

const Title = styled('p')`
  ${H2_REGULAR}
`;

const DescriptionContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
`;

const SubTitle = styled('p')`
  ${TEXT_M_BOLD}
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const Description = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const ButtonContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 24px;
  width: 100%;
  height: 80px;
  background-color: var(--Black);
  position: absolute;
  bottom: -24px;
`;

const Button = styled(BaseButton)`
  ${TEXT_LG_SEMIBOLD}
`;
