import { FC } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalSelectors } from '@/containers/global/selectors';

import Icon from '@/components/icon';

import { CoreNetwork } from '@/hooks/network';

import CheckedIcon from 'assets/landing/greenChecked.svg';

import {
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import { TEXT_LG_MEDIUM } from '@/styles/globalTypography';
import { globalActions } from '@/containers/global/slice';

interface NetworkItemProps {
  network: CoreNetwork;
}

export const NetworkItem: FC<NetworkItemProps> = ({ network }) => {
  const dispatch = useDispatch();
  const activeNetwork = useSelector(GlobalSelectors.activeNetwork);

  const isActiveNetwork = network.networkId === activeNetwork?.networkId;

  const handleSelectNetwork = () => {
    dispatch(globalActions.setActiveNetwork(network));
  };

  return (
    <NetworkItemContainer>
      <NetworkContainer>
        <NetworkIcon src={network.iconUrl ?? ''} />
        <Title>{network.name}</Title>
      </NetworkContainer>
      <>
        {isActiveNetwork ? (
          <Icon src={CheckedIcon} />
        ) : (
          <IconButton onClick={handleSelectNetwork}>
            <UnselectedNetwork />
          </IconButton>
        )}
      </>
    </NetworkItemContainer>
  );
};

const NetworkItemContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap: 24px;
  width: 100%;
  padding: 24px;
`;

const NetworkContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 8px;
`;

const NetworkIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`;

const Title = styled('p')`
  ${TEXT_LG_MEDIUM}
`;

const UnselectedNetwork = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1.5px solid var(--Neutrals-N400);
`;
