import { FC, useEffect } from 'react';
import { Box, styled } from '@mui/material';

import StatusBar from '@/components/charts/StatusBar';

import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_ALIGN_END__JUSTIFY_CENTER,
  ROW_CENTER,
} from '@/styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_M_REGULAR,
  TEXT_SM_BOLD,
  TEXT_XL_REGULAR,
} from '@/styles/globalTypography';
import { Proposal } from '../types';
import { remainingTime } from '@/utils/date';
import { mediaQueries } from '@/styles/mediaQueries';
import { useDispatch, useSelector } from 'react-redux';
import { governanceActions } from '../slice';
import { useParams } from 'react-router';
import { governanceSelectors } from '../selectors';
import { PropPosalTimeBox } from './ProposalTimeBox';

export interface ProposalStatusBoxProps {}
export interface StatusValueProps {
  status: boolean;
}

const ProposalStatusBox: FC<ProposalStatusBoxProps> = () => {
  const { id: stringProposalId } = useParams<{ id?: string }>();
  const proposalId = stringProposalId ? parseInt(stringProposalId, 10) : 0; // or Number(proposalId);

  const proposalData = useSelector(
    governanceSelectors.proposalById(proposalId)
  );

  const favoredVotes = useSelector(
    governanceSelectors.favoredVotesById(proposalId)
  );

  const initialProposalData = {
    quorum: 0,
  };
  const { quorum } = proposalData || initialProposalData;

  return (
    <Container>
      <PropPosalTimeBox />
      <QuorumContainer>
        <HeaderContainer>
          <Title>Quorum</Title>
          <QuorumValueContainer>
            <ValueText>{favoredVotes}%</ValueText>
            <NeededText>/ {quorum}% Needed</NeededText>
          </QuorumValueContainer>
        </HeaderContainer>
        <StyledStatusBar
          status="neutral"
          value={favoredVotes}
          dividerValue={quorum}
        />
      </QuorumContainer>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  padding: 24px;
  border: 1px solid var(--Neutrals-N700);
  border-radius: 8px;
  ${ROW_ALIGN_CENTER__SPACE_B}

  ${mediaQueries.lessThan('sm')`
    flex-direction: column;
    gap: 32px;
  `}
`;

const HeaderContainer = styled(Box)``;

const Title = styled('p')`
  ${TEXT_SM_BOLD}
  color: var(--Neutrals-N100);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: left;
`;

const ValueText = styled('p')`
  ${H3_REGULAR}
  text-align: left;
`;

const StatusContainer = styled(Box)`
  margin-top: 12px;
  ${ROW_CENTER}
  gap: 4px;
`;

const QuorumContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap: 4px;
  width: 100%;
`;

const QuorumValueContainer = styled(Box)`
  ${ROW_ALIGN_END__JUSTIFY_CENTER}
  gap: 8px;
`;

const NeededText = styled('p')`
  ${TEXT_XL_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const StyledStatusBar = styled(StatusBar)`
  margin-top: 12px;
  position: relative;
`;

export default ProposalStatusBox;
