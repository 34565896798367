import { FC } from 'react';
import { InputBase, InputProps, styled } from '@mui/material';

export interface BaseInputProps extends InputProps {}

const BaseInput: FC<BaseInputProps> = ({ ...props }) => {
  return <BInput {...props}></BInput>;
};

const BInput = styled(InputBase)<BaseInputProps>`
  width: 100%;
  color: white;
  text-align: right;
  direction: rtl;
  background-color: transparent;
`;

export default BaseInput;
