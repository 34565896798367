import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, css, styled } from '@mui/material';

import { swapSelectors } from '../selectors';

import SwapItem from './SwapItem';
import BaseButton from 'components/button/BaseButton';
import SwapDetailsBox from './SwapDetailsBox';
import Icon from '@/components/icon';
import SwapButton from './SwapButton';
import { SwapHeader } from './SwapHeader';
import { SwapSettingMenu } from './SwapSettingMenu';

import TransferIcon from 'assets/swapping/transfer.svg';

import { TEXT_M_REGULAR } from 'styles/globalTypography';
import {
  BORDER_BLACK_WHITE_16,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_CENTER,
} from 'styles/globalStyles';
import { mediaQueries } from '@/styles/mediaQueries';
import { ConfirmSwapModal } from './modals/confirmSwapModal';
import { swapActions } from '../slice';
import { StringBoolean } from '@/types';
import { SwapSelectTokenModal } from './modals/SwapSelectTokenModal';
import { useAccount } from '@/hooks/useAccount';
import { EnergyFeeForSwap } from './energyFee';
import { TokensSelectors } from '@/containers/tokens/selectors';

export interface SwappingBoxProps {
  isMobileView?: StringBoolean;
}

const SwappingBox: FC<SwappingBoxProps> = ({ isMobileView }) => {
  const dispatch = useDispatch();

  const { account } = useAccount();

  const { id: symbols } = useParams();

  //FIXME: after new design ready, remove this code
  const tokens =
    symbols &&
    useSelector(
      TokensSelectors.allAvailableTokensExpect(
        symbols?.split('-')[0]?.toUpperCase()
      )
    );

  //If in the route any tokens sended to this component, set new tokens
  useEffect(() => {
    if (symbols) {
      const token1 = symbols?.split('-')[0]?.toUpperCase();
      const token2 = symbols?.split('-')[1]?.toUpperCase();

      dispatch(
        swapActions.setSwapTokensSymbol({
          type: 'From',
          symbol: token1,
        })
      );

      dispatch(
        swapActions.setSwapTokensSymbol({
          type: 'To',
          //@ts-ignore
          symbol: token2 ? token2 : tokens[0].symbol,
        })
      );
    }
  }, []);

  const selectedFromToken = useSelector(swapSelectors.swapTokensInfo('From'));
  const selectedToToken = useSelector(swapSelectors.swapTokensInfo('To'));

  const selectedFromValue = useSelector(swapSelectors.swapTokensValue('From'));
  const selectedToValue = useSelector(swapSelectors.swapTokensValue('To'));

  const priceRatioOfTokens = useSelector(
    swapSelectors.ratioOfThePriceOfTheTokensFromtoTo(
      selectedFromToken.symbol,
      selectedToToken.symbol
    )
  );

  const showSwapStat = account && selectedFromValue && selectedToValue;

  const handleMovement = () => {
    dispatch(
      swapActions.setSwapTokensSymbol({
        type: 'From',
        symbol: selectedToToken.symbol,
      })
    );
    dispatch(
      swapActions.setSwapTokensSymbol({
        type: 'To',
        symbol: selectedFromToken.symbol,
      })
    );
    dispatch(
      swapActions.setSwapTokensValue({
        type: 'From',
        value: selectedToValue,
        otherType: 'To',
        otherTypeValue: selectedFromValue,
      })
    );
  };
  return (
    <>
      <Container is_mobile_view={isMobileView}>
        <SwapHeader isMobileView={isMobileView} />
        <SwapContainer is_mobile_view={isMobileView}>
          <SwapItem type="From" isMobileView={isMobileView}></SwapItem>
          <TransferContainer>
            <TransferButton onClick={handleMovement}>
              <Icon src={TransferIcon} />
            </TransferButton>
            <EquivalentContainer>
              {priceRatioOfTokens ? (
                <EquivalentText>
                  1 {selectedFromToken.symbol} ≈ {priceRatioOfTokens}{' '}
                  {selectedToToken.symbol}
                </EquivalentText>
              ) : null}
              <EnergyFeeForSwap />
            </EquivalentContainer>
          </TransferContainer>
          <SwapItem type="To" isMobileView={isMobileView}></SwapItem>
        </SwapContainer>
        <SwapButton />
        {showSwapStat && <SwapDetailsBox isMobileView={isMobileView} />}
      </Container>
      <ConfirmSwapModal />
      <SwapSelectTokenModal />
      <SwapSettingMenu />
    </>
  );
};

const Container = styled(Box)<{ is_mobile_view?: StringBoolean }>`
  width: 100%;
  max-width: 608px;
  padding: 32px;
  ${COLUMN_CENTER}
  gap: 32px;
  border-radius: 16px;
  position: relative;
  box-shadow: -10px 0px 35px 0px rgba(209, 254, 146, 0.5),
    10px 0px 35px 0px rgba(9, 150, 105, 0.5);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.7px;
    opacity: 0.7;
    background: linear-gradient(
      90deg,
      rgba(209, 254, 146, 1),
      rgba(9, 150, 105, 1)
    );
    -webkit-mask: linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  ${mediaQueries.lessThan('sm')`
      box-shadow: -1px 0px 5px 0px rgba(209, 254, 146, 0.5),
    10px 0px 35px 0px rgba(9, 150, 105, 0.5);
  `}

  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      width: 35%;
      padding: 24px;
      gap: 24px;
      box-shadow: none;
      ${BORDER_BLACK_WHITE_16}
    `}

  ${mediaQueries.lessThan('sm')`
      padding: 24px;
      gap: 24px;
    `}
`;
const SwapContainer = styled(Box)<{ is_mobile_view?: StringBoolean }>`
  ${COLUMN_CENTER}
  gap: 24px;
  width: 100%;
  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      gap: 16px;
    `}
  ${mediaQueries.lessThan('sm')`
    gap:16px;
  `}
`;

const TransferButton = styled(BaseButton)`
  padding: 12px;
  z-index: 3;
`;

const TransferContainer = styled('div')`
  ${ROW_CENTER}
  gap: 16px;
`;

const EquivalentContainer = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
`;

const EquivalentText = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
`;

export default SwappingBox;
