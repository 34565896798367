import { AddressLike, ContractTransaction, parseUnits } from 'm_ccbc';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { sendTx } from '../global/utils/sendTransaction';
import { CoreCoinData } from '../tokens/initialTokens';
import { tokensActions } from '../tokens/slice';
import { startAddLiquidityProcess, startGetListOfPools } from './providers';
import { liquidityActions } from './slice';
import { Pool } from './types';

function* addLiquidity({
  payload: { token1, token1Amount, token2Amount, token2 },
}: ReturnType<typeof liquidityActions.addLiquidity>) {
  const tmp = {
    token1,
    token2,
    token1Amount,
    token2Amount,
  };
  const dataToSend = { ...tmp };
  if (token1.address === CoreCoinData.address) {
    (dataToSend.token1 = tmp.token2),
      (dataToSend.token1Amount = tmp.token2Amount);
    dataToSend.token2 = tmp.token1;
    dataToSend.token2Amount = tmp.token1Amount;
  }
  yield put(liquidityActions.setIsAddingLiquidity(true));
  try {
    const unsignedTransaction: ContractTransaction = yield call(
      startAddLiquidityProcess,
      dataToSend
    );
    const res: string | { result: string } = yield call(
      sendTx,
      unsignedTransaction
    );
    if (typeof res === 'string' && res.toLowerCase().includes('rejected')) {
      toast.error('Transaction Rejected');
    } else {
      if (typeof res !== 'string' && res?.result.includes('0x')) {
        toast.success('Transaction Submitted');
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(liquidityActions.setIsAddingLiquidity(false));
    yield put(tokensActions.getAllBalances({ silent: true }));
  }
}

function* getPools({}: ReturnType<typeof liquidityActions.getPools>) {
  //FIXME: please add isLoading state for this api call
  try {
    const result: Pool[] = yield call(startGetListOfPools);
    yield put(liquidityActions.setPoolsList(result));
  } catch (error) {
    console.error(error);
  } finally {
    // yield put(liquidityActions.setIsAddingLiquidity(false));
  }
}

export function* liquiditySaga() {
  yield takeLatest(liquidityActions.addLiquidity.type, addLiquidity);
  yield takeLatest(liquidityActions.getPools.type, getPools);
}
