import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';
import { AddressLike } from 'm_ccbc';
import { add, divide, multiply, subtract } from 'precise-math';

export const TokensDomains = {
  root: (state: RootState) => state?.tokens,
  isGettingTokens: (state: RootState) => state?.tokens?.isGettingTokens,
  error: (state: RootState) => state?.tokens?.error,
  searchValue: (state: RootState) => state?.tokens?.searchValue,
  tokens: (state: RootState) => state?.tokens?.tokens,
  isLoadingPrices: (state: RootState) => state.tokens?.isLoadingInitialPrices,
  isGettingAllBalances: (state: RootState) =>
    state.tokens?.isGettingAllBalances,
  tokenSetting: (state: RootState) => state.tokens.tokenSetting,
};

export const TokensSelectors = {
  error: createSelector(TokensDomains.error, (error) => error),
  searchValue: createSelector(
    TokensDomains.searchValue,
    (searchValue) => searchValue
  ),
  root: createSelector(TokensDomains.root, (root) => root),
  tokens: createSelector([TokensDomains.tokens], (tokens) => tokens),
  tokenInfoBySymbol: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      if (tokens) {
        return tokens[tokenSymbol];
      }
      return undefined;
    }),
  tokenInfoByAddress: (tokenAddress: string | AddressLike) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      if (tokens) {
        return Object.values(tokens).find(
          (token) => token.address === tokenAddress
        );
      }
      return undefined;
    }),
  tokenTrendBySymbol: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      if (tokens) {
        const lastPrices = tokens[tokenSymbol]?.lastPrices || [];
        if (lastPrices.length) {
          const lastPrice = lastPrices[lastPrices.length - 1];
          const firstPrice = lastPrices[0];
          let trend = lastPrice > firstPrice ? 'ascending' : 'descending';
          if (lastPrice === firstPrice) {
            trend = 'neutral';
          }
          const percentageChanged = divide(
            subtract(lastPrice, firstPrice),
            firstPrice
          );
          return { trend, percentageChanged };
        }
      }
      return undefined;
    }),
  listOfTokens: createSelector([TokensDomains.tokens], (tokens) => {
    if (tokens) {
      return Object.keys(tokens).map((tokenSymbol) => tokens[tokenSymbol]);
    }
    return [];
  }),
  tokenPrice: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      if (!tokens || !tokens[tokenSymbol]) {
        console.error(`token doesn\'t exist in tokens list `);
      }
      return tokens[tokenSymbol]?.usdPrice || 0;
    }),
  tokenBalanceInUsdt: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      const token = tokens[tokenSymbol];
      if (token) {
        const multiplied = multiply(token.balance, token.usdPrice || 0);
        return multiplied;
      }
      return 0;
    }),
  isLoadingTokenBalance: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      const token = tokens[tokenSymbol];
      if (token) {
        return token.isGettingBalance || false;
      }
      return false;
    }),
  isGettingTokenPrice: (tokenSymbol: string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      const token = tokens[tokenSymbol];
      if (token) {
        return token.isGettingPrice || false;
      }
      return false;
    }),
  totalBalanceInUsdt: createSelector([TokensDomains.tokens], (tokens) => {
    let totalBalance = 0;

    if (tokens) {
      Object.keys(tokens).forEach((tokenSymbol) => {
        if (tokens[tokenSymbol].usdPrice) {
          const tkn = tokens[tokenSymbol];
          const multiplied = multiply(tkn.balance, tkn.usdPrice || 0);
          totalBalance = add(totalBalance, multiplied);
        }
      });
    }
    return totalBalance;
  }),
  isLoadingAssetsPrices: createSelector(
    TokensDomains.isLoadingPrices,
    (isLoading) => isLoading
  ),
  isLoadingAllBalances: createSelector(
    TokensDomains.isGettingAllBalances,
    (loading) => loading
  ),
  isLoadingTotalBalance: createSelector(
    [TokensDomains.isGettingAllBalances, TokensDomains.isLoadingPrices],
    (loadingBalances, loadingPrices) => loadingBalances || loadingPrices
  ),
  equavalantPrice: (props: { symbol: string; value: number }) =>
    createSelector(TokensDomains.tokens, (tokens) => {
      let equivalentPrice;
      if (tokens[props.symbol].usdPrice) {
        equivalentPrice = multiply(
          tokens[props.symbol].usdPrice as number,
          props.value
        );
      }
      return equivalentPrice;
    }),
  searchedTokens: createSelector(
    [TokensDomains.tokens, TokensDomains.searchValue],
    (tokens, searchValue) => {
      if (tokens) {
        return Object.keys(tokens)
          .map((tokenSymbol) => tokens[tokenSymbol])
          .filter((token) => {
            if (searchValue) {
              return (
                token.symbol
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) ||
                token.name.toLowerCase().includes(searchValue.toLowerCase())
              );
            }
            return true;
          });
      }
      return [];
    }
  ),
  allAvailableTokensExpect: (tokensSymbol: string[] | string) =>
    createSelector([TokensDomains.tokens], (tokens) => {
      if (tokens) {
        return Object.keys(tokens)
          .map((tokenSymbol) => tokens[tokenSymbol])
          .filter((token) => {
            if (tokensSymbol.includes(token.symbol)) {
              return false;
            }
            return true;
          });
      }
      return [];
    }),
  tokenSetting: createSelector(
    TokensDomains.tokenSetting,
    (tokenSetting) => tokenSetting
  ),
  isGettingTokens: createSelector(
    TokensDomains.isGettingTokens,
    (isGettingTokens) => isGettingTokens
  ),
};
