import { RouterContract__factory } from '@/contracts/router';
import { ROUTER_CONTRACT_ADDRESS } from '@/services/constants';
import { ContractTransaction, TransactionLike } from 'm_ccbc';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { NETWORK } from '../global/initialNetwork';
import { getRpcProvider } from '../global/utils/getRpcProvider';
import { sendTx } from '../global/utils/sendTransaction';
import { tokensActions } from '../tokens/slice';
import { swapSelectors } from './selectors';
import { swapActions } from './slice';

// swap page saga

function* startSwapProcess(
  action: ReturnType<typeof swapActions.startSwapProcess>
) {
  const methodObj: { functionName: string; params: any[] } = yield select(
    swapSelectors.swapMethod
  );
  const contract = RouterContract__factory.connect(
    ROUTER_CONTRACT_ADDRESS,
    getRpcProvider(NETWORK.rpcUrl)
  );
  const { params, functionName } = methodObj;
  try {
    yield put(swapActions.setIsSwapping(true));
    // @ts-ignore
    const unsignedTx: ContractTransaction = yield contract[
      functionName
    ].populateTransaction(...params);
    const res: string | { result: string } = yield call(sendTx, unsignedTx);
    if (typeof res === 'string' && res.toLowerCase().includes('rejected')) {
      toast.error('Transaction Rejected');
    } else {
      if (typeof res !== 'string' && res?.result.includes('0x')) {
        toast.success('Transaction Submitted');
      }
      yield put(tokensActions.getAllBalances({ silent: true }));
    }
  } catch (error) {
  } finally {
    yield put(swapActions.setIsSwapping(false));
    yield put(swapActions.setIsConfirmModalOpen(false));
  }
}

export function* swapSaga() {
  yield takeLatest(swapActions.startSwapProcess.type, startSwapProcess);
}
