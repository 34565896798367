import {
  COLUMN_ALIGN_START__JUSTIFY_START,
  ROW_CENTER,
} from '@/styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_M_REGULAR,
  TEXT_SM_BOLD,
} from '@/styles/globalTypography';
import { remainingTime } from '@/utils/date';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { governanceSelectors } from '../selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProposalDataType } from '../types';
import CancelledIcon from 'assets/governance/cancelled.svg';
import ExecutedIcon from 'assets/governance/executed.svg';
import Icon from '@/components/icon';

export interface StatusValueProps {
  status: boolean;
}

export const PropPosalTimeBox = () => {
  const { id: stringProposalId } = useParams<{ id?: string }>();
  const proposalId = stringProposalId ? parseInt(stringProposalId, 10) : 0; // or Number(proposalId);

  const proposalData = useSelector(
    governanceSelectors.proposalById(proposalId)
  );

  const favoredVotes = useSelector(
    governanceSelectors.favoredVotesById(proposalId)
  );

  const initialProposalData = {
    quorum: 0,
    proposalStatus: 'open' as ProposalDataType['proposalStatus'],
    startDate: new Date(),
    endDate: new Date(),
  };
  const { startDate, endDate, quorum, proposalStatus } =
    proposalData || initialProposalData;

  const { diffDays, diffHours, diffMinutes } = remainingTime(
    startDate,
    endDate
  );
  const outCome = favoredVotes > quorum ? 'For' : 'Against';

  return (
    <TimeContainer>
      <HeaderContainer>
        {proposalStatus === 'open' ? (
          <>
            <Title>Time remaining</Title>
            <ValueText>
              {diffDays}d {diffHours}h {diffMinutes}m
            </ValueText>
          </>
        ) : (
          <>
            <Title>Date</Title>
            <ValueText>Mar 4, 2023</ValueText>
          </>
        )}
      </HeaderContainer>
      <StatusContainer>
        {proposalStatus === 'open' ? (
          <>
            <StatusTitle>Current outcome:</StatusTitle>
            <StatusValue status={outCome === 'For'}>{outCome}</StatusValue>
          </>
        ) : proposalStatus === 'cancelled' ? (
          <>
            <Icon src={CancelledIcon} />
            <ProposalStatus proposal_status={proposalStatus}>
              cancelled
            </ProposalStatus>
          </>
        ) : (
          <>
            <Icon src={ExecutedIcon} />
            <ProposalStatus proposal_status={proposalStatus}>
              Executed
            </ProposalStatus>
          </>
        )}
      </StatusContainer>
    </TimeContainer>
  );
};

const TimeContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap: 4px;

  width: 100%;
`;

const HeaderContainer = styled(Box)``;

const Title = styled('p')`
  ${TEXT_SM_BOLD}
  color: var(--Neutrals-N100);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: left;
`;

const ValueText = styled('p')`
  ${H3_REGULAR}
  text-align: left;
`;

const StatusContainer = styled(Box)`
  margin-top: 12px;
  ${ROW_CENTER}
  gap: 4px;
`;

const StatusTitle = styled('p')`
  ${TEXT_M_REGULAR}
  text-align: left;
  color: var(--Neutrals-N100);
`;

const StatusValue = styled('p')<StatusValueProps>`
  ${TEXT_M_REGULAR}
  color:${(props) =>
    props.status ? 'var(--Brand-Neon-200)' : 'var(--Base-Red)'};
  /* color: var(--Brand-Neon-200); */
  text-align: left;
`;

const ProposalStatus = styled('p')<{
  proposal_status: ProposalDataType['proposalStatus'];
}>`
  ${TEXT_SM_BOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: ${({ proposal_status }) =>
    proposal_status === 'open'
      ? ' var(--White)'
      : proposal_status === 'Executed'
      ? ' var(--Brand-Neon-200)'
      : 'var( --Base-Red)'};
`;
