import { GlobalDomains } from '@/containers/global/selectors';
import { GlobalState } from '@/containers/global/types';
import { CoreNetwork } from '@/hooks/network';
import { all, call, select } from 'redux-saga/effects';
import { TokensDomains } from '../selectors';
import { Token, TokensState } from '../types';
import { getAccountBalanceAPI, getTokenBalanceAPI } from './apis';
import { NETWORK } from '@/containers/global/initialNetwork';
import { AbstractProvider, AddressLike } from 'm_ccbc';
import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { UniswapV2Factory__factory } from '@/contracts/uniswapfactory';
import { UniswapV2Pair__factory } from '@/contracts/uniswapPair';
import { A } from 'vitest/dist/global-fe52f84b';
import { UNISWAP_V2_CONTRACT_ADDRESS } from '@/services/constants';

export function* getAccountBalance() {
  const activeAccount: GlobalState['activeWalletAddress'] = yield select(
    GlobalDomains.activeWalletAddress
  );
  if (activeAccount) {
    const activeNetwork: CoreNetwork = yield select(
      GlobalDomains.activeNetwork
    );
    const rpcUrl = activeNetwork.rpcUrl;
    const balance: bigint = yield call(getAccountBalanceAPI, {
      address: activeAccount,
      providerAddress: rpcUrl,
    });
    return balance;
  }
  console.error('no active account');
}

export function* getTokenBalance(props: { symbol: string }) {
  const { symbol } = props;
  const selectedNetwork: CoreNetwork = yield select(
    GlobalDomains.activeNetwork
  );
  try {
    const tokens: TokensState['tokens'] = yield select(TokensDomains.tokens);
    const tokensForThisNetwork = tokens;
    const activeAccount: GlobalState['activeWalletAddress'] = yield select(
      GlobalDomains.activeWalletAddress
    );
    const token = tokensForThisNetwork[symbol];
    if (token && activeAccount) {
      const balance: bigint = yield call(getTokenBalanceAPI, {
        providerAddress: selectedNetwork.rpcUrl,
        tokenAddress: token.address,
        userAddress: activeAccount,
      });
      return balance;
    }
  } catch (error) {
  } finally {
  }
}

//@ts-ignore
export function* getTokenslist() {
  const rpcUrl = NETWORK.rpcUrl;
  const provider: AbstractProvider = yield call(getRpcProvider, rpcUrl);

  const contract = UniswapV2Factory__factory.connect(
    UNISWAP_V2_CONTRACT_ADDRESS,
    provider
  );

  const length: bigint = yield contract.allPairsLength();

  const tokensAddresses = new Set<AddressLike>();

  for (let i = 0; i < length; i++) {
    const pairAddress: AddressLike = yield contract.allPairs(i);

    const pairContract = UniswapV2Pair__factory.connect(
      pairAddress.toString(),
      provider
    );

    const [token0Address, token1Address] = yield all([
      pairContract.token0(),
      pairContract.token1(),
    ]);

    tokensAddresses.add(token0Address);
    tokensAddresses.add(token1Address);
  }
  const toCall: any = [];

  tokensAddresses.forEach((tokenAddress: AddressLike) => {
    const tokenContract = UniswapV2Pair__factory.connect(
      tokenAddress.toString(),
      provider
    );

    toCall.push(
      all([tokenContract.name(), tokenContract.symbol(), tokenAddress])
    );
  });
  const results: [string, string, string][] = yield all(toCall);

  const tokens: Record<string, Token> = {};
  //FIXME: after getting whole data of the tokens must move out and refactor
  results.forEach(([name, symbol, address]) => {
    tokens[symbol] = {
      name,
      symbol,
      address,
      decimals: 18,
      logoURI: '',
      balance: 0,
      lastPrices: [],
      notRemoveable: false,
      isNative: false,
    };
  });

  return tokens;
}
