import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuProps, styled, Menu } from '@mui/material';

import { navbarSelectors } from '../../../selectors';
import { navbarActions } from '../../../slice';

import { BORDER_BLACK_WHITE_16, COLUMN_CENTER } from '@/styles/globalStyles';

import { SelectNetworkMenu } from '../shared/SelectNetworkMenu';
import { WalletMenuContent } from '../shared/WalletMenuContent';

export const WalletMenu: FC<MenuProps> = ({ open }) => {
  const dispatch = useDispatch();

  const { anchorEl } = useSelector(navbarSelectors.walletMenu);
  const { isOpen: selectNetworkMeniIsOpen } = useSelector(
    navbarSelectors.selectNetworkMenu
  );

  const handleClose = () => {
    dispatch(navbarActions.toggleWalletMenu(false));
    dispatch(navbarActions.setAnchorElWalletMenu(null));
  };

  return (
    <>
      <Container
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <WalletMenuContent />
      </Container>
      <SelectNetworkMenu open={selectNetworkMeniIsOpen} />
    </>
  );
};

const Container = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 24px;
  }

  .MuiList-root {
    ${BORDER_BLACK_WHITE_16}
    ${COLUMN_CENTER}
    gap: 24px;
    width: 350px;
    padding: 24px;
    background-color: var(--Black);
  }
`;
