import { NETWORK } from '@/containers/global/initialNetwork';
import { GlobalSelectors } from '@/containers/global/selectors';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { TEXT_LG_SEMIBOLD } from '@/styles/globalTypography';
import { getAllowedAmountOfTokenToSpend } from '@/utils/checkAllowance';
import { requestApproveToSpendToken } from '@/utils/requestApprovalToSpendToken';
import { Box, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import WhiteButton from '../WhiteButton';
import { StringBoolean } from '@/types';
import { parseUnits } from 'm_ccbc';
import { ROW_CENTER } from '@/styles/globalStyles';

interface Props {
  tokenSymbol: string;
  amount: number;
  spenderAddress: string;
  onApprove: (approved: boolean) => void;
}

interface ButtonState {
  isApproving: boolean;
  isApproved: boolean;
  disabled: boolean;
}

export const ApproveTokenButton = (props: Props) => {
  const { amount, spenderAddress, tokenSymbol, onApprove } = props;
  const [buttonState, setButtonState] = useState<ButtonState>({
    isApproving: false,
    isApproved: false,
    disabled: false,
  });
  const [loaded, setLoaded] = useState(false);
  const connection = useSelector(GlobalSelectors.coreportConnection) || {};
  const tokenData = useSelector(TokensSelectors.tokenInfoBySymbol(tokenSymbol));
  const activeNetwork = NETWORK;
  const { account, sendTransaction } = connection;

  const parsedAmount = parseUnits(
    (amount || 0).toString(),
    tokenData?.decimals || 18
  );

  const getAllowedAmount = async () => {
    if (account && tokenData) {
      const allowedAmountToSpend = await getAllowedAmountOfTokenToSpend({
        accountAddress: account,
        rpcAddress: activeNetwork.rpcUrl,
        spenderAddress,
        tokenAddress: tokenData.address,
      });

      if (Number(allowedAmountToSpend) >= Number(parsedAmount)) {
        onApprove(true);

        setButtonState(() => {
          return { isApproved: true, disabled: true, isApproving: false };
        });
      } else {
        onApprove(false);
        setButtonState(() => {
          return { isApproved: false, disabled: false, isApproving: false };
        });
      }
    }
  };
  const requestApprovalForAmount = async () => {
    setButtonState(() => {
      return { isApproved: false, disabled: true, isApproving: true };
    });
    if (tokenData && account && sendTransaction) {
      const unsignedTx = await requestApproveToSpendToken({
        rpcUrl: activeNetwork.rpcUrl,
        spenderAddress,
        tokenAddress: tokenData.address,
        amount: parsedAmount.toString(),
        userAccountAddress: account,
      });
      try {
        const result = await sendTransaction(unsignedTx);
        console.log({ resultFromCorepass: result });
        onApprove(true);
        setButtonState((state) => {
          return { isApproving: false, disabled: true, isApproved: true };
        });
      } catch (error) {
        onApprove(false);
        setButtonState((state) => {
          return { isApproving: false, disabled: false, isApproved: false };
        });
      }
    }
  };
  useEffect(() => {
    if (account) {
      getAllowedAmount();
    }
  }, [account, amount]);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => {};
  }, []);

  if (!account || !loaded) {
    return <></>;
  }

  return (
    <StyledButton
      onClick={requestApprovalForAmount}
      fullWidth
      gradient_border="green"
      loadingText="Approving"
      isLoading={buttonState.isApproving}
      disabled={buttonState.disabled}
      is_approved={buttonState.isApproved?.toString() as StringBoolean}
    >
      Approve {tokenSymbol}
    </StyledButton>
  );
};

const StyledButton = styled(WhiteButton)<{ is_approved: StringBoolean }>`
  padding: 16px;
  ${TEXT_LG_SEMIBOLD}
  display: ${({ is_approved }) => (is_approved === 'true' ? 'none' : 'block')};
`;
export const ApproveButtonContainer = styled(Box)`
  width: 100%;
  ${ROW_CENTER}
  gap: 24px;
`;
