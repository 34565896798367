import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuProps, styled, Menu, Box, IconButton } from '@mui/material';

import { navbarActions } from '../../slice';
import { navbarSelectors } from '../../selectors';

import Icon from '@/components/icon';

import WhiteDiognalArrow from 'assets/landing/whiteDiognalArrow.svg';
import BrandIcon from 'assets/landing/brandIcon.svg';
import TwitterIcon from 'assets/landing/twitter.svg';
import DiscordIcon from 'assets/landing/discord.svg';
import GithubIcon from 'assets/landing/github.svg';

import { TEXT_LG_MEDIUM, TEXT_SM_REGULAR } from '@/styles/globalTypography';
import {
  BORDER_BLACK_WHITE_12,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';

export const HamberMenu: FC<MenuProps> = ({ open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { anchorEl } = useSelector(navbarSelectors.hamberMenu);

  const handleClose = () => {
    dispatch(navbarActions.hamberMenu({ isOpen: false, anchorEl: null }));
  };

  const handleClick = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <Container
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <LinkContainer>
        <LinkRow>
          <LinkBox onClick={() => handleClick('/governance')}>
            <LinkTitle>Vote in governance</LinkTitle>
            <Icon src={WhiteDiognalArrow} />
          </LinkBox>
          <LinkBox>
            <LinkTitle>Developers</LinkTitle>
          </LinkBox>
        </LinkRow>
        <LinkRow>
          <LinkBox>
            <LinkTitle>Legal & Privacy </LinkTitle>
            <Icon src={WhiteDiognalArrow} />
          </LinkBox>
          <LinkBox>
            <LinkTitle>Community</LinkTitle>
          </LinkBox>
        </LinkRow>
        <LinkRow>
          <LinkBox>
            <LinkTitle>Contact</LinkTitle>
            <Icon src={WhiteDiognalArrow} />
          </LinkBox>
        </LinkRow>
      </LinkContainer>

      <DocumentationContainer>
        <TitleContainer>
          <LinkBox>
            <Title>Documentation</Title>
            <Icon src={WhiteDiognalArrow} />
          </LinkBox>
          <SubTitle>
            Learn about the core concepts of the Swaps, Pools, Liquidity and
            more.
          </SubTitle>
        </TitleContainer>
        <BrandIconContainer src={BrandIcon} />
      </DocumentationContainer>

      <IconContainer>
        <IconButton>
          <Icon src={TwitterIcon} />
        </IconButton>
        <IconButton>
          <Icon src={GithubIcon} />
        </IconButton>
        <IconButton>
          <Icon src={DiscordIcon} />
        </IconButton>
      </IconContainer>
    </Container>
  );
};

const Container = styled(Menu)`
  .MuiList-root {
    width: 428px;
    background-color: var(--Black);
    padding: 48px 64px 48px;
    ${COLUMN_CENTER}
    gap: 40px;
    ${BORDER_BLACK_WHITE_12}
  }
`;
const LinkContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 24px;
  width: 100%;
  height: 120px;
  z-index: 3;
`;

const LinkRow = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;

const LinkBox = styled(Box)`
  ${ROW_CENTER}
  gap: 4px;
  cursor: pointer;
`;

const LinkTitle = styled('p')`
  ${TEXT_LG_MEDIUM}
  text-align: left;
`;

const DocumentationContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 62px;
  width: 100%;
  z-index: 3;
`;
const TitleContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 8px;
`;

const Title = styled('p')`
  ${TEXT_LG_MEDIUM}
  cursor: pointer;
`;

const SubTitle = styled('p')`
  ${TEXT_SM_REGULAR}
  text-align: left;
  color: var(--Neutrals-N100);
  min-width: 190px;
`;

const BrandIconContainer = styled(Icon)`
  width: 64px;
  height: 64px;
`;

const IconContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  width: 100%;
`;
