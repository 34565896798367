import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SelectChangeEvent } from '@mui/material';
import BaseSelect from '@/components/select/BaseSelect';
import { navbarSelectors } from '../../../selectors';
import { navbarActions } from '../../../slice';
import { NavbarState } from '../../../types';

interface Props {}

export const LanguageSelectButton = (props: Props) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [allLanguages] = useState<string[]>([...i18n.languages]);
  const languages = allLanguages.filter(
    (item) => item.toLowerCase() !== 'en-us'
  );

  const selectedLanguage = useSelector(navbarSelectors.selectedLanguage);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const v: NavbarState['selectedLanguage'] = event.target.value;
    dispatch(
      navbarActions.setSelectedLanguage(v as NavbarState['selectedLanguage'])
    );

    i18n.changeLanguage(v);
  };

  return (
    <BaseSelect
      onChange={handleChange}
      defaultValue={selectedLanguage}
      items={languages.map((language) => {
        return {
          label: language.toUpperCase(),
          value: language,
        };
      })}
    />
  );
};
