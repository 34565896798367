import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { swapActions } from '../slice';
import { swapSelectors } from '../selectors';
import { StringBoolean, TokenItemTypes } from '@/types';
import { TokenValueItem } from '@/components/input/TokenValueItem';
import { divide, multiply } from 'precise-math';
import { castStringToNumber } from '@/utils/string';

export interface ItemProps {
  type: TokenItemTypes;
  isMobileView?: StringBoolean;
}

const SwapItem: FC<ItemProps> = ({ type, isMobileView }) => {
  const dispatch = useDispatch();
  const symbol = useSelector(swapSelectors.swapTokensSymbolInfo(type));
  const value = useSelector(swapSelectors.swapTokensValue(type));

  const otherType: TokenItemTypes = type === 'From' ? 'To' : 'From';

  const otherSymbol = useSelector(
    swapSelectors.swapTokensSymbolInfo(otherType)
  );

  const priceRatioOfTokens = useSelector(
    swapSelectors.ratioOfThePriceOfTheTokensFromtoTo(
      type === 'From' ? symbol : otherSymbol,
      type === 'From' ? otherSymbol : symbol
    )
  );
  const handleInputChange = (value: string) => {
    const otherTypeValue =
      type === 'From'
        ? multiply(castStringToNumber(value), priceRatioOfTokens).toString()
        : multiply(
            castStringToNumber(value),
            divide(1, priceRatioOfTokens)
          ).toString();

    dispatch(
      swapActions.setSwapTokensValue({ type, value, otherType, otherTypeValue })
    );
  };
  const handleChangeToken = () => {
    dispatch(swapActions.selectTokenToSwapModal({ isOpen: true, type: type }));
  };

  return (
    <TokenValueItem
      onDropdownClick={handleChangeToken}
      headerText={type}
      isMobileView={isMobileView || 'false'}
      onChange={handleInputChange}
      symbol={symbol}
      value={value}
      hasActions={type !== 'To'}
    />
  );
};

export default SwapItem;
