import { FC } from 'react';

import { styled, Box, css } from '@mui/material';
import PriceFormat from '@/components/formatting/PriceFormat';
import TrendStatus from '@/components/formatting/TrendStatus';
import LineChart from '@/components/charts/LineChart';
import { ChartData } from 'chart.js';
import {
  H2_REGULAR,
  H3_REGULAR,
  TEXT_LG_MEDIUM,
  TEXT_XL_REGULAR,
  TEXT_XS_SEMIBOLD,
} from '@/styles/globalTypography';
import {
  BORDER_BLACK_WHITE_8,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import BaseButton from '@/components/button/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { TokensSelectors } from '../../selectors';
import { TIME_FRAME, numberOfDecimalsForUSDT } from '../../constants';
import { tokensActions } from '../../slice';
import { StringBoolean } from '@/types';
import { mediaQueries } from '@/styles/mediaQueries';
import { TokenIcon } from '@/components/icon/TokenIcon';

export interface TokenChartProps {
  symbol: string;
}

const TokenChart: FC<TokenChartProps> = ({ symbol, ...props }) => {
  const dispatch = useDispatch();

  const { chartTimeFrame } = useSelector(TokensSelectors.tokenSetting);

  const token = useSelector(TokensSelectors.tokenInfoBySymbol(symbol));
  const trend = useSelector(TokensSelectors.tokenTrendBySymbol(symbol));

  const state: ChartData<'line'> = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'February',
      'March',
      'April',
      'February',
      'March',
      'April',
      'February',
      'March',
      'April',
      'May',
    ],
    datasets: [
      {
        fill: false,
        borderColor: trend?.trend === 'ascending' ? '#9dfe92' : '#d0103f',
        borderWidth: 2,
        data: token?.lastPrices ?? [],
      },
    ],
  };

  const handleSelectTimeFrame = (item: keyof typeof TIME_FRAME) => {
    dispatch(tokensActions.setChartTimeFrame(item));
  };
  return (
    <Container>
      <Header>
        <TitleContainer>
          <TokenIcon address={token?.address} />
          <TokenName>{token?.name}</TokenName>
          <TokenID>{token?.symbol}</TokenID>
        </TitleContainer>
        <TokenPrice maxFraction={numberOfDecimalsForUSDT}>
          {token?.usdPrice}
        </TokenPrice>
        <TokenTrend trend="ascending" hasIcon>
          {trend?.percentageChanged}
        </TokenTrend>
      </Header>
      <LineChart
        data={state}
        options={{
          scales: {
            x: {
              display: 'auto',
            },
          },
        }}
      />

      <TimingBox>
        {Object.values(TIME_FRAME).map((item) => {
          return (
            <TimeTitle
              key={item}
              //@ts-ignore
              onClick={() => handleSelectTimeFrame(item)}
              //@ts-ignore
              is_active={chartTimeFrame === item ? 'true' : 'false'}
            >
              {item}
            </TimeTitle>
          );
        })}
      </TimingBox>
    </Container>
  );
};

const Container = styled(Box)`
  width: 60%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:24px;
  ${mediaQueries.lessThan('sm')`
  width: 100%
`}
`;

const Header = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:8px;
`;

const TitleContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 8px;
`;

const Icon = styled('img')``;

const TokenName = styled('h1')`
  ${H3_REGULAR}
`;

const TokenID = styled('h3')`
  ${TEXT_XL_REGULAR}
  color: var(--Neutrals-N100);
`;

const TokenPrice = styled(PriceFormat)`
  ${H2_REGULAR}
`;

const TokenTrend = styled(TrendStatus)`
  ${TEXT_LG_MEDIUM}
  text-align: left;
`;

const TimingBox = styled(Box)`
  padding: 8px;
  width: 100%;
  ${ROW_CENTER}
  gap: 8px;
  ${BORDER_BLACK_WHITE_8}
`;

const TimeTitle = styled(BaseButton)<{ is_active: StringBoolean }>`
  width: 20%;
  padding: 12px;
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  background-color: inherit;
  &::before {
    display: none;
  }

  ${({ is_active }) =>
    is_active === 'true' &&
    css`
      color: var(--White);
      background-color: var(--Neutrals-N700);
      &::before {
        display: block;
      }
    `}
`;

export default TokenChart;
