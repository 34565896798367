// src/componetns/Footer.tsx

import { FC } from 'react';
import { styled } from '@mui/material';
import BaseButton, { BaseButtonProps } from './BaseButton';

const SquareButton: FC<BaseButtonProps> = ({ children, ...props }) => {
  return <SButton {...props}>{children}</SButton>;
};

const SButton = styled(BaseButton)<BaseButtonProps>`
  width: 40px;
  height: 40px;
  padding: 12px;
`;

export default SquareButton;
