import { NETWORK } from '@/containers/global/initialNetwork';
import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { CoreCoinData } from '@/containers/tokens/initialTokens';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { RouterContract, RouterContract__factory } from '@/contracts/router';
import { Token__factory } from '@/contracts/token';
import { ROUTER_CONTRACT_ADDRESS, WXCB } from '@/services/constants';
import { TEXT_XS_MEDIUM } from '@/styles/globalTypography';
import { styled } from '@mui/material';
import { parseUnits } from 'm_ccbc';
import { multiply } from 'precise-math';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { swapSelectors } from '../selectors';

export const EnergyFeeForSwap = ({
  prefix = 'Energy fee: $',
  wrapper: Wrapper,
}: {
  prefix?: string;
  wrapper?: any;
}) => {
  const xcbPrice = useSelector(TokensSelectors.tokenPrice(CoreCoinData.symbol));
  const [estimatedGas, setEstimatedGas] = useState(0.05);
  const swapMethod = useSelector(swapSelectors.swapMethod);
  useEffect(() => {
    const provider = getRpcProvider(NETWORK.rpcUrl);
    const contract = RouterContract__factory.connect(
      ROUTER_CONTRACT_ADDRESS,
      provider
    ) as RouterContract;
    if (swapMethod) {
      const calculateTxAndGas = async () => {
        const { functionName, params } = swapMethod;
        const fn = contract[functionName as keyof RouterContract];
        try {
          const gas = await fn.estimateEnergy(...params);
          if (gas) {
            setEstimatedGas(
              Number(parseUnits(gas.toString(), CoreCoinData.decimals))
            );
          }
        } catch (err) {
          console.error(err);
        }
      };
      calculateTxAndGas();
    }

    return () => {};
  }, [swapMethod]);

  if (Wrapper) {
    return (
      <Wrapper>
        {prefix}
        {multiply(Number(estimatedGas), Number(xcbPrice || 0))}
      </Wrapper>
    );
  }
  return (
    <EquivalentSubText>
      {prefix}
      {multiply(Number(estimatedGas), Number(xcbPrice || 0))}
    </EquivalentSubText>
  );
};

const EquivalentSubText = styled('p')`
  ${TEXT_XS_MEDIUM}
  color: var(--Neutrals-N500);
  letter-spacing: 0.01rem;
  text-transform: uppercase;
`;
