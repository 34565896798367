import { TokenItemTypes } from '@/types';
import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';
import { TokensDomains } from '../tokens/selectors';
import { SORT_BY } from '@/constants';
import { Pool } from './types';

export const liquidityDomains = {
  root: (state: RootState) => state.navbar,
  poolDetailsModal: (state: RootState) => state.liquidity.poolDetailsModal,
  addLiquidityModal: (state: RootState) => state.liquidity.addLiquidityModal,
  creatingPoolModal: (state: RootState) => state.liquidity.creatingPoolModal,
  isAddingLiquidity: (state: RootState) => state.liquidity.isAddingLiquidity,
  liquiditySettingMenu: (state: RootState) =>
    state.liquidity.liquiditySettingMenu,
  poolSettingMenu: (state: RootState) => state.liquidity.poolSettingMenu,
  addLiquidityData: (state: RootState) => state.liquidity.addLiquidityData,
  addPoolData: (state: RootState) => state.liquidity.addPoolData,
  liquiditySelectTokenModal: (state: RootState) =>
    state.liquidity.liquiditySelectTokenModal,
  poolSelectTokenModal: (state: RootState) =>
    state.liquidity.poolSelectTokenModal,
  poolPageData: (state: RootState) => state.liquidity.poolPageData,
  removeLPModal: (state: RootState) => state.liquidity.removeLPModal,
  pools: (state: RootState) => state.liquidity.pools,
  searchValue: (state: RootState) => state?.liquidity?.searchValue,
};

export const liquiditySelectors = {
  root: createSelector(liquidityDomains.root, (root) => root),
  poolDetailsModal: createSelector(
    liquidityDomains.poolDetailsModal,
    (liquidityPoolsModal) => liquidityPoolsModal
  ),
  addLiquidityModal: createSelector(
    liquidityDomains.addLiquidityModal,
    (addLiquidityModal) => addLiquidityModal
  ),
  creatingPoolModal: createSelector(
    liquidityDomains.creatingPoolModal,
    (creatingPoolModal) => creatingPoolModal
  ),
  isAddingLiquidity: createSelector(
    liquidityDomains.isAddingLiquidity,
    (isAdding) => isAdding
  ),
  liquiditySettingMenu: createSelector(
    liquidityDomains.liquiditySettingMenu,
    (liquiditySettingMenu) => liquiditySettingMenu
  ),
  poolSettingMenu: createSelector(
    liquidityDomains.poolSettingMenu,
    (poolSettingMenu) => poolSettingMenu
  ),
  addLiquidityData: createSelector(
    liquidityDomains.addLiquidityData,
    (addLiquidityData) => addLiquidityData
  ),
  addPoolData: createSelector(
    liquidityDomains.addPoolData,
    (addPoolData) => addPoolData
  ),
  liquidityTokensSymbolInfo: (type: TokenItemTypes) =>
    createSelector([liquidityDomains.addLiquidityData], (addLiquidityData) => {
      if (type === 'From') {
        return addLiquidityData.tkn1Symbol;
      }
      return addLiquidityData.tkn2Symbol;
    }),
  liquidityTokensInfo: (type: TokenItemTypes) =>
    createSelector(
      [liquidityDomains.addLiquidityData, TokensDomains.tokens],
      (addLiquidityData, tokens) => {
        if (type === 'From') {
          return tokens[addLiquidityData.tkn1Symbol];
        }
        return tokens[addLiquidityData.tkn2Symbol];
      }
    ),
  liquidityTokensValue: (type: TokenItemTypes) =>
    createSelector([liquidityDomains.addLiquidityData], (addLiquidityData) => {
      if (type === 'From') {
        return addLiquidityData.tkn1Value;
      }
      return addLiquidityData.tkn2Value;
    }),
  poolTokensSymbolInfo: (type: TokenItemTypes) =>
    createSelector([liquidityDomains.addPoolData], (addPoolData) => {
      if (type === 'From') {
        return addPoolData.tkn1Symbol;
      }
      return addPoolData.tkn2Symbol;
    }),
  poolTokensInfo: (type: TokenItemTypes) =>
    createSelector(
      [liquidityDomains.addPoolData, TokensDomains.tokens],
      (addPoolData, tokens) => {
        if (type === 'From') {
          return tokens[addPoolData.tkn1Symbol];
        }
        return tokens[addPoolData.tkn2Symbol];
      }
    ),
  poolTokensValue: (type: TokenItemTypes) =>
    createSelector([liquidityDomains.addPoolData], (addPoolData) => {
      if (type === 'From') {
        return addPoolData.tkn1Value;
      }
      return addPoolData.tkn2Value;
    }),
  liquiditySelectTokenModal: createSelector(
    liquidityDomains.liquiditySelectTokenModal,
    (liquiditySelectTokenModal) => liquiditySelectTokenModal
  ),
  poolSelectTokenModal: createSelector(
    liquidityDomains.poolSelectTokenModal,
    (poolSelectTokenModal) => poolSelectTokenModal
  ),
  poolPageData: createSelector(
    liquidityDomains.poolPageData,
    (poolPageData) => poolPageData
  ),
  removeLPModal: createSelector(
    liquidityDomains.removeLPModal,
    (removeLPModal) => removeLPModal
  ),
  pools: createSelector(liquidityDomains.pools, (poolsList) => poolsList),
  searchedPools: createSelector(
    [
      liquidityDomains.pools,
      liquidityDomains.searchValue,
      liquidityDomains.poolPageData,
    ],
    (pools, searchValue, poolPageData) => {
      let searchedPools = pools;
      if (pools) {
        searchedPools = pools.filter((pool) => {
          if (searchValue) {
            return (
              pool.token0.symbol
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              pool.token1.symbol
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            );
          }
          return true;
        });
        const { sortBy } = poolPageData;
        const sortedPoolList = searchedPools.sort((a: Pool, b: Pool) => {
          if (sortBy === SORT_BY.HOT) {
            return b.swapEventsNumber - a.swapEventsNumber;
          } else if (sortBy === SORT_BY.NEW) {
            return b.firstMintBlockNumber - a.firstMintBlockNumber;
          }
          return 0;
        });
        return sortedPoolList;
      }
      return [];
    }
  ),
};
