import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { navbarSelectors } from '../../selectors';
import { navbarActions } from '../../slice';

import { Modal } from '@/components/modal';

import { NewsModalContent } from './content';
import { TEXT_XL_REGULAR } from '@/styles/globalTypography';

export const NewsModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(navbarSelectors.newsModal);
  const handleModalClose = () => {
    dispatch(navbarActions.newsModal({ isOpen: false, news: null }));
  };

  return (
    <CModal title={'News'} isOpen={isOpen} onClose={handleModalClose}>
      <NewsModalContent />
    </CModal>
  );
};

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 928px;
    max-height: 864px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 928px;
    max-height: 864px;
  }

  h1 {
    ${TEXT_XL_REGULAR}
    color: var(--Neutrals-N100);
  }
`;
