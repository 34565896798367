import { ChangeEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { swapSelectors } from 'containers/swap/selectors';

import { SelectTokenModalContent } from './content';
import { Modal } from '@/components/modal';
import { swapActions } from '@/containers/swap/slice';
import { tokensActions } from '@/containers/tokens/slice';
import { TokensSelectors } from '@/containers/tokens/selectors';

interface SelectTokenModalProps {
  isOpen: boolean;
  handleModalClose: () => void;
  handleSelectItem: (symbol: string) => void;
  exceptedToken: string;
}

export const SelectTokenModal: FC<SelectTokenModalProps> = ({
  isOpen,
  handleModalClose,
  handleSelectItem,
  exceptedToken,
}) => {
  const dispatch = useDispatch();

  const tokens = useSelector(
    TokensSelectors.allAvailableTokensExpect(exceptedToken)
  );

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(tokensActions.setSearchValue(event.target.value));
  };

  return (
    <CModal isOpen={isOpen} onClose={handleModalClose}>
      <SelectTokenModalContent
        tokens={tokens}
        handleSelectItem={handleSelectItem}
        handleChangeSearchValue={handleChangeSearchValue}
      />
    </CModal>
  );
};

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 656px;
    max-height: 816px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 656px;
    max-height: 816px;
  }
`;
