import PriceFormat from '@/components/formatting/PriceFormat';
import { COLUMN_CENTER, ROW_CENTER } from '@/styles/globalStyles';
import { TEXT_LG_MEDIUM, TEXT_SM_BOLD } from '@/styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';
import { Box, styled } from '@mui/material';
import { FC } from 'react';

interface TokenStatBoxProps {
  symbol: string;
}

export const TokenStatBox: FC<TokenStatBoxProps> = ({ symbol }) => {
  return (
    <Container>
      <Row>
        <StyledBox>
          <Title>liquidity</Title>
          <Value>128694</Value>
        </StyledBox>
        <Divider />
        <StyledBox>
          <Title>24h volume</Title>
          <Value>28694</Value>
        </StyledBox>
      </Row>
      <Divider />
      <Row>
        <StyledBox>
          <Title>52W low</Title>
          <Value>291</Value>
        </StyledBox>
        <Divider />
        <StyledBox>
          <Title>52W high</Title>
          <Value>3694</Value>
        </StyledBox>
      </Row>
    </Container>
  );
};

const Container = styled(Box)`
  border: 1px solid var(--Neutrals-N700);
  border-radius: 8px;
  padding: 28px 24px 24px 24px;
  width: 100%;
  ${ROW_CENTER}
  justify-content: space-evenly;
  gap: 24px;

  ${mediaQueries.lessThan('sm')`
    flex-direction: column;
  `}
`;

const StyledBox = styled(Box)`
  ${COLUMN_CENTER}
  gap: 4px;
`;

const Row = styled(Box)`
  width: 100%;
  ${ROW_CENTER}
  justify-content: space-evenly;
  gap: 24px;

  ${mediaQueries.lessThan('sm')`
      justify-content: space-evenly;
  `}
`;

const Title = styled('p')`
  ${TEXT_SM_BOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const Value = styled(PriceFormat)`
  ${TEXT_LG_MEDIUM}
`;

const Divider = styled(Box)`
  border-left: 1px solid var(--Neutrals-N700);
  height: 52px;

  ${mediaQueries.lessThan('sm')`
    display:none;
  `}
`;
