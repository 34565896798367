// src/componetns/Footer.tsx

import { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Box, styled } from '@mui/material';

import { navbarActions } from '@/containers/navbar/slice';

import BaseButton, { BaseButtonProps } from './BaseButton';

import { useAccount } from '@/hooks/useAccount';

import { TEXT_LG_SEMIBOLD } from '@/styles/globalTypography';
import { ConnectToWalletModal } from '@/containers/navbar/components/ConnectToWalletModal';

interface WalletConnectionRequierdButton extends BaseButtonProps {
  disconnectedChild?: ReactNode;
}

//@ts-ignore
export const WalletConnectionRequierdButton: FC<
  WalletConnectionRequierdButton
> = ({ disconnectedChild, children }) => {
  const { account } = useAccount();
  const dispatch = useDispatch();

  const handleConnectToWallet = () => {
    dispatch(navbarActions.connectToWalletModal({ isOpen: true }));
  };

  if (account) return children;
  return (
    <>
      <ConnectToWalletModal />
      <ConnectButtonContainer onClick={handleConnectToWallet}>
        {disconnectedChild ? (
          disconnectedChild
        ) : (
          <ConnectToWalletButton fullWidth>
            Connect wallet
          </ConnectToWalletButton>
        )}
      </ConnectButtonContainer>
    </>
  );
};

const ConnectButtonContainer = styled(Box)`
  width: 100%;
`;

const ConnectToWalletButton = styled(BaseButton)`
  ${TEXT_LG_SEMIBOLD}
  border: 1px solid var(--Neutrals-N700);
  background-color: var(--Black);
  color: var(--Neutrals-N400);
`;
