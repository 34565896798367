import { FC } from 'react';
import {
  MenuProps,
  styled,
  Menu,
  Box,
  IconButton,
  InputAdornment,
  Divider,
} from '@mui/material';

import Icon from '@/components/icon';
import WhiteButton from '@/components/button/WhiteButton';
import ToggleButton from '@/components/button/ToggleButton';

import SettingIcon from 'assets/swapping/setting.svg';

import { H3_REGULAR, TEXT_XS_SEMIBOLD } from '@/styles/globalTypography';
import {
  BORDER_BLACK_WHITE_12,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from '@/styles/globalStyles';
import NumberInput from '@/components/input/numberInput';
import { PercentageInput } from '../input/PercentageInput';
import { DEFAULT_SLIPPAGE_PERCENTAGE } from '@/config';

interface SettingMenuProps extends MenuProps {
  slippageValue: string;
  handleChaneSlippageTolerance: (value: string) => void;
  transactionDurationValue: string;
  handleChangeTransactionDuration: (value: string) => void;
  autoRouterAPI: boolean;
  handleChangeAutoRouterAPI: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  expertMode: boolean;
  handleChangeExpertMode: (event: React.ChangeEvent<HTMLInputElement>) => void;

  handleClose: () => void;
}

export const SettingMenu: FC<SettingMenuProps> = ({
  open,
  anchorEl,
  slippageValue,
  handleChaneSlippageTolerance,
  transactionDurationValue,
  handleChangeTransactionDuration,
  autoRouterAPI,
  handleChangeAutoRouterAPI,
  expertMode,
  handleChangeExpertMode,
  handleClose,
}) => {
  return (
    <Container
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <HeaderContainer>
        <Title>Settings</Title>
        <IconContainer>
          <IconButton onClick={handleClose}>
            <Icon src={SettingIcon} />
          </IconButton>
        </IconContainer>
      </HeaderContainer>
      <ContentContainer>
        <ContentTitle>Slippage tolerance</ContentTitle>
        <SlippageContainer>
          <WhiteButton
            fullWidth
            gradient_border="green"
            onClick={() => handleChaneSlippageTolerance('10')}
          >
            Auto
          </WhiteButton>
          <SlippageInput
            endAdornment={
              <InputAdornment
                position="start"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  backgroundColor: 'inherit',
                  color: '#7d7d7d',
                }}
              >
                <InputDivider orientation="vertical" />%
              </InputAdornment>
            }
            placeholder={DEFAULT_SLIPPAGE_PERCENTAGE}
            onChange={handleChaneSlippageTolerance}
            value={slippageValue ?? ''}
          />
        </SlippageContainer>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle>Transaction deadline</ContentTitle>
        <DurationContainer>
          <DurationIput
            value={transactionDurationValue}
            onChange={handleChangeTransactionDuration}
          />
          <Text>minutes</Text>
        </DurationContainer>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle>Interface settings</ContentTitle>
        <ToggleModeContainer>
          <Text>Auto Router API</Text>
          <ToggleButton
            checked={autoRouterAPI}
            handleChange={handleChangeAutoRouterAPI}
          />
        </ToggleModeContainer>
        <RowDivider orientation="horizontal" />
        <ToggleModeContainer>
          <Text>Expert Mode</Text>
          <ToggleButton
            checked={expertMode}
            handleChange={handleChangeExpertMode}
          />
        </ToggleModeContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled(Menu)`
  .MuiList-root {
    width: 300px;
    background-color: var(--Black);
    padding: 24px;
    ${COLUMN_CENTER}
    gap: 40px;
    ${BORDER_BLACK_WHITE_12}
  }
  //FIXME:change background color
  /* .MuiPaper-root {
  }
  .MuiMenu-paper {
  } */
  /* .MuiPopover-paper { */
  /* background-color: var(--Neutrals-N800-75); */
  /* } */
`;
const HeaderContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;

const Title = styled('h1')`
  ${H3_REGULAR}
  text-align: left;
`;

const IconContainer = styled(Box)`
  ${ROW_CENTER}
  gap:16px;
`;

const ContentContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  width: 100%;
`;

const ContentTitle = styled('h3')`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: left;
`;

const Text = styled('p')``;

const RowDivider = styled(Divider)`
  border-color: var(--Neutrals-N700);
  margin: 0.5px;
  width: 100%;
`;

const DurationContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 16px;
  & > * {
    flex: 1;
  }
`;

const DurationIput = styled(NumberInput)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
`;

const SlippageContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 16px;
`;

const SlippageInput = styled(PercentageInput)`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
  direction: ltr;
`;

const InputDivider = styled(Divider)`
  border-color: var(--Neutrals-N700);
  margin: 0.5px;
  height: 20px;
`;

const ToggleModeContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;
