import { FC } from 'react';
import { Box, styled } from '@mui/material';

import { NewPoroposalType } from './content';

import Icon from '@/components/icon';

import WhiteClockIcon from 'assets/landing/whiteClock.svg';

import {
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from 'styles/globalStyles';
import { TEXT_M_REGULAR } from 'styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';

interface NewPoroposalItemProps {
  newPoroposal: NewPoroposalType;
}

export const NewPoroposalItem: FC<NewPoroposalItemProps> = ({
  newPoroposal,
}) => {
  return (
    <ItemBox>
      <LeftSideContainer>
        <Icon src={WhiteClockIcon} />
        <Description>{newPoroposal.description}</Description>
      </LeftSideContainer>
      <DateContainer>{newPoroposal.remainingDay}</DateContainer>
    </ItemBox>
  );
};

const ItemBox = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  border: 1px solid var(--Neutrals-N700);
  background-color: var(--Neutrals-N900);
  border-radius: 8px;
  padding: 16px;

  ${mediaQueries.lessThan('sm')`
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  `}
`;

const LeftSideContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  text-align: left;
`;

const DateContainer = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;
