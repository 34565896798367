import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LiquidityState } from './types';
import {
  storagedLiquiditySetting,
  storagedPoolSetting,
  LOCALSTORAGE_KEYS,
  storagedPoolPageData,
} from './constants';
import { TokenItemTypes, TimeBasisType, SortType } from '@/types';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '@/utils/localStorage';
import { Token } from '../tokens/types';

const { LIQUIDITY_SETTING, POOL_SETTING, POOL_PAGE_DATA } = LOCALSTORAGE_KEYS;

const initialState: LiquidityState = {
  poolDetailsModal: undefined,
  addLiquidityModal: {
    isOpen: false,
    isApproved: false,
  },
  isAddingLiquidity: false,
  creatingPoolModal: {
    isOpen: false,
    isApproved: false,
  },
  liquiditySettingMenu: {
    isOpen: false,
    anchorEl: null,
    slippageTolerance: storagedLiquiditySetting?.slippageTolerance
      ? (storagedLiquiditySetting.slippageTolerance as LiquidityState['liquiditySettingMenu']['slippageTolerance'])
      : '',
    transactionDuration: storagedLiquiditySetting?.transactionDuration
      ? (storagedLiquiditySetting.transactionDuration as LiquidityState['liquiditySettingMenu']['transactionDuration'])
      : '30',
    autoRouterAPI: storagedLiquiditySetting?.autoRouterAPI
      ? (storagedLiquiditySetting.autoRouterAPI as LiquidityState['liquiditySettingMenu']['autoRouterAPI'])
      : false,
    expertMode: storagedLiquiditySetting?.expertMode
      ? (storagedLiquiditySetting.expertMode as LiquidityState['liquiditySettingMenu']['expertMode'])
      : false,
  },
  poolSettingMenu: {
    isOpen: false,
    anchorEl: null,
    slippageTolerance: storagedPoolSetting?.slippageTolerance
      ? (storagedPoolSetting.slippageTolerance as LiquidityState['poolSettingMenu']['slippageTolerance'])
      : '',
    transactionDuration: storagedPoolSetting?.transactionDuration
      ? (storagedPoolSetting.transactionDuration as LiquidityState['poolSettingMenu']['transactionDuration'])
      : '30',
    autoRouterAPI: storagedPoolSetting?.autoRouterAPI
      ? (storagedPoolSetting.autoRouterAPI as LiquidityState['poolSettingMenu']['autoRouterAPI'])
      : false,
    expertMode: storagedPoolSetting?.expertMode
      ? (storagedPoolSetting.expertMode as LiquidityState['poolSettingMenu']['expertMode'])
      : false,
  },
  addLiquidityData: {
    tkn1Symbol: 'CTN',
    tkn2Symbol: 'XCB',
    tkn1Value: '',
    tkn2Value: '',
  },
  addPoolData: {
    tkn1Symbol: 'CTN',
    tkn2Symbol: 'XCB',
    tkn1Value: '',
    tkn2Value: '',
  },
  liquiditySelectTokenModal: {
    isOpen: false,
    type: 'From',
  },
  poolSelectTokenModal: {
    isOpen: false,
    type: 'From',
  },
  poolPageData: {
    isRowView: storagedPoolPageData?.isRowView
      ? (storagedPoolPageData?.isRowView as LiquidityState['poolPageData']['isRowView'])
      : false,
    myLiquidity: storagedPoolPageData?.myLiquidity
      ? (storagedPoolPageData?.myLiquidity as LiquidityState['poolPageData']['myLiquidity'])
      : false,
    timeBasis: storagedPoolPageData?.timeBasis
      ? (storagedPoolPageData?.timeBasis as LiquidityState['poolPageData']['timeBasis'])
      : '1W',
    sortBy: storagedPoolPageData?.sortBy
      ? (storagedPoolPageData?.sortBy as LiquidityState['poolPageData']['sortBy'])
      : 'HOT',
  },
  removeLPModal: {
    isOpen: false,
  },
  pools: [],
  searchValue: '',
};

// liquidity slice
export const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    togglePoolDetailsModal(
      state,
      action: PayloadAction<LiquidityState['poolDetailsModal']>
    ) {
      state.poolDetailsModal = action.payload;
    },
    toggleAddLiquidityModal(
      state,
      action: PayloadAction<LiquidityState['addLiquidityModal']['isOpen']>
    ) {
      state.addLiquidityModal.isOpen = action.payload;
    },
    toggleCreatingPoolModal(
      state,
      action: PayloadAction<LiquidityState['creatingPoolModal']['isOpen']>
    ) {
      state.creatingPoolModal.isOpen = action.payload;
    },
    setApproveTokensToCreatingPoolModal(
      state,
      action: PayloadAction<LiquidityState['creatingPoolModal']['isApproved']>
    ) {
      state.creatingPoolModal.isApproved = action.payload;
    },
    setApproveTokensToAddLiquidityModal(
      state,
      action: PayloadAction<LiquidityState['addLiquidityModal']['isApproved']>
    ) {
      state.addLiquidityModal.isApproved = action.payload;
    },
    addLiquidity(
      state,
      action: PayloadAction<{
        token1: Token;
        token2: Token;
        token1Amount: number;
        token2Amount: number;
      }>
    ) {},
    getPools() {},
    setIsAddingLiquidity(state, action: PayloadAction<boolean>) {
      state.isAddingLiquidity = action.payload;
    },
    toggleLiquiditySettingMenu(
      state,
      action: PayloadAction<{
        isOpen: LiquidityState['liquiditySettingMenu']['isOpen'];
        anchorEl: LiquidityState['liquiditySettingMenu']['anchorEl'];
      }>
    ) {
      //@ts-ignore
      state.liquiditySettingMenu.anchorEl = action.payload.anchorEl;
      state.liquiditySettingMenu.isOpen = action.payload.isOpen;
    },
    setLiquiditySlippageTolerance(
      state,
      action: PayloadAction<
        LiquidityState['liquiditySettingMenu']['slippageTolerance']
      >
    ) {
      state.liquiditySettingMenu.slippageTolerance = action.payload;
      setItemToLocalStorage(LIQUIDITY_SETTING, {
        ...getItemFromLocalStorage(LIQUIDITY_SETTING),
        slippageTolerance: action.payload,
      });
    },
    setLiquidityTransactionDuration(
      state,
      action: PayloadAction<
        LiquidityState['liquiditySettingMenu']['transactionDuration']
      >
    ) {
      state.liquiditySettingMenu.transactionDuration = action.payload;
      setItemToLocalStorage(LIQUIDITY_SETTING, {
        ...getItemFromLocalStorage(LIQUIDITY_SETTING),
        transactionDuration: action.payload,
      });
    },
    togglePoolSettingMenu(
      state,
      action: PayloadAction<{
        isOpen: LiquidityState['poolSettingMenu']['isOpen'];
        anchorEl: LiquidityState['poolSettingMenu']['anchorEl'];
      }>
    ) {
      //@ts-ignore
      state.poolSettingMenu.anchorEl = action.payload.anchorEl;
      state.poolSettingMenu.isOpen = action.payload.isOpen;
    },
    setPoolSlippageTolerance(
      state,
      action: PayloadAction<
        LiquidityState['poolSettingMenu']['slippageTolerance']
      >
    ) {
      state.poolSettingMenu.slippageTolerance = action.payload;
      setItemToLocalStorage(POOL_SETTING, {
        ...getItemFromLocalStorage(POOL_SETTING),
        slippageTolerance: action.payload,
      });
    },
    setPoolTransactionDuration(
      state,
      action: PayloadAction<
        LiquidityState['poolSettingMenu']['transactionDuration']
      >
    ) {
      state.poolSettingMenu.transactionDuration = action.payload;
      setItemToLocalStorage(POOL_SETTING, {
        ...getItemFromLocalStorage(POOL_SETTING),
        transactionDuration: action.payload,
      });
    },
    setLiquidityTokensSymbol(
      state,
      action: PayloadAction<{ type: TokenItemTypes; symbol: string }>
    ) {
      if (action.payload.type === 'From') {
        state.addLiquidityData.tkn1Symbol = action.payload.symbol;
      } else {
        state.addLiquidityData.tkn2Symbol = action.payload.symbol;
      }
    },
    setLiquidityTokensValue(
      state,
      action: PayloadAction<{ type: TokenItemTypes; value: string }>
    ) {
      if (action.payload.type === 'From') {
        state.addLiquidityData.tkn1Value = action.payload.value;
      } else {
        state.addLiquidityData.tkn2Value = action.payload.value;
      }
    },
    setPoolTokensSymbol(
      state,
      action: PayloadAction<{ type: TokenItemTypes; symbol: string }>
    ) {
      if (action.payload.type === 'From') {
        state.addPoolData.tkn1Symbol = action.payload.symbol;
      } else {
        state.addPoolData.tkn2Symbol = action.payload.symbol;
      }
    },
    setPoolTokensValue(
      state,
      action: PayloadAction<{ type: TokenItemTypes; value: string }>
    ) {
      if (action.payload.type === 'From') {
        state.addPoolData.tkn1Value = action.payload.value;
      } else {
        state.addPoolData.tkn2Value = action.payload.value;
      }
    },
    setLiquiditySelectTokenModal(
      state,
      action: PayloadAction<LiquidityState['liquiditySelectTokenModal']>
    ) {
      state.liquiditySelectTokenModal = action.payload;
    },
    setPoolSelectTokenModal(
      state,
      action: PayloadAction<LiquidityState['poolSelectTokenModal']>
    ) {
      state.poolSelectTokenModal = action.payload;
    },
    setLiquidityAutoRouterAPI(state, action: PayloadAction<boolean>) {
      state.liquiditySettingMenu.autoRouterAPI = action.payload;
      setItemToLocalStorage(LIQUIDITY_SETTING, {
        ...getItemFromLocalStorage(LIQUIDITY_SETTING),
        autoRouterAPI: action.payload,
      });
    },
    setLiquidityExpertMode(state, action: PayloadAction<boolean>) {
      state.liquiditySettingMenu.expertMode = action.payload;
      setItemToLocalStorage(LIQUIDITY_SETTING, {
        ...getItemFromLocalStorage(LIQUIDITY_SETTING),
        expertMode: action.payload,
      });
    },
    setPoolAutoRouterAPI(state, action: PayloadAction<boolean>) {
      state.poolSettingMenu.autoRouterAPI = action.payload;
      setItemToLocalStorage(POOL_SETTING, {
        ...getItemFromLocalStorage(POOL_SETTING),
        autoRouterAPI: action.payload,
      });
    },
    setPoolExpertMode(state, action: PayloadAction<boolean>) {
      state.poolSettingMenu.expertMode = action.payload;
      setItemToLocalStorage(POOL_SETTING, {
        ...getItemFromLocalStorage(POOL_SETTING),
        expertMode: action.payload,
      });
    },
    toggleViewOfThePoolPage(state, action: PayloadAction<boolean>) {
      state.poolPageData.isRowView = action.payload;
      setItemToLocalStorage(POOL_PAGE_DATA, {
        ...getItemFromLocalStorage(POOL_PAGE_DATA),
        isRowView: action.payload,
      });
    },
    toggleShowMyLiquidity(state, action: PayloadAction<boolean>) {
      state.poolPageData.myLiquidity = action.payload;
      setItemToLocalStorage(POOL_PAGE_DATA, {
        ...getItemFromLocalStorage(POOL_PAGE_DATA),
        myLiquidity: action.payload,
      });
    },
    setTimeBasis(state, action: PayloadAction<TimeBasisType>) {
      state.poolPageData.timeBasis = action.payload;
      setItemToLocalStorage(POOL_PAGE_DATA, {
        ...getItemFromLocalStorage(POOL_PAGE_DATA),
        timeBasis: action.payload,
      });
    },

    setSortBy(state, action: PayloadAction<SortType>) {
      state.poolPageData.sortBy = action.payload;
      setItemToLocalStorage(POOL_PAGE_DATA, {
        ...getItemFromLocalStorage(POOL_PAGE_DATA),
        sortBy: action.payload,
      });
    },
    toggleRemoveLPModal(
      state,
      action: PayloadAction<LiquidityState['removeLPModal']['isOpen']>
    ) {
      state.removeLPModal.isOpen = action.payload;
    },
    // FIXME pool type
    setPoolsList(state, action: PayloadAction<any>) {
      state.pools = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const { actions: liquidityActions, reducer: liquidityReducer } =
  liquiditySlice;
