import { FC } from 'react';
import { Box, styled } from '@mui/material';

import PriceFormat from 'components/formatting/PriceFormat';

import {
  COLUMN_ALIGN_END__JUSTIFY_START,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from 'styles/globalStyles';
import {
  TEXT_XL_MEDIUM,
  TEXT_XL_REGULAR,
  TEXT_XS_REGULAR,
} from 'styles/globalTypography';
import { swapSelectors } from '@/containers/swap/selectors';
import { useSelector } from 'react-redux';
import { TokenItemTypes } from '@/types';
import { EquivalentPrice } from '@/components/info/equivalentPrice';
import { TokenIcon } from '@/components/icon/TokenIcon';

export interface ItemProps {
  type: TokenItemTypes;
}

const ConfirmSwapItem: FC<ItemProps> = ({ type }) => {
  const tokenDetails = useSelector(swapSelectors.swapTokensInfo(type));
  const value = useSelector(swapSelectors.swapTokensValue(type));
  return (
    <ItemBox>
      <ItemContent>
        <CoinSection>
          <TokenIcon address={tokenDetails?.address} />
          <CoinName>{tokenDetails?.symbol}</CoinName>
        </CoinSection>
        <Value>{value}</Value>
      </ItemContent>
      <EquivalentPrice symbol={tokenDetails.symbol} value={value} />
    </ItemBox>
  );
};

const ItemBox = styled(Box)`
  ${COLUMN_ALIGN_END__JUSTIFY_START}
  border: 1px var(--Neutrals-N700) solid;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
`;

const ItemContent = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap: 16px;
  width: 100%;
`;

const CoinSection = styled('div')`
  ${ROW_CENTER}
  gap: 8px;
`;

const CoinName = styled('p')`
  ${TEXT_XL_MEDIUM}
`;

const Price = styled(PriceFormat)`
  ${TEXT_XS_REGULAR}
  color: var(--Neutrals-N100);
`;

const Value = styled('p')`
  ${TEXT_XL_REGULAR}
`;

export default ConfirmSwapItem;
