import { CoreNetwork } from '@/hooks/network';

export const NETWORK: CoreNetwork = {
  name: 'Core Main Network',
  networkId: 3,
  rpcUrl: 'https://xcbapi.corecoin.cc/',
  blockBookBaseUrl: 'https://devin.blockindex.net/api/v2',
  explorerUrl: 'https://devin.blockindex.net',
  nativeCurrency: {
    name: 'core',
    symbol: 'CRE',
    decimals: 18,
  },
  iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
};

export const NETWORKs: CoreNetwork[] = [
  {
    name: 'Core Main Network',
    networkId: 3,
    rpcUrl: 'https://xcbapi.corecoin.cc/',
    blockBookBaseUrl: 'https://devin.blockindex.net/api/v2',
    explorerUrl: 'https://devin.blockindex.net',
    nativeCurrency: {
      name: 'core',
      symbol: 'CRE',
      decimals: 18,
    },
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  {
    name: 'Devin Test Network',
    networkId: 4,
    rpcUrl: 'https://xcbapi.corecoin.cc/',
    blockBookBaseUrl: 'https://devin.blockindex.net/api/v2',
    explorerUrl: 'https://devin.blockindex.net',
    nativeCurrency: {
      name: 'core',
      symbol: 'CRP',
      decimals: 18,
    },
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  {
    name: 'Devin Network',
    networkId: 5,
    rpcUrl: 'https://xcbapi.corecoin.cc/',
    blockBookBaseUrl: 'https://devin.blockindex.net/api/v2',
    explorerUrl: 'https://devin.blockindex.net',
    nativeCurrency: {
      name: 'core',
      symbol: 'CRG',
      decimals: 18,
    },
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
];
