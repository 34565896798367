import { getRpcProvider } from '@/containers/global/utils/getRpcProvider';
import { Token__factory } from '@/contracts/token';
import { AbstractProvider } from 'm_ccbc';

export const getAccountBalanceAPI = async (props: {
  address: string;
  providerAddress: string;
}) => {
  const { address, providerAddress } = props;
  try {
    return await getRpcProvider(providerAddress).getBalance(address);
  } catch (error) {
    console.error({ error });
  }
};

export const getTokenBalanceAPI = async (props: {
  userAddress: string;
  providerAddress: string;
  tokenAddress: string;
}) => {
  const { userAddress, tokenAddress, providerAddress } = props;
  try {
    const provider: AbstractProvider = getRpcProvider(providerAddress);
    const contract = Token__factory.connect(tokenAddress, provider);
    return await contract.balanceOf(userAddress);
  } catch (error) {
    console.error({ error });
  }
};

export const getTokenDetails = async (props: {
  providerAddress: string;
  tokenAddress: string;
}) => {
  const { providerAddress, tokenAddress } = props;
  try {
    const provider: AbstractProvider = getRpcProvider(providerAddress);
    const contract = Token__factory.connect(tokenAddress, provider);
    const [decimals, name, symbol] = await Promise.all([
      contract.decimals(),
      contract.name(),
      contract.symbol(),
    ]);
    return {
      decimals: Number(decimals),
      name,
      symbol,
    };
  } catch (error) {
    console.log({ error });
    return undefined;
  }
};
