import { Box, styled } from '@mui/material';

import Icon from '@/components/icon';
import ConfirmSwapItem from './ConfirmSwapItem';
import SwapDetailsBox from '../../SwapDetailsBox';
import GradientContentButton from '@/components/button/GradientContentButton';

import ChevronDownIcon from 'assets/swapping/whiteCehevronDown.svg';
import SwapIcon from 'assets/swapping/swap.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_CENTER,
} from '@/styles/globalStyles';
import { TEXT_LG_SEMIBOLD, TEXT_SM_REGULAR } from '@/styles/globalTypography';
import { useDispatch, useSelector } from 'react-redux';
import { swapSelectors } from '@/containers/swap/selectors';
import { calRemainValueOfPercentage } from '@/utils/numbers';
import { swapActions } from '@/containers/swap/slice';
import { CoreCoinData } from '@/containers/tokens/initialTokens';
import {
  ApproveButtonContainer,
  ApproveTokenButton,
} from '@/components/button/approveButton/approveButton';
import { castStringToNumber } from '@/utils/string';
import { ROUTER_CONTRACT_ADDRESS } from '@/services/constants';
import { useState } from 'react';

export const ConfirmSwapModalContent = () => {
  const dispatch = useDispatch();
  const swapMethod = useSelector(swapSelectors.swapMethod);
  const slippage = useSelector(swapSelectors.settingMenu)?.slippageTolerance;
  const tkn2Amount = useSelector(swapSelectors.swapTokensValue('To')) || 0;
  const tkn1Amount = useSelector(swapSelectors.swapTokensValue('From')) || 0;
  const toToken = useSelector(swapSelectors.swapTokensInfo('To'));
  const minimumReceived = calRemainValueOfPercentage(tkn2Amount, slippage);
  const tkn1Symbol = useSelector(swapSelectors.swapTokensSymbolInfo('From'));
  const isSwapping = useSelector(swapSelectors.isSwapping);
  const token1IsXCB: boolean = tkn1Symbol === CoreCoinData.symbol;
  const [tkn1IsApprove, setTkn1IsApprove] = useState(
    token1IsXCB ? token1IsXCB : !token1IsXCB && Number(tkn1Amount) > 0
  );

  const handleSwapClick = () => {
    dispatch(swapActions.startSwapProcess());
  };
  return (
    <Wrapper>
      <SwapTokensContainer>
        <ConfirmSwapItem type="From" />
        <DirectionBox>
          <Icon src={ChevronDownIcon} />
        </DirectionBox>
        <ConfirmSwapItem type="To" />
      </SwapTokensContainer>
      <SwapDetailsBox />
      <ApproveButtonContainer>
        {!token1IsXCB && Number(tkn1Amount) > 0 && (
          <ApproveTokenButton
            amount={castStringToNumber(tkn1Amount)}
            onApprove={(approved) => {
              setTkn1IsApprove(approved);
            }}
            spenderAddress={ROUTER_CONTRACT_ADDRESS}
            tokenSymbol={tkn1Symbol}
          />
        )}
      </ApproveButtonContainer>
      <ConfirmSwapButton
        isLoading={isSwapping}
        loadingText="Swapping..."
        disabled={!swapMethod || !tkn1IsApprove}
        onClick={handleSwapClick}
        endIcon={<Icon src={SwapIcon} />}
      >
        Confirm swap
      </ConfirmSwapButton>
      <Description>
        Output is estimated. You will receive at least
        <span>
          {' '}
          {minimumReceived} {toToken.symbol}{' '}
        </span>{' '}
        or the transaction will revert
      </Description>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
  padding: 8px;
`;

const SwapTokensContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 24px;
  width: 100%;
`;

const DirectionBox = styled(Box)`
  width: 40px;
  height: 40px;
  ${ROW_CENTER}
  border: 1px solid var(--Neutrals-N700);
  border-radius: 8px;
`;

const ConfirmSwapButton = styled(GradientContentButton)`
  ${TEXT_LG_SEMIBOLD}
  ${ROW_CENTER}
  width: 100%;
`;

const Description = styled('p')`
  ${TEXT_SM_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
  span {
    color: var(--Neutrals-N50);
  }
`;
