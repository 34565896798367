import { FC } from 'react';

import { styled, Slider, SliderProps, Box, css } from '@mui/material';

export interface StatusBarProps extends SliderProps {
  status: 'positiveChange' | 'negativeChange' | 'neutral';
  dividerValue?: string | number;
}

const StatusBar: FC<StatusBarProps> = ({
  value = 0,
  dividerValue = 0,
  ...props
}) => {
  return (
    <Container>
      <CStatusBar value={value} {...props} />
      <Divider divider_value={dividerValue} />
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  position: relative;
`;

const CStatusBar = styled(Slider)<StatusBarProps>`
  z-index: 3;
  cursor: default;
  & .MuiSlider-thumb {
    display: none;
  }
  span:first-of-type {
    height: 2px;
    background-color: var(--White);
    opacity: 0.2;
  }
  span:nth-of-type(2) {
    height: 4px;
    color: ${(props) =>
      props.status === 'positiveChange'
        ? ' var( --Brand-Neon-200)'
        : props.status === 'negativeChange'
        ? ' var( --Base-Red)'
        : props.status === 'neutral'
        ? 'var(--White)'
        : 'none'};
  }
`;

const Divider = styled(Box)<{ divider_value: number | string }>`
  ${({ divider_value }) =>
    (divider_value &&
      css`
        border-left: 1px dashed var(--White);
        opacity: 0.33;
        height: 32px;
        position: absolute;
        top: 11px;
        left: ${divider_value}%;
        z-index: 2;
      `) ||
    (!divider_value &&
      css`
        display: none;
      `)}
`;
export default StatusBar;
