import { FC } from 'react';
import { Box, styled } from '@mui/material';

import StatusBar from '@/components/charts/StatusBar';

import {
  BORDER_BLACK_WHITE_16,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_ALIGN_START__JUSTIFY_START,
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles';
import {
  H3_REGULAR,
  TEXT_LG_REGULAR,
  TEXT_M_BOLD,
  TEXT_M_REGULAR,
} from '@/styles/globalTypography';
import { Proposal } from '../types';
import { mediaQueries } from '@/styles/mediaQueries';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { governanceSelectors } from '../selectors';

export interface CurrentResultBoxProps {}

const CurrentResultBox: FC<CurrentResultBoxProps> = () => {
  const { id: stringProposalId } = useParams<{ id?: string }>();
  const proposalId = stringProposalId ? parseInt(stringProposalId, 10) : 0;
  const favoredVotes = useSelector(
    governanceSelectors.favoredVotesById(proposalId)
  );
  const againstVotes = useSelector(
    governanceSelectors.againstVotesById(proposalId)
  );

  return (
    <Container>
      <Title>Current results</Title>
      <Description>
        Vote according to your discretion, not based on the current result. The
        current result comes out in favor of the proposal.
      </Description>
      <StatusContainer>
        <StatusSide>
          <StatusHeader>
            <StatusName>For</StatusName>
            <StatusPrecentage>{favoredVotes}%</StatusPrecentage>
          </StatusHeader>
          <StatusBar status="positiveChange" value={favoredVotes} />
        </StatusSide>
        <StatusSide>
          <StatusHeader>
            <StatusName>Against</StatusName>
            <StatusPrecentage>{againstVotes}%</StatusPrecentage>
          </StatusHeader>
          <StatusBar status="negativeChange" value={againstVotes} />
        </StatusSide>
      </StatusContainer>
    </Container>
  );
};

const Container = styled(Box)`
  width: 352px;
  padding: 32px;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  ${BORDER_BLACK_WHITE_16}

  ${mediaQueries.lessThan('sm')`
    max-width: 352px;
  `}
`;

const Title = styled('h1')`
  ${H3_REGULAR}
  text-align: left;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;

const StatusContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 24px;
  width: 100%;
`;

const StatusSide = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_START}
  gap: 8px;
  width: 100%;
`;

const StatusHeader = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;

const StatusPrecentage = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
`;

const StatusName = styled('p')`
  ${TEXT_M_BOLD}
  letter-spacing: 0.1;
  text-transform: uppercase;
`;

export default CurrentResultBox;
