import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes as appRoutes } from './routes';
import Navbar from 'containers/navbar';
import Footer from 'components/Footer';
import NotFound from '@/containers/notFound/NotFound';
import 'styles/bases.css';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { Blockchain } from './containers/global';
import { CustomToastContainer } from './components/toast/ToastContainer';
import { useInitializeApp } from './hooks/useInitialApp';

Chart.register(CategoryScale);

// define theme
const theme = createTheme({
  palette: {
    primary: {
      light: '#63b8ff',
      main: '#0989e3',
      dark: '#005db0',
      contrastText: '#000',
    },
    secondary: {
      main: '#4db6ac',
      light: '#82e9de',
      dark: '#00867d',
      contrastText: '#000',
    },
  },
});
export function App() {
  useInitializeApp();
  return (
    <ThemeProvider theme={theme}>
      <CustomToastContainer />
      <CssBaseline />
      <AppContainer>
        <Blockchain />
        <Router>
          <Navbar />
          <ContentContainer>
            <Routes>
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </ContentContainer>

          <Footer />
        </Router>
      </AppContainer>
    </ThemeProvider>
  );
}
const AppContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
`;

const ContentContainer = styled(Box)`
  min-height: calc(100vh - 192px);
  box-sizing: border-box;
  background-color: var(--Black);
`;
export function WrappedApp() {
  return <App />;
}
