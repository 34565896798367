import { FC } from 'react';
import { Box, styled, css } from '@mui/material';

import Icon from '@/components/icon';

import { DateFormaterUTC } from '@/utils/date';

import SuccessIcon from 'assets/governance/executed.svg';
import FailedIcon from 'assets/governance/cancelled.svg';
import PendingIcon from 'assets/governance/pending.svg';

import {
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from 'styles/globalStyles';
import { TEXT_LG_MEDIUM, TEXT_M_REGULAR } from 'styles/globalTypography';
import { TransactionType } from './content';
import { mediaQueries } from '@/styles/mediaQueries';

interface TransactionItemProps {
  transaction: TransactionType;
}

export const TransactionItem: FC<TransactionItemProps> = ({ transaction }) => {
  return (
    <ItemBox is_pending={transaction.status === 'pending' ? 'true' : 'false'}>
      <DesktopContainer>
        <LeftSideContainer>
          <Icon
            src={
              transaction.status === 'failed'
                ? FailedIcon
                : transaction.status === 'success'
                ? SuccessIcon
                : PendingIcon
            }
          />
          <Title>{transaction.title}</Title>
          <Description>{transaction.detail}</Description>
        </LeftSideContainer>
        <DateContainer>{DateFormaterUTC(transaction.date)}</DateContainer>
      </DesktopContainer>

      <MobileContainer>
        <UpperContainer>
          <LeftSideContainer>
            <Icon
              src={
                transaction.status === 'failed'
                  ? FailedIcon
                  : transaction.status === 'success'
                  ? SuccessIcon
                  : PendingIcon
              }
            />
            <Title>
              {transaction.status === 'success'
                ? 'Confirtmed'
                : transaction.status === 'failed'
                ? 'Failed'
                : 'Pending'}
            </Title>
          </LeftSideContainer>

          <DateContainer>{DateFormaterUTC(transaction.date)}</DateContainer>
        </UpperContainer>
        <Description>{transaction.detail}</Description>
      </MobileContainer>
    </ItemBox>
  );
};

const ItemBox = styled(Box)<{ is_pending: 'false' | 'true' }>`
  width: 100%;
  border: 1px solid var(--Neutrals-N700);
  background-color: var(--Neutrals-N900);
  border-radius: 8px;
  padding: 16px;

  ${({ is_pending }) =>
    is_pending === 'true' &&
    css`
      border: 1px solid var(--Neutrals-N500);
      background-color: var(--Neutrals-N800);
    `}
`;

const DesktopContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}

  ${mediaQueries.lessThan('sm')`
    display: none;
  `}
`;

const LeftSideContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
`;

const Title = styled('p')`
  ${TEXT_LG_MEDIUM}
  text-align: left;
  min-width: 100px;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  text-align: left;
`;

const DateContainer = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;

  ${mediaQueries.lessThan('sm')`
    min-width:154px
  `}
`;

const MobileContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 16px;

  ${mediaQueries.greaterThan('sm')`
    display: none;
  `}
`;

const UpperContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
`;
