import { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled, Box, IconButton, css } from '@mui/material';

import { navbarActions } from './slice';
import { navbarSelectors } from './selectors';

import { WalletMenu } from './components/WalletMenuComponents/WalletMenu/WalletMenu';
import BaseButton from 'components/button/BaseButton';
import Icon from 'components/icon';
import SearchInput from 'components/input/SearchInput';
import { ConnectToWalletModal } from './components/ConnectToWalletModal';
import { TransactionModal } from './components/TransactionModal';
import { NotificationModal } from './components/NotificationModal';
import { NewsModal } from './components/NewsModal';

import { useAccount } from '@/hooks/useAccount';

import { ellipsisBetween } from 'utils/string';

import BrandIcon from 'assets/landing/Brand.svg';
import BrandMobileIcon from 'assets/landing/brandIcon.svg';
import MenuIcon from 'assets/landing/hamMenu.svg';
import ChevronDownIcon from 'assets/landing/chevronDown.svg';
import WalletAddressIcon from 'assets/landing/wallet.svg';
import NotificationIcon from 'assets/landing/notification.svg';

import { TEXT_LG_MEDIUM, TEXT_M_SEMIBOLD } from 'styles/globalTypography';
import {
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from 'styles/globalStyles';
import { mediaQueries } from '@/styles/mediaQueries';

import { NavbarItemsType } from './types';
import { GlobalSelectors } from '../global/selectors';
import { HamberMenu } from './components/HamberIconComponents/hamberMenu';
import { useScreenSize } from '@/hooks/useScreenSize';
import { HamberMenuModal } from './components/HamberIconComponents/HamberMenuModal';
import { WalletMenuModal } from './components/WalletMenuComponents/WalletMenuModal';

const Navbar: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { account, connected } = useSelector(
    GlobalSelectors.coreportConnection
  );

  const { isMobile } = useScreenSize();

  const activeNavbarItem = useSelector(navbarSelectors.activeNavbarItem);

  const { isOpen: walletMenuIsOpen } = useSelector(navbarSelectors.walletMenu);
  const { isOpen: hamberMenuIsOpen } = useSelector(navbarSelectors.hamberMenu);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.includes('swap')) {
      dispatch(navbarActions.setActiveNavbarItem('swap'));
    } else if (pathname.includes('tokens')) {
      dispatch(navbarActions.setActiveNavbarItem('tokens'));
    } else if (pathname.includes('pools')) {
      dispatch(navbarActions.setActiveNavbarItem('pools'));
    }
  }, []);

  const handleOpenWalletMenu = (event: React.MouseEvent<HTMLElement>) => {
    const isConnected = connected && account;
    if (!isConnected) {
      dispatch(
        navbarActions.connectToWalletModal({
          isOpen: true,
        })
      );
      return;
    }
    isMobile
      ? dispatch(navbarActions.toggleWalletMenuModal(true))
      : dispatch(navbarActions.toggleWalletMenu(!walletMenuIsOpen));
    dispatch(navbarActions.setAnchorElWalletMenu(event.currentTarget));
  };

  const handleShowNotificatons = () => {
    dispatch(navbarActions.notificationModal({ isOpen: true }));
  };

  const handleConnectToWallet = () => {
    dispatch(
      navbarActions.connectToWalletModal({
        isOpen: true,
      })
    );
  };

  const handleActiveNavbarItemChange = (activeItem: NavbarItemsType) => {
    dispatch(navbarActions.setActiveNavbarItem(activeItem));
  };

  const handleClickHamberMenu = (event: React.MouseEvent<HTMLElement>) => {
    isMobile
      ? dispatch(navbarActions.toggleHamberMenuModal(true))
      : dispatch(
          navbarActions.hamberMenu({
            isOpen: !hamberMenuIsOpen,
            anchorEl: event.currentTarget,
          })
        );
  };

  return (
    <>
      <NavBox>
        <DesktopViewContainer>
          <IconButton onClick={() => handleActiveNavbarItemChange(null)}>
            <Link to="/">
              <Icon src={BrandIcon} />
            </Link>
          </IconButton>
          {account ? <MobileDivider /> : null}
        </DesktopViewContainer>
        <MobileViewContainer>
          <StyledIconButton onClick={() => handleActiveNavbarItemChange(null)}>
            <BrandLink to="/">
              <Icon src={BrandMobileIcon} />
            </BrandLink>
          </StyledIconButton>
          <Divider />
          <StyledIconButton onClick={handleClickHamberMenu}>
            <Icon src={MenuIcon} />
          </StyledIconButton>
        </MobileViewContainer>
        <CenterContainer>
          <SwapItem
            to={'/swap'}
            onClick={() => handleActiveNavbarItemChange('swap')}
            active_item={activeNavbarItem}
          >
            Swap
          </SwapItem>
          <TokensItem
            to="/tokens"
            onClick={() => handleActiveNavbarItemChange('tokens')}
            active_item={activeNavbarItem}
          >
            Tokens
          </TokensItem>
          <PoolsItem
            to="/pools"
            onClick={() => handleActiveNavbarItemChange('pools')}
            active_item={activeNavbarItem}
          >
            Pool
          </PoolsItem>
        </CenterContainer>
        {account ? (
          <CustomSearchInput
            // @ts-ignore
            width="352px"
            placeholder="Search tokens, pools ..."
          />
        ) : null}
        <RightContainer>
          <StyledIconButton onClick={handleClickHamberMenu}>
            <DesktopIcon src={MenuIcon} />
          </StyledIconButton>
          <MobileDivider />
          {account ? (
            <>
              <StyledIconButton onClick={handleShowNotificatons}>
                <Icon src={NotificationIcon} />
              </StyledIconButton>
              <Button
                onClick={handleOpenWalletMenu}
                startIcon={<Icon src={WalletAddressIcon} />}
                endIcon={<Icon src={ChevronDownIcon} />}
              >
                {ellipsisBetween(account, 4)}
              </Button>
            </>
          ) : (
            <Button
              onClick={handleConnectToWallet}
              endIcon={<Icon src={ChevronDownIcon} />}
            >
              Get Started
            </Button>
          )}
        </RightContainer>
      </NavBox>
      <ConnectToWalletModal />
      <TransactionModal />
      <NotificationModal />
      <NewsModal />
      <WalletMenu open={walletMenuIsOpen} />
      <HamberMenu open={hamberMenuIsOpen} />
      <HamberMenuModal />
      <WalletMenuModal />
    </>
  );
};

const NavBox = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  height: 108px;
  background-color: var(--Black);
  padding: 0 40px;

  ${mediaQueries.lessThan('sm')`
    padding: 0 16px;
  `}
`;

const CenterContainer = styled('div')`
  ${ROW_CENTER}
  ${mediaQueries.lessThan('lg')`
    display:none;
  `}
`;

const RightContainer = styled('div')`
  ${ROW_CENTER}
  gap: 24px;
`;

const Title = styled(Link)<{ active_item: NavbarItemsType }>`
  ${TEXT_LG_MEDIUM}
  margin: 0 16px;
  color: var(--Neutrals-N100);
  text-decoration: none;
  ${({ active_item }) =>
    active_item === null &&
    css`
      color: var(--White);
    `}
`;

const SwapItem = styled(Title)`
  ${({ active_item }) =>
    active_item === 'swap' &&
    css`
      color: var(--White);
    `}
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const TokensItem = styled(Title)`
  ${({ active_item }) =>
    active_item === 'tokens' &&
    css`
      color: var(--White);
    `}
`;

const PoolsItem = styled(Title)`
  ${({ active_item }) =>
    active_item === 'pools' &&
    css`
      color: var(--White);
    `}
`;

const DesktopIcon = styled(Icon)`
  ${mediaQueries.lessThan('sm')`
    display:none;
  `}
`;

const MobileViewContainer = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 20px;
  ${mediaQueries.greaterThan('sm')`
    display:none;
  `}
`;

const DesktopViewContainer = styled(Box)`
  ${ROW_CENTER}
  gap:24px;
  ${mediaQueries.lessThan('sm')`
    display:none;
  `}
`;

const Divider = styled(Box)`
  border-left: 1px solid var(--Neutrals-N800);
  height: 44px;
`;

const MobileDivider = styled(Divider)`
  ${mediaQueries.lessThan('sm')`
    display:none;
  `}
`;

const Button = styled(BaseButton)`
  ${TEXT_M_SEMIBOLD}
  padding: 12px 30px;
  min-width: 124px;
  height: 44px;
`;

const CustomSearchInput = styled(SearchInput)`
  width: 352px;
  ${mediaQueries.lessThan('sm')`
    display:none;
  `}
  max-width:352px;
`;

const BrandLink = styled(Link)`
  ${ROW_CENTER}
`;

export default Navbar;
