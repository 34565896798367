import { FC } from 'react';
import { Box, css, styled } from '@mui/material';

import { COLUMN_CENTER, ROW_ALIGN_START__SPACE_B } from '@/styles/globalStyles';
import { TEXT_SM_REGULAR } from '@/styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';
import { swapSelectors } from '../selectors';
import { useSelector } from 'react-redux';
import { calRemainValueOfPercentage } from '@/utils/numbers';
import { StringBoolean } from '@/types';
import { EnergyFeeForSwap } from './energyFee';

export interface SwapDetailsBoxProps {
  isMobileView?: StringBoolean;
}

const SwapDetailsBox: FC<SwapDetailsBoxProps> = ({ isMobileView }) => {
  const slippageTolerance = useSelector(
    swapSelectors.settingMenu
  )?.slippageTolerance;
  const selectedFromSymbolToken = useSelector(
    swapSelectors.swapTokensSymbolInfo('To')
  );

  const toValue = useSelector(swapSelectors.swapTokensValue('To'));

  const showableSlippageTolerance =
    isNaN(Number(slippageTolerance)) || Number(slippageTolerance) === 0
      ? 10
      : Number(slippageTolerance);

  const expectedOutput = isNaN(Number(toValue)) ? 0 : Number(toValue);

  const minimumRecivedAfterSlippage = calRemainValueOfPercentage(
    toValue,
    slippageTolerance
  );

  return (
    <DetailsContainer>
      <DetailsRow>
        <DetailsText textType="title">Expected Output</DetailsText>
        <DetailsText textType="value">
          {expectedOutput} {selectedFromSymbolToken}
        </DetailsText>
      </DetailsRow>
      <DetailsRow>
        <DetailsText textType="title">
          Price Impact: <LowImpact> Low</LowImpact>
        </DetailsText>
        <DetailsText textType="lowImpact">2.89%</DetailsText>
      </DetailsRow>
      <DetailsRow>
        <DetailsTextDesktop textType="title" is_mobile_view={isMobileView}>
          Minimum received after slippage ({showableSlippageTolerance}%)
        </DetailsTextDesktop>
        <DetailsTextMobile textType="title" is_mobile_view={isMobileView}>
          Minimum
        </DetailsTextMobile>
        <DetailsText textType="value">
          {minimumRecivedAfterSlippage} {selectedFromSymbolToken}
        </DetailsText>
      </DetailsRow>
      <DetailsRow>
        <DetailsText textType="title">Network Fee</DetailsText>
        <EnergyFeeForSwap prefix="~$" wrapper={DetailsText} />
      </DetailsRow>
    </DetailsContainer>
  );
};

const DetailsContainer = styled(Box)`
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: 100%;
  ${COLUMN_CENTER}
  gap: 16px;
`;

const DetailsRow = styled(Box)`
  ${ROW_ALIGN_START__SPACE_B}
  width: 100%;
`;

const DetailsText = styled('p')<{
  textType: 'title' | 'value' | 'lowImpact';
}>`
  ${TEXT_SM_REGULAR}
  display: inline;
  ${({ textType }) =>
    (textType === 'title' &&
      css`
        color: var(--Neutrals-N100);
      `) ||
    (textType === 'value' &&
      css`
        color: var(--Neutrals-N50);
      `) ||
    (textType === 'lowImpact' &&
      css`
        color: var(--Brand-Neon-200);
      `)}
`;

const LowImpact = styled('span')`
  ${TEXT_SM_REGULAR}
  color: var(--Brand-Neon-200);
`;

const DetailsTextDesktop = styled('p')<{
  textType: 'title' | 'value' | 'lowImpact';
  is_mobile_view?: StringBoolean;
}>`
  ${TEXT_SM_REGULAR}
  display: inline;
  ${({ textType }) =>
    (textType === 'title' &&
      css`
        color: var(--Neutrals-N100);
      `) ||
    (textType === 'value' &&
      css`
        color: var(--Neutrals-N50);
      `) ||
    (textType === 'lowImpact' &&
      css`
        color: var(--Brand-Neon-200);
      `)}

  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      display: none;
    `}
 ${mediaQueries.lessThan('sm')`
    display:none;
 `}
`;

const DetailsTextMobile = styled('p')<{
  textType: 'title' | 'value' | 'lowImpact';
  is_mobile_view?: StringBoolean;
}>`
  ${TEXT_SM_REGULAR}
  display: none;
  ${({ textType }) =>
    (textType === 'title' &&
      css`
        color: var(--Neutrals-N100);
      `) ||
    (textType === 'value' &&
      css`
        color: var(--Neutrals-N50);
      `) ||
    (textType === 'lowImpact' &&
      css`
        color: var(--Brand-Neon-200);
      `)}

  ${({ is_mobile_view }) =>
    is_mobile_view === 'true' &&
    css`
      display: inline;
    `}
    ${mediaQueries.lessThan('sm')`
    display:inline;
 `}
`;

export default SwapDetailsBox;
