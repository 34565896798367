import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';

export const navbarDomains = {
  root: (state: RootState) => state.navbar,
  connectToWalletModal: (state: RootState) => state.navbar.connectToWalletModal,
  walletMenu: (state: RootState) => state.navbar.walletMenu,
  transactionModal: (state: RootState) => state.navbar.transactionModal,
  notificationModal: (state: RootState) => state.navbar.notificationModal,
  newsModal: (state: RootState) => state.navbar.newsModal,
  activeNavbarItem: (state: RootState) => state.navbar.activeNavbarItem,
  hamberMenu: (state: RootState) => state.navbar.hamberMenu,
  selectedLanguage: (state: RootState) => state.navbar.selectedLanguage,
  selectedFiatCurrency: (state: RootState) => state.navbar.selectedFiatCurrency,
  selectNetworkMenu: (state: RootState) => state.navbar.selectNetworkMenu,
  hamberMenuModal: (state: RootState) => state.navbar.hamberMenuModal,
  walletMenuModal: (state: RootState) => state.navbar.walletMenuModal,
};

export const navbarSelectors = {
  root: createSelector(navbarDomains.root, (root) => root),
  connectToWalletModal: createSelector(
    navbarDomains.connectToWalletModal,
    (connectToWalletModal) => connectToWalletModal
  ),
  walletMenu: createSelector(
    navbarDomains.walletMenu,
    (walletMenu) => walletMenu
  ),
  transactionModal: createSelector(
    navbarDomains.transactionModal,
    (transactionModal) => transactionModal
  ),
  notificationModal: createSelector(
    navbarDomains.notificationModal,
    (notificationModal) => notificationModal
  ),
  newsModal: createSelector(navbarDomains.newsModal, (newsModal) => newsModal),
  activeNavbarItem: createSelector(
    navbarDomains.activeNavbarItem,
    (activeNavbarItem) => activeNavbarItem
  ),
  hamberMenu: createSelector(
    navbarDomains.hamberMenu,
    (hamberMenu) => hamberMenu
  ),
  selectedLanguage: createSelector(
    navbarDomains.selectedLanguage,
    (selectedLanguage) => selectedLanguage
  ),
  selectedFiatCurrency: createSelector(
    navbarDomains.selectedFiatCurrency,
    (selectedFiatCurrency) => selectedFiatCurrency
  ),
  selectNetworkMenu: createSelector(
    navbarDomains.selectNetworkMenu,
    (selectNetworkMenu) => selectNetworkMenu
  ),
  hamberMenuModal: createSelector(
    navbarDomains.hamberMenuModal,
    (hamberMenuModal) => hamberMenuModal
  ),
  walletMenuModal: createSelector(
    navbarDomains.walletMenuModal,
    (walletMenuModal) => walletMenuModal
  ),
};
