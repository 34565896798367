import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';

import { styled } from '@mui/material';

export interface LineChartProps {
  options?: ChartOptions<'line'>;
  data: ChartData<'line'>;
}

const LineChart: FC<LineChartProps> = ({ data }) => {
  return (
    <CLineChart
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      }}
    />
  );
};

const CLineChart = styled(Line)`
  padding: 0;
  margin: 0;
`;
export default LineChart;
