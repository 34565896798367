import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NETWORK } from './initialNetwork';
import { GlobalState } from './types';
import { CoreNetwork } from '@/hooks/network';

const initialState: GlobalState = {
  coreportConnection: {},
  userBalance: 0,
  activeNetwork: NETWORK,
};

// global slice
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setConnectionData: (
      state,
      action: PayloadAction<GlobalState['coreportConnection']>
    ) => {
      state.coreportConnection = action.payload;
      state.activeNetwork = action.payload.network || NETWORK;
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.coreportConnection.account = action.payload;
      state.activeWalletAddress = action.payload;
    },
    getAccountRelatedData: (state, action: PayloadAction<void>) => {},
    setActiveNetwork: (state, action: PayloadAction<CoreNetwork>) => {
      console.log(action);

      state.activeNetwork = action.payload;
    },
  },
});

export const { actions: globalActions, reducer: globalReducer } = globalSlice;
