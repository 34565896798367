import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SwapState } from './types';
import { storagedSwapSetting, LOCALSTORAGE_KEYS } from './constants';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '@/utils/localStorage';
import { TokenItemTypes } from '@/types';

const { SWAP_SETTING } = LOCALSTORAGE_KEYS;

const initialState: SwapState = {
  isSwapping: false,
  confirmSwapModal: {
    isOpen: false,
  },
  selectTokenToSwapModal: {
    isOpen: false,
    type: 'From',
  },
  swapSettingMenu: {
    isOpen: false,
    anchorEl: null,
    transactionDuration: storagedSwapSetting?.transactionDuration
      ? (storagedSwapSetting.transactionDuration as SwapState['swapSettingMenu']['transactionDuration'])
      : '30',
    slippageTolerance: storagedSwapSetting?.slippageTolerance
      ? (storagedSwapSetting.slippageTolerance as SwapState['swapSettingMenu']['slippageTolerance'])
      : '',

    autoRouterAPI: storagedSwapSetting?.autoRouterAPI
      ? (storagedSwapSetting.autoRouterAPI as SwapState['swapSettingMenu']['autoRouterAPI'])
      : false,
    expertMode: storagedSwapSetting?.expertMode
      ? (storagedSwapSetting.expertMode as SwapState['swapSettingMenu']['expertMode'])
      : false,
  },
  selectedFromSymbolToken: 'XCB',
  selectedToSymbolToken: 'CTN',
  fromValue: '',
  toValue: '',
  ratioOfThePriceOfTheTokensFromtoTo: 1,
  lastEntry: 'none',
};
// swap page slice
export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    setIsConfirmModalOpen(state, action: PayloadAction<boolean>) {
      state.confirmSwapModal.isOpen = action.payload;
    },
    selectTokenToSwapModal(
      state,
      action: PayloadAction<SwapState['selectTokenToSwapModal']>
    ) {
      state.selectTokenToSwapModal = action.payload;
    },
    toggleSwapSettingMenu(
      state,
      action: PayloadAction<{
        isOpen: SwapState['swapSettingMenu']['isOpen'];
        anchorEl: SwapState['swapSettingMenu']['anchorEl'];
      }>
    ) {
      //@ts-ignore
      state.swapSettingMenu.anchorEl = action.payload.anchorEl;
      state.swapSettingMenu.isOpen = action.payload.isOpen;
    },
    setSwapTokensSymbol(
      state,
      action: PayloadAction<{ type: TokenItemTypes; symbol: string }>
    ) {
      if (action.payload.type === 'From') {
        state.selectedFromSymbolToken = action.payload.symbol;
      } else {
        state.selectedToSymbolToken = action.payload.symbol;
      }
    },
    setSwapTokensValue(
      state,
      action: PayloadAction<{
        type: TokenItemTypes;
        value: string;
        otherType: TokenItemTypes;
        otherTypeValue: string;
      }>
    ) {
      if (action.payload.type === 'From') {
        state.fromValue = action.payload.value;
        state.toValue = action.payload.otherTypeValue;
        state.lastEntry = 'token1';
      } else {
        state.toValue = action.payload.value;
        state.fromValue = action.payload.otherTypeValue;
        state.lastEntry = 'token2';
      }
    },
    setTransactionDuration(state, action: PayloadAction<string>) {
      state.swapSettingMenu.transactionDuration = action.payload;
      setItemToLocalStorage(SWAP_SETTING, {
        ...getItemFromLocalStorage(SWAP_SETTING),
        transactionDuration: action.payload,
      });
    },
    setSwapSlippageTolerance(state, action: PayloadAction<string>) {
      state.swapSettingMenu.slippageTolerance = action.payload;
      setItemToLocalStorage(SWAP_SETTING, {
        ...getItemFromLocalStorage(SWAP_SETTING),
        slippageTolerance: action.payload,
      });
    },
    setSwapAutoRouterAPI(state, action: PayloadAction<boolean>) {
      state.swapSettingMenu.autoRouterAPI = action.payload;
      setItemToLocalStorage(SWAP_SETTING, {
        ...getItemFromLocalStorage(SWAP_SETTING),
        autoRouterAPI: action.payload,
      });
    },
    setSwapExpertMode(state, action: PayloadAction<boolean>) {
      state.swapSettingMenu.expertMode = action.payload;
      setItemToLocalStorage(SWAP_SETTING, {
        ...getItemFromLocalStorage(SWAP_SETTING),
        expertMode: action.payload,
      });
    },
    startSwapProcess() {},
    setIsSwapping(state, action: PayloadAction<boolean>) {
      state.isSwapping = action.payload;
    },
  },
});

export const { actions: swapActions, reducer: swapReducer } = swapSlice;
