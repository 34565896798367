import { AddressLike } from 'm_ccbc';
import Icon from '.';
import { FC } from 'react';
import XCBIcon from '@/assets/tokens/xcb_icon.svg';
import { styled } from '@mui/material';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { useSelector } from 'react-redux';

interface TokenIconProps {
  address?: string | AddressLike;
}
export const TokenIcon: FC<TokenIconProps> = ({ address = '' }) => {
  const token = useSelector(TokensSelectors.tokenInfoByAddress(address));

  return (
    <>
      {token?.logoURI ? (
        <Icon src={token?.logoURI} />
      ) : (
        <FilterIcon src={XCBIcon} />
      )}
    </>
  );
};

const FilterIcon = styled(Icon)`
  filter: grayscale(100%);
`;
