import { FC } from 'react';
import { Box, styled } from '@mui/material';

import Icon from '@/components/icon';

import CloseIcon from 'assets/swapping/grayClose.svg';

import { COLUMN_CENTER, ROW_CENTER } from 'styles/globalStyles';
import { TEXT_LG_REGULAR } from 'styles/globalTypography';

export interface Props {}

export const NotAvailableToken: FC<Props> = () => {
  return (
    <Container>
      <IconContainer>
        <Icon src={CloseIcon} />
      </IconContainer>
      <Description>{`We were unable to find the token you're looking for.`}</Description>
    </Container>
  );
};

const Container = styled(Box)`
  padding: 104px 48px;
  ${COLUMN_CENTER}
  gap: 40px;
`;

const IconContainer = styled(Box)`
  width: 104px;
  height: 104px;
  background-color: var(--Neutrals-N800);
  border: 1px solid var(--Neutrals-N700);
  border-radius: 8px;
  ${ROW_CENTER}
`;

const Description = styled('p')`
  ${TEXT_LG_REGULAR}
  color: var(--Neutrals-N100);
`;
