import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuProps, styled, Menu, Box } from '@mui/material';

import { BORDER_BLACK_WHITE_12, COLUMN_CENTER } from '@/styles/globalStyles';
import { navbarSelectors } from '../../../selectors';
import { navbarActions } from '../../../slice';
import { NETWORKs } from '@/containers/global/initialNetwork';
import { NetworkItem } from './NetworkItem';

export const SelectNetworkMenu: FC<MenuProps> = ({ open }) => {
  const dispatch = useDispatch();

  const { anchorEl } = useSelector(navbarSelectors.selectNetworkMenu);

  const handleClose = () => {
    dispatch(
      navbarActions.selectNetworkMenu({ isOpen: false, anchorEl: null })
    );
  };

  return (
    <Container
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {NETWORKs.map((network) => {
        return <NetworkItem key={network.networkId} network={network} />;
      })}
    </Container>
  );
};

const Container = styled(Menu)`
  .MuiList-root {
    min-width: 200px;
    background-color: var(--Neutrals-N850);
    ${COLUMN_CENTER}
    ${BORDER_BLACK_WHITE_12}
  }
`;

const Divider = styled(Box)`
  width: 100%;
  border-bottom: 1px solid var(--Neutrals-N500);
`;
