export interface GovernanceState {
  isGettingProposals: boolean;
  proposals: {
    [key: string]: Proposal;
  };
  error: string;
  createProposalModal: {
    isOpen: boolean;
  };
}

export interface ProposalDataType {
  id: number;
  date: string;
  title: string;
  description: string;
  forPrecentage: number;
  againstPrecentage: number;
  proposalStatus: 'open' | 'Executed' | 'cancelled';
}

export interface Proposal {
  id: number;
  title: string;
  description: string;
  details: string;
  startDate: Date;
  endDate: Date;
  quorum: Quorum;
  votes: { agree: number; disagree: number };
  proposalStatus: 'open' | 'Executed' | 'cancelled';
}

export enum Quorum {
  normal = 51,
  strict = 90,
}
