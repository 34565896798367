import { FC, useState } from 'react';
import { styled, SwitchProps, Switch } from '@mui/material';

export interface ToggleButtonProps extends SwitchProps {
  checked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  checked = false,
  handleChange,
  ...props
}) => {
  return (
    //@ts-ignore
    <TButton checked={checked} onChange={handleChange} {...props}></TButton>
  );
};

export const TButton = styled((props: ToggleButtonProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: 48,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        background:
          theme.palette.mode === 'dark'
            ? '#2ECA45'
            : ` linear-gradient(86.34deg, #D1FE92 6.01%, #099669 93.99%)`,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid var(--Neutrals-N100)',
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    color: checked ? 'var(--White)' : 'var(--Neutrals-N100)',
  },

  '& .MuiSwitch-track': {
    borderRadius: 28 / 2,
    backgroundColor:
      theme.palette.mode === 'light' ? 'var(--Neutrals-N600)' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default ToggleButton;
