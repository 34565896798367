import { FC } from 'react';
import { styled } from '@mui/material';
import { addCommaSeparator, truncating } from 'utils/string';
import { useSelector } from 'react-redux';
import { navbarSelectors } from '@/containers/navbar/selectors';

export interface PriceFormatProps {
  children?: number | string;
  maxFraction?: number;
}

const PriceFormat: FC<PriceFormatProps> = ({
  children = 0,
  maxFraction = 0,
  ...props
}) => {
  const { symbol: currencySymbol } = useSelector(
    navbarSelectors.selectedFiatCurrency
  );

  return (
    <Price {...props}>
      {currencySymbol}
      {typeof children === 'number'
        ? addCommaSeparator(truncating(children, maxFraction))
        : children}
    </Price>
  );
};

const Price = styled('p')``;

export default PriceFormat;
