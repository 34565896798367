import { COLUMN_ALIGN_START__JUSTIFY_CENTER } from '@/styles/globalStyles';
import { TEXT_M_REGULAR, TEXT_XL_REGULAR } from '@/styles/globalTypography';
import { styled } from '@mui/material';

export const ConnectionFailedToast = () => {
  return (
    <Container>
      <Title>Warning: Connection Failed</Title>
      <Description>
        {` We're currently experiencing difficulties connecting to the Coreport.
        Please check your internet connection and try again. If the problem
        persists, you may wish to update your application or contact our
        customer support. We apologize for any inconvenience caused.`}
      </Description>
    </Container>
  );
};

const Container = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:24px;
`;

const Title = styled('p')`
  ${TEXT_XL_REGULAR}
  text-align: left;
`;

const Description = styled('p')`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
  text-align: left;
`;
