import { useDispatch, useSelector } from 'react-redux';
import { styled, Box, IconButton, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { navbarActions } from '../../../slice';

import Icon from '@/components/icon';
import GradientContentButton from '@/components/button/GradientContentButton';
import BaseButton from '@/components/button/BaseButton';
import SquareButton from '@/components/button/SquareButton';
import PriceFormat from '@/components/formatting/PriceFormat';

import DiagonalArrowIcon from 'assets/swapping/swap.svg';
import TransactionIcon from 'assets/swapping/transfer.svg';
import DisconnectIcon from 'assets/landing/disconnect.svg';
import WalletAddressIcon from 'assets/landing/wallet.svg';
import CopyIcon from 'assets/landing/copy.svg';
import ShareIcon from 'assets/landing/share.svg';

import { ellipsisBetween } from '@/utils/string';

import {
  TEXT_LG_MEDIUM,
  TEXT_LG_SEMIBOLD,
  TEXT_M_SEMIBOLD,
  H2_REGULAR,
  TEXT_M_REGULAR,
  TEXT_M_BOLD,
} from '@/styles/globalTypography';
import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from '@/styles/globalStyles';

import { GlobalSelectors } from '@/containers/global/selectors';
import { copyTextToClipboard } from '@/utils/copyText';
import { TokensSelectors } from '@/containers/tokens/selectors';
import { FiatCurrenctSelectButton } from './FiatCurrencySelectButton';
import { LanguageSelectButton } from './LanguageSelectButton';

export const WalletMenuContent = () => {
  const dispatch = useDispatch();
  const { account, disconnect } = useSelector(
    GlobalSelectors.coreportConnection
  );

  const address = account;

  const network = useSelector(GlobalSelectors.activeNetwork);

  const balanceOfTheXCB = useSelector(
    TokensSelectors.tokenBalanceInUsdt('XCB')
  );
  const priceOfTheXCB = useSelector(
    TokensSelectors.equavalantPrice({ symbol: 'XCB', value: balanceOfTheXCB })
  );

  const handleClose = () => {
    dispatch(navbarActions.toggleWalletMenu(false));
    dispatch(navbarActions.setAnchorElWalletMenu(null));
  };

  const handleTransactionsListOpen = () => {
    dispatch(navbarActions.transactionModal({ isOpen: true }));
    handleClose();
  };

  const handleDisconnectClick = () => {
    disconnect && disconnect();
    handleClose();
  };

  const handleSelectNetwork = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(
      navbarActions.selectNetworkMenu({
        isOpen: true,
        anchorEl: event.currentTarget,
      })
    );
  };
  return (
    <>
      <SpaceBetweenContainer>
        <HalfRowContainer>
          <Icon src={WalletAddressIcon} />
          <WalletAddress>{ellipsisBetween(address || '', 5)}</WalletAddress>
        </HalfRowContainer>
        <HalfRowContainer>
          <SquareButton
            onClick={() => {
              copyTextToClipboard(address);
            }}
          >
            <Icon src={CopyIcon} />
          </SquareButton>
          <SquareButton>
            <Icon src={ShareIcon} />
          </SquareButton>
        </HalfRowContainer>
      </SpaceBetweenContainer>
      <BalanceContainer>
        <BalanceName>Xcb balance</BalanceName>
        <BalanceValueContainer>
          <BalanceValue>{balanceOfTheXCB} XCB</BalanceValue>
          <BalanceValuePrice>{priceOfTheXCB}</BalanceValuePrice>
        </BalanceValueContainer>
      </BalanceContainer>
      <BuyButton fullWidth endIcon={<Icon src={DiagonalArrowIcon} />}>
        Buy crypto
      </BuyButton>
      <SettingContainer>
        <SpaceBetweenContainer>
          <SettingName>Chain</SettingName>
          <SettingValueContainer onClick={handleSelectNetwork}>
            <NetworkIcon src={network?.iconUrl ?? ''} />
            <SettingValue>{network?.name}</SettingValue>
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          </SettingValueContainer>
        </SpaceBetweenContainer>
        <RowDivider />
        <SpaceBetweenContainer>
          <SettingName>FIAT Currency</SettingName>
          <SettingValueContainer>
            <FiatCurrenctSelectButton />
          </SettingValueContainer>
        </SpaceBetweenContainer>
        <RowDivider />
        <SpaceBetweenContainer>
          <SettingName>Language</SettingName>
          <SettingValueContainer>
            <LanguageSelectButton />
          </SettingValueContainer>
        </SpaceBetweenContainer>
      </SettingContainer>
      <CtaButton
        gradient_border="white"
        fullWidth
        endIcon={<Icon src={TransactionIcon} />}
        onClick={handleTransactionsListOpen}
      >
        Transactions
      </CtaButton>
      <CtaButton
        onClick={handleDisconnectClick}
        gradient_border="white"
        fullWidth
        endIcon={<Icon src={DisconnectIcon} />}
      >
        Disconnect
      </CtaButton>
    </>
  );
};

const SpaceBetweenContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width:100%;
`;

const HalfRowContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 16px;
`;

const WalletAddress = styled('p')`
  ${TEXT_LG_MEDIUM}
  text-align: left;
`;

const BalanceContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 24px;
  padding: 24px 0px;
`;

const BalanceValueContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 8px;
`;

const BalanceName = styled('p')`
  ${TEXT_M_BOLD}
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--Neutrals-N100);
`;

const BalanceValue = styled('p')`
  ${H2_REGULAR}
`;

const BalanceValuePrice = styled(PriceFormat)`
  ${TEXT_M_REGULAR}
  color: var(--Neutrals-N100);
`;

const BuyButton = styled(GradientContentButton)`
  ${ROW_CENTER}
  padding: 16 auto;
  ${TEXT_LG_SEMIBOLD}
  height: 56px;
`;

const SettingContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 8px;
  width: 100%;
  padding: 16px 0;
`;

const SettingName = styled('p')`
  ${TEXT_LG_MEDIUM}
  color: var(--Neutrals-N100);
`;

const SettingValueContainer = styled(Box)`
  ${ROW_CENTER}
  cursor: pointer;
  z-index: 3;
`;

const NetworkIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const SettingValue = styled('p')`
  ${TEXT_LG_MEDIUM}
`;

const RowDivider = styled(Divider)`
  border-color: var(--Neutrals-N700);
  margin: 1px;
  width: 100%;
`;

const CtaButton = styled(BaseButton)`
  ${TEXT_M_SEMIBOLD}
`;
