import Icon from '../icon';
import WhiteIcon from 'assets/landing/whiteDiognalArrow.svg';
import GrayIcon from 'assets/landing/grayDiagnolArrow.svg';
import GreenIcon from 'assets/tokens/greenArrow.svg';
import { FC } from 'react';
import { Box, TypographyProps, styled } from '@mui/material';
import { ROW_JUSTIFY_START__ALIGN_CENTER } from '@/styles/globalStyles';
import { useNavigate } from 'react-router-dom';

interface Props extends TypographyProps {
  hasIcon?: boolean;
  color?: 'white' | 'gray' | 'green';
  iconWidth?: string;
  iconHeight?: string;
  to: string;
}

export const CustomLink: FC<Props> = ({
  hasIcon = true,
  color = 'white',
  iconWidth = '24px',
  iconHeight = '24px',
  children,
  to = '/',
}) => {
  const navigate = useNavigate();

  const selectedIcon =
    color === 'white' ? WhiteIcon : color === 'gray' ? GrayIcon : GreenIcon;

  const handleClickLink = () => {
    if (to.startsWith('http')) {
      window.location.href = to;
    } else navigate(to);
  };

  return (
    <Container color={color} onClick={handleClickLink}>
      {children}

      {hasIcon && (
        <Icon src={selectedIcon} width={iconWidth} height={iconHeight} />
      )}
    </Container>
  );
};

const Container = styled(Box)<{ color: 'white' | 'gray' | 'green' }>`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 4px;

  color: ${({ color }) =>
    color === 'white'
      ? ' var(--White)'
      : color === 'gray'
      ? 'var(--Neutrals-N100)'
      : ' var(--Brand-Neon-200)'};
`;
