import { useDispatch } from 'react-redux';
import { styled, Box } from '@mui/material';

import { navbarActions } from '../../slice';

import { TransactionItem } from '../TransactionModal/TransactionItem';
import Icon from '@/components/icon';
import BaseButton from '@/components/button/BaseButton';
import { NewsItem } from './NewsItem';
import { NewPoroposalItem } from './NewPoroposalItem';
import { TRANSACTIONS } from '../TransactionModal/content';

import GraySuccessIcon from 'assets/landing/grayExecute.svg';
import GrayRecentIcon from 'assets/landing/grayRecent.svg';
import GrayDiognalArrowIcon from 'assets/landing/grayDiagnolArrow.svg';
import WhiteDiognalArrowIcon from 'assets/landing/whiteDiognalArrow.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  COLUMN_CENTER,
  HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING,
  ROW_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import { TEXT_LG_SEMIBOLD, TEXT_XS_SEMIBOLD } from '@/styles/globalTypography';
import { mediaQueries } from '@/styles/mediaQueries';

export interface NewPoroposalType {
  description: string;
  remainingDay: string;
  id: string | number;
}
export interface NewsType {
  title: string;
  subTitle: string;
  description: string;
  date: Date;
  id: string | number;
}

export const NEW_POROPOSALS: NewPoroposalType[] = [
  {
    description: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    remainingDay: '6d 23h 12m',
    id: 1,
  },
  {
    description: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    remainingDay: '6d 23h 12m',
    id: 2,
  },
  {
    description: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    remainingDay: '6d 23h 12m',
    id: 3,
  },
  {
    description: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    remainingDay: '6d 23h 12m',
    id: 4,
  },
  {
    description: 'Fix the Cross Chain Messaging Bridge on Arbitrum',
    remainingDay: '6d 23h 12m',
    id: 5,
  },
];

export const NEWS: NewsType[] = [
  {
    title: 'New UI is live on Beta',
    subTitle: 'whats up cueswappers ?',
    description: `CueSwap's new UI is finally here!
    We appreciate your patience! Having encountered a number of "growing pains" due to an influx of new users and high-traffic events like CueSwapping launches in recent months, significant resources have been spent on less user-facing infrastructure improvements to ensure things are up to speed. However CueSwap’s updated UI has now arrived! Some features like the Trading and CueSwapping pages are still under development.
    Keep in mind that this Beta version is still a work in progress, so we’d love to hear your thoughts. Have feedback? Let us know in our feature-requests channel in discord
    The new UI has also been re-built on top of CueSwap’s comprehensive SDK. The SDK also took countless hours to build out, but now means that future updates, improvements, and integrations with other projects can happen much more smoothly.
    Please note that this is a beta version of CueSwap V2, which is still undergoing final testing before official release. The platform, its software and all content found on it are provided on an “as is” and “as available” basis. CueSwap does not give any warranties, whether express or implied, as to the suitability or usability of the website.`,
    date: new Date(),
    id: 1,
  },
];

export const NotificationModalContent = () => {
  const dispatch = useDispatch();
  const handleShowAllTransactions = () => {
    dispatch(navbarActions.transactionModal({ isOpen: true }));
  };

  return (
    <Container>
      <Wrapper>
        <CategoryContainer>
          <Header>
            <Icon src={GraySuccessIcon} />
            <Title>News and releases</Title>
          </Header>
          <ContentContainer>
            {NEWS.map((news) => {
              return <NewsItem news={news} key={news.id} />;
            })}
          </ContentContainer>
        </CategoryContainer>
        <CategoryContainer>
          <Header>
            <Icon src={GrayRecentIcon} />
            <Title>Recent proposals</Title>
          </Header>
          <ContentContainer>
            {NEW_POROPOSALS.map((proposal) => {
              return (
                <NewPoroposalItem newPoroposal={proposal} key={proposal.id} />
              );
            })}
          </ContentContainer>
        </CategoryContainer>
        <CategoryContainer>
          <Header>
            <Icon src={GrayDiognalArrowIcon} />
            <Title>Recent notifications</Title>
          </Header>
          <ContentContainer>
            {TRANSACTIONS.map((transaction) => {
              return (
                <TransactionItem
                  key={transaction.id}
                  transaction={transaction}
                />
              );
            })}
          </ContentContainer>
        </CategoryContainer>
      </Wrapper>
      <ButtonContainer>
        <Button
          fullWidth
          gradient_border="white"
          endIcon={<Icon src={WhiteDiognalArrowIcon} />}
        >
          All proposals
        </Button>
        <Button
          fullWidth
          gradient_border="white"
          endIcon={<Icon src={WhiteDiognalArrowIcon} />}
          onClick={handleShowAllTransactions}
        >
          All transactions
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled(Box)`
  position: relative;
`;

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
  overflow: auto;
  max-height: 640px;
  height: auto;
  padding: 80px 0;
  ${HIDE_SCROLLBAR_BUT_ALLOW_SCROLLING}
  ${mediaQueries.lessThan('sm')`
    gap:24px;
    padding:160px 0;
  `}
`;

const CategoryContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 16px;
  width: 100%;
`;

const Header = styled(Box)`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 8px;
  width: 100%;
`;

const Title = styled('p')`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-align: left;
`;

const ContentContainer = styled(Box)`
  ${COLUMN_CENTER}
  gap: 8px;
  width: 100%;
`;

const ButtonContainer = styled(Box)`
  ${ROW_CENTER}
  gap: 24px;
  width: 100%;
  height: 80px;
  background-color: var(--Black);
  position: absolute;
  bottom: -24px;

  ${mediaQueries.lessThan('sm')`
    flex-direction:column;
    height: 160px;

  `}
`;

const Button = styled(BaseButton)`
  ${TEXT_LG_SEMIBOLD}
`;
