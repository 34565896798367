import { IRoute } from './interfaces';
import { LiquidityPage } from './containers/liquidity/loadable';
import { HomaPage } from './containers/home/loadable';
import { GovernancePage } from './containers/governance/loadable';
import { SwapPage } from './containers/swap/loadable';
import { VoteProposalComponent } from './containers/governance/components/VoteProposalComponent';
import { TokensPage } from './containers/tokens/loadable';
import TokenOverview from './containers/tokens/components/TokenOverview';

export const routes: Array<IRoute> = [
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    component: HomaPage,
  },
  {
    key: 'swapping-route',
    title: 'swap',
    path: '/swap',
    enabled: true,
    component: SwapPage,
  },
  {
    key: 'swapping-route',
    title: 'swap',
    path: '/swap/:id',
    enabled: true,
    component: SwapPage,
  },
  {
    key: 'liquidity-route',
    title: 'pools',
    path: '/pools/*',
    enabled: true,
    component: LiquidityPage,
  },
  {
    key: 'governance-route',
    title: 'governance',
    path: '/governance/*',
    enabled: true,
    component: GovernancePage,
  },

  {
    key: 'vote-proposal-route',
    title: 'vote-proposal',
    path: '/vote-proposal/:id',
    enabled: true,
    component: VoteProposalComponent,
  },
  {
    key: 'tokens-route',
    title: 'tokens',
    path: '/tokens',
    enabled: true,
    component: TokensPage,
  },

  {
    key: 'token-route',
    title: 'token',
    path: '/tokens/:id',
    enabled: true,
    component: TokenOverview,
  },
];
