/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { globalReducer } from '@/containers/global/slice';
import { governanceReducer } from '@/containers/governance/slice';
import { liquidityReducer } from '@/containers/liquidity/slice';
import { navbarReducer } from '@/containers/navbar/slice';
import { swapReducer } from '@/containers/swap/slice';
import { tokensReducer } from '@/containers/tokens/slice';
import { combineReducers } from '@reduxjs/toolkit';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer() {
  return combineReducers({
    global: globalReducer,
    liquidity: liquidityReducer,
    governance: governanceReducer,
    swap: swapReducer,
    tokens: tokensReducer,
    navbar: navbarReducer,
  });
}
