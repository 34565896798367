import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { TokensSelectors } from '@/containers/tokens/selectors';
import { numberOfDecimalsForUSDT } from '@/containers/tokens/constants';
import { swapSelectors } from '@/containers/swap/selectors';
import { swapActions } from '@/containers/swap/slice';

import PriceFormat from 'components/formatting/PriceFormat';
import Icon from '@/components/icon';

import {
  COLUMN_ALIGN_END__JUSTIFY_START,
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from 'styles/globalStyles';
import {
  TEXT_LG_MEDIUM,
  TEXT_M_REGULAR,
  TEXT_SM_REGULAR,
  TEXT_XS_SEMIBOLD,
} from 'styles/globalTypography';
import { ellipsisBetween } from '@/utils/string';
import { TokenIcon } from '@/components/icon/TokenIcon';

export interface ItemProps {
  symbol: string;
  handleSelectItem: () => void;
}

export const AvailableTokenItemBox: FC<ItemProps> = ({
  symbol,
  handleSelectItem,
}) => {
  const token = useSelector(TokensSelectors.tokenInfoBySymbol(symbol));
  const address = token?.address;

  return (
    <ItemBox onClick={handleSelectItem}>
      <ItemContent>
        <CoinSection>
          <TokenIcon address={token?.address} />
          <NameContainer>
            <CoinName>{token?.name}</CoinName>
            <CoinSymbol>{token?.symbol}</CoinSymbol>
          </NameContainer>
        </CoinSection>
        <ValueSection>
          <Wallet>{ellipsisBetween(address || '', 4)}</Wallet>
          <Value>
            {token?.balance} {token?.symbol}
          </Value>
        </ValueSection>
      </ItemContent>
      <Price maxFraction={numberOfDecimalsForUSDT}>{token?.usdPrice}</Price>
    </ItemBox>
  );
};

const ItemBox = styled(Box)`
  ${COLUMN_ALIGN_END__JUSTIFY_START}
  width: 100%;
  cursor: pointer;
`;

const ItemContent = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap: 16px;
  width: 100%;
`;

const CoinSection = styled('div')`
  ${ROW_CENTER}
  gap: 8px;
`;

const NameContainer = styled(Box)`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
`;

const CoinSymbol = styled('p')`
  ${TEXT_XS_SEMIBOLD}
  color: var(--Neutrals-N100);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const CoinName = styled('p')`
  ${TEXT_LG_MEDIUM}
`;

const Price = styled(PriceFormat)`
  ${TEXT_SM_REGULAR}
  color: var(--Neutrals-N100);
`;

const ValueSection = styled(Box)`
  ${ROW_CENTER}
  gap: 24px;
`;

const Wallet = styled('p')`
  background-color: var(--Neutrals-N700);
  color: var(--Neutrals-N100);
  border: 1px solid var(--Neutrals-N500);
  border-radius: 4px;
  padding: 4px 8px;
  ${TEXT_SM_REGULAR}
`;

const Value = styled('p')`
  ${TEXT_M_REGULAR}
`;
