import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NavbarState } from './types';
import {
  FIAT_CURRENCES,
  storagedFiatCurrency,
  storagedLanguage,
} from './constants';

const initialState: NavbarState = {
  connectToWalletModal: {
    isOpen: false,
  },
  walletMenu: {
    isOpen: false,
    anchorEl: null,
  },
  transactionModal: {
    isOpen: false,
  },
  notificationModal: {
    isOpen: false,
  },
  newsModal: {
    isOpen: false,
    news: null,
  },
  activeNavbarItem: null,
  hamberMenu: {
    isOpen: false,
    anchorEl: null,
  },
  selectedLanguage: storagedLanguage
    ? (storagedLanguage as NavbarState['selectedLanguage'])
    : 'en',
  selectedFiatCurrency: storagedFiatCurrency
    ? (JSON.parse(storagedFiatCurrency) as NavbarState['selectedFiatCurrency'])
    : FIAT_CURRENCES.USD,
  selectNetworkMenu: {
    isOpen: false,
    anchorEl: null,
  },
  hamberMenuModal: {
    isOpen: false,
  },
  walletMenuModal: {
    isOpen: false,
  },
};
// navbar page slice
export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    connectToWalletModal(state, action: PayloadAction<{ isOpen: boolean }>) {
      state.connectToWalletModal.isOpen = action.payload.isOpen;
    },
    toggleWalletMenu(state, action: PayloadAction<boolean>) {
      state.walletMenu.isOpen = action.payload;
    },
    setAnchorElWalletMenu(state, action: PayloadAction<HTMLElement | null>) {
      //@ts-ignore
      state.walletMenu.anchorEl = action.payload;
    },
    transactionModal(
      state,
      action: PayloadAction<NavbarState['transactionModal']>
    ) {
      state.transactionModal = action.payload;
    },
    notificationModal(
      state,
      action: PayloadAction<NavbarState['notificationModal']>
    ) {
      state.notificationModal = action.payload;
    },
    newsModal(state, action: PayloadAction<NavbarState['newsModal']>) {
      state.newsModal = action.payload;
    },
    setActiveNavbarItem(
      state,
      action: PayloadAction<NavbarState['activeNavbarItem']>
    ) {
      state.activeNavbarItem = action.payload;
    },
    hamberMenu(state, action: PayloadAction<NavbarState['hamberMenu']>) {
      //@ts-ignore
      state.hamberMenu.anchorEl = action.payload.anchorEl;
      state.hamberMenu.isOpen = action.payload.isOpen;
    },
    setSelectedLanguage(
      state,
      action: PayloadAction<NavbarState['selectedLanguage']>
    ) {
      state.selectedLanguage = action.payload;
    },
    setSelectedFiatCurrency(
      state,
      action: PayloadAction<NavbarState['selectedFiatCurrency']>
    ) {
      state.selectedFiatCurrency = action.payload;
      localStorage.setItem('fiat-currency', JSON.stringify(action.payload));
    },
    selectNetworkMenu(
      state,
      action: PayloadAction<NavbarState['selectNetworkMenu']>
    ) {
      //@ts-ignore
      state.selectNetworkMenu.anchorEl = action.payload.anchorEl;
      state.selectNetworkMenu.isOpen = action.payload.isOpen;
    },
    toggleHamberMenuModal(state, action: PayloadAction<boolean>) {
      state.hamberMenuModal.isOpen = action.payload;
    },
    toggleWalletMenuModal(state, action: PayloadAction<boolean>) {
      state.walletMenuModal.isOpen = action.payload;
    },
  },
});

export const { actions: navbarActions, reducer: navbarReducer } = navbarSlice;
