import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { tokensActions } from '../tokens/slice';
import { GlobalDomains } from './selectors';
import { globalActions } from './slice';
import { GlobalState } from './types';

function* getAccountRelatedData(action: PayloadAction<void>) {
  const connection: GlobalState['coreportConnection'] = yield select(
    GlobalDomains.coreportConnection
  );
  const activeWalletAddress: GlobalState['activeWalletAddress'] = yield select(
    GlobalDomains.activeWalletAddress
  );
  if (connection && activeWalletAddress) {
    yield put(tokensActions.getAllBalances({}));
  }
}

export function* globalSaga() {
  yield takeLatest(
    globalActions.getAccountRelatedData.type,
    getAccountRelatedData
  );
  yield takeLatest(globalActions.setAccount.type, getAccountRelatedData);
}
