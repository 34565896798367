import { ContractTransaction } from 'm_ccbc';
import { call, select } from 'redux-saga/effects';
import { GlobalSelectors } from '../selectors';
import { GlobalState } from '../types';

export function* sendTx(contractTransaction: ContractTransaction) {
  const coreportConnection: GlobalState['coreportConnection'] = yield select(
    GlobalSelectors.coreportConnection
  );
  const { sendTransaction } = coreportConnection;
  if (sendTransaction) {
    try {
      const res: string = yield call(sendTransaction, contractTransaction);
      return res;
    } catch (error) {
      return error;
    }
  }
  return undefined;
}
